import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Apollo, gql } from 'apollo-angular';
import { HelperService } from 'app/services/helper.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-view-post-details',
  templateUrl: './view-post-details.component.html',
  styleUrls: ['./view-post-details.component.scss']
})
export class ViewPostDetailsComponent implements OnInit {
  postId: any;
  page: any;
  postDetails:any;
  constructor(private _helper: HelperService,private _apollo: Apollo,private sanitizer: DomSanitizer) { 
    if(!history || !history.state || !history.state.postId ||  (!history.state.page && history.state.page <= -1)){
      this._helper.goToPage('view-posts');
    }
    this.page = history.state.page;
    this.postId = history.state.postId;
    if(this.postId ){
      this.getPostDetails();
    }
  }

  ngOnInit(): void {
  }
  getPostDetails() {
    this._apollo.watchQuery<any>({
      query: gql`
        query adminpostDetails($id:ID!){
          adminpostDetails(id:$id){
            id
            caption
            type
            media
            mediaType
            description
            adminId
            createdAt
            status
          }
        }`,
          variables: {id:this.postId},
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.adminpostDetails)).subscribe((result) => {
      if(result){
        this.postDetails = result;
      }else{
        this._helper.showToast(4,'Something Went Wrong.. Try Again');
      }
    });
  }
  
  viewPosts(){    
    this._helper.goToPage('/admin/view-posts',{state: {page:this.page}});
  }
}
