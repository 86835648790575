import { Component, OnInit } from '@angular/core';
import { HelperService } from 'app/services/helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Apollo, gql } from "apollo-angular";
import { ActivityQuery } from '../../../models/types';
@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss']
})
export class ActivityDetailsComponent implements OnInit {
  activityId: any;
  activityDetails:any;
  page:any;
  indicatorArray:any = new Array();
  mileArray: any = new Array();
  constructor(private _helper: HelperService,private _apollo: Apollo,private sanitizer: DomSanitizer) { 
    if(!history || !history.state || !history.state.activityId ||  (!history.state.page && history.state.page <= -1)){
      this._helper.goToPage('view-activities');
    }
    this.page = history.state.page;
    this.activityId = history.state.activityId;
    if(this.activityId){
      this.getActivityById();
    }
  }
  
  ngOnInit(): void {
  }
  getActivityById() {
    this._apollo.watchQuery<ActivityQuery>({
      query: gql`
      query activityOne($id:ID!){
        activityOne(id:$id){
          id
          activityName
          media
          mediaType
          interactions
          indicators
          duration
          materials
          objective
          description
          ageGroup
          comment
          evaluation
          place
          other
          milestones{
            milestone
          }
          area{
            areaName
          }
          skills{
            skill
          }
          indicatorSkill{
            id
            indicatorSkill
          }
          status
        }
      }`,
      variables: {id:this.activityId},
      fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.activityOne)).subscribe((result) => {
      if(result){
        this.activityDetails = result;
        if(this.activityDetails.indicatorSkill && this.activityDetails.indicatorSkill.length > 0){
          this.activityDetails.indicatorSkill.forEach(element => {
            this.indicatorArray.push(element.indicatorSkill);
          });
          this.indicatorArray = (this.indicatorArray)?this.indicatorArray.toString():'';
        }
        if(this.activityDetails.milestones && this.activityDetails.milestones.length > 0){
          this.activityDetails.milestones.forEach(element => {
            this.mileArray.push(element.milestone);
          });
          this.mileArray = (this.mileArray)?this.mileArray.toString():'';
        }
      }else{
        this._helper.showToast(4,'Something Went Wrong.. Try Again');
      }
    });
  }

  viewActivity(){    
    this._helper.goToPage('view-activities',{state: {page:this.page}});
  }
}
