import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { mileStoneQuery, mileStoneActivityList, IndicatorList, Indicator } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
import { MatTabChangeEvent } from '@angular/material/tabs';
@Component({
  selector: 'app-showindicators',
  templateUrl: './showindicators.component.html',
  styleUrls: ['./showindicators.component.scss']
})
export class ShowindicatorsComponent implements OnInit {
  tabIndex:number;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;
  dataList:any;
  currentPage: number;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['IndicatorSkill'];
  skills:any;
  milestoneId:any;
  milestones:any;
  selectedId:any[] = new Array();
  getActivityQuery:QueryRef<any>;
  private querySubscription: Subscription;
  constructor(private _helper: HelperService,private _apollo: Apollo) { 
    if(!history || !history.state || !history.state.milestoneId){
      this._helper.goToPage('milestones');
    }
    this.milestoneId = history.state.milestoneId;
    if(this.milestoneId ){
      this.getIndicatorBasedOnMileStone();
    }
  }

  ngOnInit(): void {
  }
  onTabChange(event:MatTabChangeEvent){
    this.tabIndex = event.index;
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;
    this.getIndicatorBasedOnMileStone();
  }
  searchIndicator(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getIndicatorBasedOnMileStone();
  }

  getIndicatorBasedOnMileStone() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : 0}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getActivityQuery = this._apollo.watchQuery<mileStoneQuery>({
      query: gql`
          query milestoneBasedIndicatorSKill($page: Int, $size: Int, $search: String, $milestone: String){
            milestoneBasedIndicatorSKill(page: $page, size: $size, search: $search, milestone:$milestone){
              totalItems
              data{
               id
               indicatorSkill
               status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex) ,
            size: parseInt(pagesize),
            search: search,
            milestone:this.milestoneId
          },
      fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getActivityQuery.valueChanges.pipe(map(result => result.data.milestoneBasedIndicatorSKill)).subscribe((result) => {
      if(result.totalItems !== 0){
        const ELEMENT_DATA:any = result.data.map((ind: Indicator) => ({
          indicatorSkill:ind.indicatorSkill,
        }));
        this.dataList = ELEMENT_DATA;
        this.dataSource = new MatTableDataSource<mileStoneQuery>(ELEMENT_DATA);
        this.milestones = result;
      }else{
        this.milestones = '';
      }
    });
  }

  viewAssignedActivities(){
    this._helper.goToPage('view-assigned-activity',{state: {milestoneId:this.milestoneId}});
  }

}
