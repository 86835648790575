import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { HelperService } from 'app/services/helper.service';

@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    newCaseCount: number;
    newProReqCount: number;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _helper: HelperService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // this._helper.getNewCaseCount().subscribe((count: number) => {
        //     this.newCaseCount = count;
        //     this._changeDetectorRef.detectChanges();
        // });

        // this._helper.getProReqUsersCount().subscribe((count: number) => {
        //     this.newProReqCount = count;
        //     this._changeDetectorRef.detectChanges();
        // });

        // // Subscribe to navigation item
        // merge(
        //     this._fuseNavigationService.onNavigationItemAdded,
        //     this._fuseNavigationService.onNavigationItemUpdated,
        //     this._fuseNavigationService.onNavigationItemRemoved
        // ).pipe(takeUntil(this._unsubscribeAll))
        //  .subscribe(() => {

        //      // Mark for check
        //      this._changeDetectorRef.markForCheck();
        //  });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
