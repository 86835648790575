import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import ImageCompressor from 'image-compressor.js';
import { HelperService } from 'app/services/helper.service';

@Injectable()
export class VideoProcessingService {

  constructor(
    @Inject(DOCUMENT) private document: Document,private _helper: HelperService,
  ) { }

  public promptForVideo(): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      // make file input element in memory
      const fileInput: HTMLInputElement = this.document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'all/*';
      fileInput.setAttribute('capture', 'camera');
      // fileInput['capture'] = 'camera';
      fileInput.addEventListener('error', event => {
        reject(event.error);
      });
      fileInput.addEventListener('change', event => {
        resolve(fileInput.files[0]);
      });
      // prompt for video file
      fileInput.click();
    });
  }

  public generateThumbnail(videoFile): Promise<any>  {
    
    const video: HTMLVideoElement = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<any>((resolve, reject) => {
      canvas.addEventListener('error',  reject);
      video.addEventListener('error',  reject);
      video.addEventListener('canplay', event => {
        // canvas.width = video.videoWidth;
        canvas.width = 500;
        // canvas.height = video.videoHeight;
        canvas.height = 500;
        
        // context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        context.drawImage(video, 0, 0, 500, 500);
        resolve({status:true,imageData:videoFile,url:canvas.toDataURL(),filename:videoFile.name});
      });
      if (videoFile.type) {
        video.setAttribute('type', videoFile.type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile);
      video.load();
    });
  }

  public generateThumbnail2(videoFile): Promise<any>  {
    
    const video: HTMLVideoElement = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<any>((resolve, reject) => {
      canvas.addEventListener('error',  reject);
      video.addEventListener('error',  reject);
      video.addEventListener('canplay', event => {
        // canvas.width = video.videoWidth;
        canvas.width = 1200;
        // canvas.height = video.videoHeight;
        canvas.height = 628;
        
        // context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        context.drawImage(video, 0, 0, 1200, 628);
        resolve({status:true,imageData:videoFile,url:canvas.toDataURL(),filename:videoFile.name});
      });
      if (videoFile.type) {
        video.setAttribute('type', videoFile.type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile);
      video.load();
    });
  }

  onImageFileChanged(imageFile): Promise<any> {    
    return new Promise<any>((resolve, reject) => {
      if (!imageFile) {
        resolve({ status: false });
        return;
      }
  
      const supportedFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
      if (!supportedFormats.includes(imageFile.type)) {
        const supportedFormatsString = supportedFormats.join(', ').toUpperCase();
        const errorMessage = `Unsupported image format. Supported formats are: ${supportedFormatsString}`;
        console.error(errorMessage);
        this._helper.showToast(3, errorMessage);
        resolve({ status: false });
        return;
      }
  
      const imageCompressor = new ImageCompressor();
  
      const compressionOptions = {
        maxWidth: 1200, 
        maxHeight: 628, 
        quality: 1, 
        mimeType: 'auto', 
      };
  
     
      imageCompressor.compress(imageFile, compressionOptions)
        .then(compressedFile => {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onload = () => {
            resolve({
              status: true,
              url: reader.result,
              imageData: compressedFile,
              videoUrl: false,
              imageUrl: reader.result,
              filename: imageFile.name
            });
          };
        })
        .catch(error => {
          console.error('Image compression error:', error);
          this._helper.showToast(3, error.message);
          resolve({ status: false });
        });
    });
  }

  onImageFileChanged2(imageFile): Promise<any> {    
    return new Promise<any>((resolve, reject) => {
      if (!imageFile) {
        resolve({ status: false });
        return;
      }
  
      const supportedFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];
      if (!supportedFormats.includes(imageFile.type)) {
        const supportedFormatsString = supportedFormats.join(', ').toUpperCase();
        const errorMessage = `Unsupported image format. Supported formats are: ${supportedFormatsString}`;
        console.error(errorMessage);
        this._helper.showToast(3, errorMessage);
        resolve({ status: false });
        return;
      }
  
      const imageCompressor = new ImageCompressor();
  
      const compressionOptions = {
        maxWidth: 1200, 
        maxHeight: 628, 
        quality: 1, 
        mimeType: 'auto', 
      };
  
     
      imageCompressor.compress(imageFile, compressionOptions)
        .then(compressedFile => {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onload = () => {
            resolve({
              status: true,
              url: reader.result,
              imageData: compressedFile,
              videoUrl: false,
              imageUrl: reader.result,
              filename: imageFile.name
            });
          };
        })
        .catch(error => {
          console.error('Image compression error:', error);
          this._helper.showToast(3, error.message);
          resolve({ status: false });
        });
    });
  }

}