<div id="profile" class="page-layout simple tabbed">
    <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" >
      <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" >
        <div class="name">KPI</div>
      </div>
    </div>
    
    <div class="about-content" >
      <div id="about"  style="margin-top: 25px;" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <mat-form-field [attr.fxFlex]="filter_type == '3' ? '40' : '80'" appearance="outline" class="w-50-p">
          <mat-label>Filter By</mat-label>
          <mat-select (selectionChange)="filterBy($event)" >
            <mat-option value="1"> 1 Month </mat-option>
            <mat-option value="2"> 1 Week </mat-option>
            <mat-option value="4"> Today </mat-option>
            <mat-option value="3"> Choose Date</mat-option>
          </mat-select>
          </mat-form-field>
        <mat-form-field fxFlex="40" appearance="outline" class="w-50-p" *ngIf="filter_type == '3'">
          <input
            matInput
            placeholder="Select Date Range"
            [satDatepicker]="picker"
            readonly
            [max]="maxDate"
            (dateChange)="getSelectedDate($event)"
          />
          <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
          <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div id="about"  style="margin-top: 25px;" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="15" fxFlex.gt-sm="15" fxFlex.gt-md="15">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Family Count</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{familyCountData}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="15" fxFlex.gt-sm="15" fxFlex.gt-md="15">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Child Count</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{childCountData}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="15" fxFlex.gt-sm="15" fxFlex.gt-md="15">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">CareGiver Count</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{caregiverCountData}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="15" fxFlex.gt-sm="15" fxFlex.gt-md="15">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Session Active</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{sessionCountData}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="15" fxFlex.gt-sm="15" fxFlex.gt-md="15">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">User Engaged</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{userEngageinTotalCount}} </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="90" fxFlex.gt-sm="90" fxFlex.gt-md="90">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Increased User Count</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">
                  <div  *ngIf="ticketCount">
                    <mat-table class="agents-table" #table [dataSource]="incDatasource" fusePerfectScrollbar>
                      <ng-container matColumnDef="care1">
                        <mat-header-cell *matHeaderCellDef>Family Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.familyCount}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="care2">
                        <mat-header-cell *matHeaderCellDef>Child Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.childCount}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="care3">
                        <mat-header-cell *matHeaderCellDef>CareGivers Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.careGiversCount}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="care4">
                        <mat-header-cell *matHeaderCellDef>Session Active Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.sessionActiveCount}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="care5">
                        <mat-header-cell *matHeaderCellDef>User Engage in Total Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.userEngageinTotalCount}}
                        </mat-cell>
                      </ng-container>
        
                      <mat-header-row *matHeaderRowDef="incDisplayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let agent; columns: incDisplayedColumns" class="agent" matRipple> </mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="30" fxFlex.gt-sm="30" fxFlex.gt-md="30">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Family Child Count Details</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">
                  <div >
                    <mat-table class="agents-table" #table [dataSource]="famDatasource" fusePerfectScrollbar>
                      <ng-container matColumnDef="care1">
                        <mat-header-cell *matHeaderCellDef>Family Name</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.familyName ? u.familyName :'0'}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="care2">
                        <mat-header-cell *matHeaderCellDef>Child Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.childCount ? u.childCount :'0'}}
                        </mat-cell>
                      </ng-container>
        
                      <mat-header-row *matHeaderRowDef="famDisplayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let agent; columns: famDisplayedColumns" class="agent" matRipple> </mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="60" fxFlex.gt-sm="60" fxFlex.gt-md="60">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Ticket Count Details</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">
                  <div  *ngIf="ticketCount">
                    <mat-table class="agents-table" #table [dataSource]="ticDatasource" fusePerfectScrollbar>
                      <ng-container matColumnDef="care1">
                        <mat-header-cell *matHeaderCellDef>Resolved Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.resolvedCount}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="care2">
                        <mat-header-cell *matHeaderCellDef>NewTickets Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.newTicketsCount}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="care3">
                        <mat-header-cell *matHeaderCellDef>Processing Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.processingCount}}
                        </mat-cell>
                      </ng-container>
        
                      <mat-header-row *matHeaderRowDef="ticDisplayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let agent; columns: ticDisplayedColumns" class="agent" matRipple> </mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  