import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { map } from 'rxjs/operators';
import { familyGroup, familyQuery } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
@Component({
  selector: 'app-view-family',
  templateUrl: './view-family.component.html',
  styleUrls: ['./view-family.component.scss']
})
export class ViewFamilyComponent implements OnInit {
  loading: boolean = false;
  familyDetails:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;

  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['FamilyName', 'CareGiver','CreatedAt','MemberCount','ChildCount','Status', 'menu'];
  constructor(private _helper: HelperService,private _apollo: Apollo, private _model: MatDialog) {
    if(history && history.state && history.state.page){
      this.pIndex = history.state.page;
    }
    this.getFamily();
   }

  ngOnInit(): void {
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;

    this.getFamily();
  }
  searchFamily(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getFamily();
  }
  getFamily() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : ''}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this._apollo.watchQuery<familyQuery>({
      query: gql`
          query familyList($page: Int, $size: Int,$search: String){
            familyList(page: $page, size: $size,search: $search){
              totalItems
              data{
                id
                familyName
                familyMembersCount
                childCount
                careGiver{
                  lastname
                  firstname
                }         
                createdAt
                status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex),
            size: parseInt(pagesize),
            search:search
          },
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.familyList)).subscribe((result) => {
      if (result.totalItems) {
        const ELEMENT_DATA:any = result.data.map((fam: familyGroup) => ({
          id: fam.id,
          familyName: fam.familyName,
          firstName: (fam.careGiver && fam.careGiver.firstname)?fam.careGiver.firstname:' -- ',
          lastName: (fam.careGiver && fam.careGiver.lastname)?fam.careGiver.lastname: ' ',
          createdAt: fam.createdAt,
          memberCount:fam.familyMembersCount,
          childCount:fam.childCount,
          Status: fam.status,
        }));
        this.dataSource = new MatTableDataSource<familyQuery>(ELEMENT_DATA);
        this.familyDetails = result;
      } else {
        this._helper.showToast(4,'No Data Found');
      }
    });

  }
  viewDetails(familyId){    
    this._helper.goToPage('about-family',{state: {familyId:familyId,page:this.pIndex}});
  }
}
