<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    
    <div class="center">
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16"> school </mat-icon>
                <span class="logo-text h1"> Add Learn / Blog </span>
            </div>
        </div>
        
        <div class="content-card" >
            <mat-card>
                <mat-card-content>
                    <form [formGroup]="blogForm">
                        <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                            <mat-label>Learn Caption</mat-label>
                            <input matInput formControlName="caption" type="text" placeholder="Caption" />
                            <mat-error *ngIf="blogForm.controls['caption'].hasError('required')">Caption is Required </mat-error>
                            <mat-error *ngIf="blogForm.controls['caption'].hasError('removeSpaces')">Invalid caption</mat-error>
                        </mat-form-field> 
                        <mat-form-field  class="w-80-p" floatPlaceholder="never">
                            <mat-label>Media</mat-label>
                            <input [hidden]="true" type="file" accept="all/*" #fileSelect>
                            <input readonly matInput  style="cursor: pointer" (click)="getVideoImage()"  formControlName="blogIcon"  [(ngModel)]="filename" placeholder="Select file..."/>
                            <!-- <button matSuffix mat-icon-button (click)="getVideoImage()"  matTooltip="Select a file" [matTooltipPosition]="'above'">
                                <mat-icon>attach_file</mat-icon>
                            </button> -->
                            <div style="float: right; cursor: pointer;margin-top: -25px;" (click)="getVideoImage()" matTooltip="Select a file" [matTooltipPosition]="'above'">
                                <mat-icon>attach_file</mat-icon>
                              </div>
                        </mat-form-field>
                        <img *ngIf="imageUrl" class="uploadTempnail" [src]="imageUrl" onerror="'assets/images/no_image.png'" />
                        <video *ngIf="videoUrl" class="uploadTempnail"  controls [attr.src]="videoUrl" width="90" height="90"  (loadedmetadata)="getDuration($event)"></video>
                        <mat-error *ngIf="blogFormSubmit">Media is Required </mat-error>
                        <br/><br/>
                                                
                     <!-- <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                        <mat-label>Duration</mat-label>
                        <input matInput formControlName="duration" type="text" placeholder="Duration" />
                        <mat-error *ngIf="blogForm.controls['duration'].hasError('required')">Duration is Required </mat-error>
                      </mat-form-field> -->
                      <!-- <mat-label>Description</mat-label> -->
                        <angular-editor id="privacy" [config]="editorConfig" [placeholder]="'Description'" formControlName="description"></angular-editor>
                        <div *ngIf=" blogForm.controls['description'].touched">
                            <mat-error *ngIf="blogForm.controls['description'].hasError('required')">Description is Required </mat-error>
                            <mat-error *ngIf="blogForm.controls['description'].hasError('removeSpaces')">Invalid description</mat-error>
                        </div>
                        <br/>
                        
                        <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                        <mat-label>MileStone</mat-label>
                        <mat-select formControlName="milestone"  multiple placeholder="Choose Milestones">
                            <mat-option selected [value]="-1" disabled>Choose Milestones</mat-option>
                            <!-- <mat-option (click)="setAllMileStones()">All</mat-option> -->
                        <mat-option *ngFor="let mile of milestones" [value]="mile.value" (click)="setAllMileStones(mile.value)">
                            {{mile.value}}
                        </mat-option>
                        </mat-select>
                        <mat-error *ngIf="blogForm.controls['milestone'].hasError('required')">Milestone is Required </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                            <mat-label>Tags</mat-label>
                            <mat-chip-list #chipList aria-label="Tag selection">
                              <mat-chip *ngFor="let tag of tags" (removed)="remove(tag)">
                                {{tag}}
                                <!-- <button matChipRemove> -->
                                  <mat-icon matChipRemove>cancel</mat-icon>
                                <!-- </button> -->
                              </mat-chip>
                              <input placeholder="New Tag...."
                                    type="text"
                                    matInput
                                     [matChipInputFor]="chipList"
                                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                     [matChipInputAddOnBlur]="addOnBlur"
                                     (matChipInputTokenEnd)="add($event)"
                                     [matAutocomplete]="auto_1"
                                     #tagInput
                                     formControlName="tag">
                            </mat-chip-list>
                            <mat-autocomplete #auto_1="matAutocomplete"  >
                                <mat-option *ngFor="let tag of filteredTags | async" [value]="tag" (click)="selected(tag)">
                                  {{tag}}
                                </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                            <mat-label>Slug</mat-label>
                            <input matInput formControlName="slug" type="text" placeholder="Slug" />
                            <mat-error *ngIf="blogForm.controls['slug'].hasError('required')">Slug is Required </mat-error>
                          </mat-form-field> 
                          <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                            <mat-label>Meta Title</mat-label>
                            <input matInput formControlName="metaTitle" type="text" placeholder="Meta Title" />
                            <mat-error *ngIf="blogForm.controls['metaTitle'].hasError('required')">Meta title is Required </mat-error>
                            <mat-error *ngIf="blogForm.controls['metaTitle'].hasError('removeSpaces')">Invalid Meta Title </mat-error>

                          </mat-form-field> 
                          <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                            <mat-label>Meta Description</mat-label>
                            <textarea matInput formControlName="metaDescription" placeholder="Meta Description" rows="4"></textarea>
                            <mat-error *ngIf="blogForm.controls['metaDescription'].hasError('required')">Meta Description is Required </mat-error>
                            <mat-error *ngIf="blogForm.controls['metaDescription'].hasError('removeSpaces')">Invalid Meta Description</mat-error>

                          </mat-form-field> 
                        <br/><br/>
                        <button mat-raised-button style="margin-right: 5px" color="primary" [disabled]="blogForm.invalid" (click)="addBlog()"> Save </button>
                        &nbsp;&nbsp;
                        <button mat-stroked-button  (click)="cancel()" >
                            <span>Cancel</span> 
                        </button>
                        
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

