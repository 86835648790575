import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { Apollo, gql } from "apollo-angular";

import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";

import { AUTH_TOKEN,ROLES, setLocalStorageItem } from "app/helpers/utils";
import { HelperService } from "app/services/helper.service";
import { AngularFireAuth }  from '@angular/fire/auth';
import { Admin, MainQuery } from '../_admin/models/types'
import { map } from "rxjs/operators";
interface LoginResponse {
    data: {
        adminSignIn: {
            token: string;
            admin:Admin;
        };
    };
}

@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loader = false;
    loginMessage: any;
    roles: any;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _helper: HelperService,
        private _apollo: Apollo,
        private authFire: AngularFireAuth
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: { hidden: true },
                toolbar: { hidden: true },
                footer: { hidden: true },
                sidepanel: { hidden: true },
            },
        };
    }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            emailId: ["", [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            password: ["", [Validators.required,Validators.minLength(8)]],
            isRemember:[false]
        });
    }

    loginUser(): void {
        localStorage.clear();        
        if (this.loginForm.valid) {
            // this.loader = true;
            this.authFire.signInWithEmailAndPassword(this.loginForm.value.emailId, this.loginForm.value.password).then((userCredential) => {
                if(userCredential && userCredential.user && userCredential.user.uid){
                    this._apollo.mutate({
                        mutation: gql`
                            mutation($uuid: String!,$loggedIn: Boolean) {
                                adminSignIn(uuid: $uuid,loggedIn: $loggedIn) {
                                    token
                                    admin{
                                        id
                                        firstname
                                        lastname
                                        username
                                        email
                                        userType
                                    }
                                }
                            }
                        `,
                        variables: { uuid: userCredential.user.uid,loggedIn: this.loginForm.value.isRemember},
                    })
                    .subscribe(
                        ({ data }: LoginResponse) => {
                            const token = data.adminSignIn.token;

                            setLocalStorageItem(AUTH_TOKEN, token);
                            setLocalStorageItem('DETAILS', data.adminSignIn.admin);
                            this._helper.setUserDetails(data.adminSignIn.admin);
                            this._apollo.watchQuery<MainQuery>({
                                query: gql`
                                    query getModule{
                                        getModule{
                                           module{
                                               id
                                               url
                                               icon
                                               mainMenu{
                                                   name
                                                }
                                            }
                                        }
                                    }`,
                                    fetchPolicy:'network-only'
                              }).valueChanges.pipe(map(result => result.data.getModule)).subscribe((result) => {
                                if(result){
                                    this.roles = result;
                                    let roleArr = new Array();
                                    let menuArr = new Array();
                                    menuArr.push({id: "Home",
                                            title: "Home",
                                            type: "group",
                                            children: [{
                                                    id: "dashboard",
                                                    title: "Dashboard",
                                                    type: "item",
                                                    icon: "dashboard",
                                                    url: "/admin",
                                                    exactMatch: true,
                                                }]
                                            })
                                    this.roles.forEach(ele => {
                                        if((menuArr.findIndex((item)=>item.id == ele.module.mainMenu.name) == -1) && ele.module.icon){
                                            menuArr.push({
                                                id: ele.module.mainMenu.name,
                                                title: ele.module.mainMenu.name,
                                                type: 'item',
                                                icon: ele.module.icon,
                                                url: ele.module.url,
                                                exactMatch: true,
                                            })
                                        }
                                        roleArr.push(parseInt(ele.module.id));
                                    });
                                    setLocalStorageItem(ROLES, roleArr);
                                    setLocalStorageItem('side-menu',menuArr);

                                    this._helper.setMenu(menuArr);
                                    this._helper.setAuth(true);
                                    this._router.navigateByUrl('/admin');
                                }
                            });
                        },

                        (err) => {
                            // this.loader = false;
                            this._helper.showToast(4,'Invalid Email Id or Password');
                        }
                    );
                }else{
                    // this.loader = false;
                    this._helper.showToast(4,'Invalid Email Id or Password');
                }
            }, (error)=>{
                // this.loader = false;
                this._helper.showToast(4,'Invalid Email Id or Password');
            })
        }
    }
}
