import { Component, OnInit } from '@angular/core';
import * as RoleModules from "../../../../../assets/utils/roles.json";
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { map } from 'rxjs/operators';
import { MainQuery,module } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
import { uniq } from 'lodash';
import _ from 'lodash';
interface addPermission {
  data: {
    addPermission: {
      status: String;
      code: String;
      message: String;
    };
  };
}
@Component({
  selector: 'app-view-user-permissions',
  templateUrl: './view-user-permissions.component.html',
  styleUrls: ['./view-user-permissions.component.scss']
})
export class ViewUserPermissionsComponent implements OnInit {
  adminRoles: any = (RoleModules as any).default;
  modules: any;
  roles:any = new Array();
  assUserRoles:any = new Array();
  userType: any;
  username: any;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  constructor(private _helper: HelperService,private _apollo: Apollo) { 
    if(!history || !history.state || !history.state.userType || !history.state.username){
      this._helper.goToPage('roles');
    }
    this.userType = history.state.userType;
    this.username = history.state.username;
    if(this.userType){
      this.getUserRoles();
      this.getModules();
    }
  }

  ngOnInit(): void {

  }
  getModules(){
    this._apollo.watchQuery<MainQuery>({
      query: gql`
      query mainMenuModuleList($id:ID!){
        mainMenuModuleList(id: $id){
          id
          name
          module{
            moduleName
            display
            id
          }
        }
      }`,
      variables: {id: 1},
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.mainMenuModuleList)).subscribe((result) => {
      if(result){
        this.modules = result;
      }
    });
  }
  getUserRoles(){
    this._apollo.watchQuery<MainQuery>({
      query: gql`
      query getModuleByuser($roleId:Int!){
        getModuleByuser(roleId: $roleId){
          module{
            id
          }
        }
      }`,
      variables: {roleId: parseInt(this.userType)},
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.getModuleByuser)).subscribe((result) => {
      if(result){
        this.assUserRoles = result;
        this.assUserRoles.forEach((ele: { module: { id: string; }; }) => {
          this.roles.push(parseInt(ele.module.id));
        });
      }
    });
  }

  getSelectedValue(event,id,menuId,name){   
    let index;
    let checkArr = new Array();
    let skillIndArr = new Array(35,36,37,38,39,40);
    this.modules.forEach((element) => {
      if(parseInt(element.id) === parseInt(menuId)){
        element.module.forEach(element2 => {
          checkArr.push(parseInt(element2.id))
          if(element2.display === 'View'){
            index = element2.id
          }
        });
        return
      }
    });

    if(skillIndArr.includes(parseInt(id))){
      if(event == false){
        if(id == 35 || id == 38){
          if(id == 35){
            skillIndArr.forEach(item =>{
              if(item != 35){
                if(this.roles.includes(item)){
                  this.roles.splice(this.roles.indexOf(item), 1);
                }
              }
            })
          }
          if(id == 38){
            if(this.roles.includes(39)){
              this.roles.splice(this.roles.indexOf(39), 1);
            }
            if(this.roles.includes(40)){
              this.roles.splice(this.roles.indexOf(40), 1);
            }
          }
        }
        this.roles.splice(this.roles.indexOf(parseInt(id)), 1);
      }else{
        if(!this.roles.includes(8)){
          this.roles.push(8);
        }
        if(id == 35){
          this.roles.push(parseInt(id));
        } else if(id == 38 || id == 36 ||id == 40|| id == 37 || id == 39) {          
          if(!this.roles.includes(35)){
            this.roles.push(35);
          }
          if(id == 39 || id == 40){
            if(!this.roles.includes(38)){
              this.roles.push(38);
            }
          }
          this.roles.push(parseInt(id));
        }
      }
    }else{
      if(name === 'Add' || name === 'Edit' || name === 'Password Reset' || name === 'Change Permission' || name === 'Change Status'|| name === 'Delete'){
        if(event == true){
          if(!this.roles.includes(parseInt(index))){
            this.roles.push(parseInt(index));
          }
          this.roles.push(parseInt(id));
        } else{
          this.roles.splice(this.roles.indexOf(parseInt(id)), 1);
        }
      }else if(name === 'View'){
        if(event == true){
          this.roles.push(parseInt(id));
        } else{
          if(id == 8){
            skillIndArr.forEach(item =>{
              if(this.roles.includes(item)){
                this.roles.splice(this.roles.indexOf(item), 1);
              }
            })
            this.roles.splice(this.roles.indexOf(8), 1);
          }else{
            checkArr.forEach(item =>{
              if(this.roles.includes(item)){
                this.roles.splice(this.roles.indexOf(item), 1);
              }
            })
          }
        }
      }else{
        if(event == true){
          this.roles.push(parseInt(id));
        } else{
          this.roles.splice(this.roles.indexOf(parseInt(id)), 1);
        }
      }  
    }  
  }

  checkedBoxorNot(id: string){
    if(this.roles.includes(parseInt(id))){      
      return true;
    }
    return false;
  } 

  assignRoles(){
    this._apollo.mutate({
      mutation: gql`
          mutation addPermission($roleId:Int!,$moduleId: [Int]){
            addPermission(roleId: $roleId,moduleId:$moduleId){
              code
              status
            }
          }`,
          variables: {roleId: parseInt(this.userType),moduleId:this.roles},
    }).subscribe(
      ({ data }: addPermission) => {
        if(data.addPermission.status){
          this._helper.showToast(1,'Permission Updated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
