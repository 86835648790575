<div id="profile" class="page-layout simple tabbed" *ngIf="activityDetails">
    <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" >
        <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"  fxLayoutAlign.gt-sm="start center">
            <div class="name">{{activityDetails.activityName}}</div>
        </div>
        <button mat-raised-button (click)="viewActivity()"><mat-icon>arrow_back</mat-icon>Back</button>
    </div>
    
    <div class="about-content">
        <mat-card >
            <mat-card-content>
                <div id="about" class="p-24" fxLayout="row wrap">
                    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">
                        <div class="profile-box info-box general" fxLayout="column">
                            <header class="accent">
                                <div class="title">Activity Details</div>
                            </header>
                            
                            <div class="content">
                                
                                <div class="info-line">
                                    <div class="title">Description</div>
                                    <div class="info" [innerHTML]="activityDetails.description"></div>
                                </div>
                                
                                <div class="info-line">
                                    <div class="title">Objective</div>
                                    <div class="info">{{(activityDetails.objective)? activityDetails.objective:' -- '}}</div>
                                </div>
                                
                                <div class="info-line">
                                    <div class="title">Materials</div>
                                    <div class="info">{{(activityDetails.materials)?activityDetails.materials:' -- '}}</div>
                                </div>
                                <div class="info-line">
                                    <div class="title">Indicators</div>
                                    <div class="info">{{(activityDetails.indicatorSkill && activityDetails.indicatorSkill.length > 0)?indicatorArray:' -- '}}</div>
                                </div>
                                <div class="info-line">
                                    <div class="title">Interactions</div>
                                    <div class="info">{{(activityDetails.interactions)? activityDetails.interactions: ' -- '}}</div>
                                </div>
                                <div class="info-line">
                                    <div class="title">Evaluation</div>
                                    <div class="info">{{(activityDetails.evaluation)?activityDetails.evaluation:' -- '}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="about-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="35">
                        <div class="profile-box info-box general" fxLayout="column">
                            <div class="content">
                                
                                <div class="info-line">
                                    <div class="title">Age Group</div>
                                    <div class="info">{{activityDetails.ageGroup}}</div>
                                </div>
                                <div class="info-line">
                                    <div class="title">Area of Development</div>
                                    <div class="info">{{activityDetails.area.areaName}}</div>
                                </div>
                                
                                <div class="info-line">
                                    <div class="title">Skill</div>
                                    <div class="info">{{activityDetails.skills.skill}}</div>
                                </div>
                                
                                <div class="info-line">
                                    <div class="title">Duration</div>
                                    <div class="info">{{(activityDetails.duration)?activityDetails.duration:' -- '}}</div>
                                </div>
                                
                                <div class="info-line">
                                    <div class="title">Place</div>
                                    <div class="info">{{(activityDetails.place)?activityDetails.place:' -- '}}</div>
                                </div>
                                
                                <div class="info-line">
                                    <div class="title">Other</div>
                                    <div class="info" style="overflow-wrap: break-word;">{{(activityDetails.other)?activityDetails.other:' -- '}}</div>
                                </div>
                                <div class="info-line">
                                    <div class="title">MileStone</div>
                                    <div class="info" style="overflow-wrap: break-word;">{{(activityDetails.milestones && activityDetails.milestones.length > 0)?mileArray:' -- '}}</div>
                                </div>
                                <div class="info-line">
                                    <div class="title">Status</div>
                                    <div class="info">{{activityDetails.status == '1' ? 'Active': 'Inactive'}}</div>
                                </div>                               
                                
                            </div>
                        </div>
                    </div>
                    <div class="about-sidebar" fxLayout="column" fxFlex="80" >
                        <div class="profile-box info-box general"  >
                            <header class="accent">
                                <div class="title">Media</div>
                            </header>
                            <div class="content">
                                <div fxLayout="column" fxLayoutAlign="center center">
                                    <img class="uploadTempnail" *ngIf="activityDetails.mediaType =='image'" [src]="activityDetails.media" onerror="'assets/images/no_image.png'" />
                                    <video class="uploadTempnailvideo" *ngIf="activityDetails.mediaType =='video'"   controls [attr.src]="activityDetails.media" style="max-width: -webkit-fill-available;"></video>                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
