<div id="agents" class="page-layout carded fullwidth">
    <div class="center">
        <div class="header" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
            <div>
                <mat-form-field class="example-full-width" appearance="outline" class="w-100-p" style="max-width: 185px;">
                    <mat-label>Area</mat-label>
                    <mat-select  placeholder="Choose Area" (selectionChange)="selectArea($event)">
                        <mat-option selected [value]="-1" disabled>Choose Area</mat-option>
                        <mat-option *ngFor="let area of areas" [value]="area">
                            {{area.areaName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div >
                <mat-form-field class="example-full-width" appearance="outline" class="w-100-p"  style="max-width: 185px;">
                    <mat-label>Skill</mat-label>
                    <mat-select placeholder="Choose Skill"  (selectionChange)="selectSkill($event)">
                        <mat-option selected [value]="-1" disabled>Choose Skill</mat-option>
                        <mat-option *ngFor="let skill of skills" [value]="skill"  matTooltip="{{skill.skill}}"  [matTooltipPosition]="'above'">
                            {{skill.skill}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div >
                <mat-form-field appearance="outline" class="example-full-width" class="w-100-p"  style="max-width: 185px;">
                    <mat-label>Age group</mat-label>
                    <mat-select  placeholder="Choose Age Group"  (selectionChange)="selectAgeGroup($event)">
                      <mat-option selected="true" [value]="-1" disabled>Choose Age Group</mat-option>
                      <mat-option value="Infants">Infants (Birth to 24 months)</mat-option>
                      <mat-option value="Toddlers">Toddlers (14 months to 3 years)</mat-option>
                      <mat-option value="Preschool">Preschool Kindergarten(2.5 to 6 years)</mat-option>
                      <mat-option value="School-age">School-age Children (5 to 8 years)</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div> OR </div>
            <div>
                <app-search-bar placeholder="Search for Activity"  (searchEvent)="searchActivities($event)"> </app-search-bar>
            </div>
            <div>
                <button mat-raised-button (click)="clearFilter()"><mat-icon>clear_all</mat-icon>Clear Filter</button>
            </div>
        </div>
        
        <div class="content-card" style="overflow-y: scroll">
            <h1 class="err-msg" *ngIf="!milestones">No Activities Found</h1>
            
            <div *ngIf="milestones">
                <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
                    <!-- Name Column -->
                    <ng-container matColumnDef="select">
                        <mat-header-cell *matHeaderCellDef>
                            <section class="example-section">
                                <mat-checkbox  (change)="masterToggle($event)"
                                [checked]="masterTogglePage[pIndex]?.all == true"
                                [indeterminate]="masterTogglePage[pIndex]?.single == true" class="example-margin"> </mat-checkbox>
                            </section>
                        </mat-header-cell>
                        <mat-cell  *matCellDef="let u">
                            <section class="example-section">
                                <mat-checkbox [checked]="checkedBoxorNot(u.id)" (change)= "getSelectedValue($event.checked,u.id)" class="example-margin" [value]="u.id"> </mat-checkbox>
                            </section>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Name">
                        <mat-header-cell *matHeaderCellDef>Activity</mat-header-cell>
                        <mat-cell  *matCellDef="let u">
                            <p class="text-truncate">{{u.activityName}} </p>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Skill">
                        <mat-header-cell *matHeaderCellDef>Skill</mat-header-cell>
                        <mat-cell  *matCellDef="let u">
                            <p class="text-truncate">{{u.skillName}} </p>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Area">
                        <mat-header-cell *matHeaderCellDef>Area</mat-header-cell>
                        <mat-cell  *matCellDef="let u">
                            <p class="text-truncate">{{u.areaName}} </p>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="IndicatorSkill">
                        <mat-header-cell *matHeaderCellDef>Indicator Skill</mat-header-cell>
                        <mat-cell  *matCellDef="let u">
                          <p class="text-truncate">{{u.indicatorSkill}} </p>
                        </mat-cell>
                      </ng-container>
                    <!-- Status Column -->
                    <ng-container matColumnDef="Status">
                        <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
                        <mat-cell  *matCellDef="let u">
                            <p class="text-truncate">{{u.Status == '1' ? 'Active':'Inactive'}}</p>
                        </mat-cell>
                    </ng-container>
                    
                    
                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    
                    <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
                </mat-table>
                
                <mat-paginator #paginator [length]="milestones.totalItems" [pageSize]="pSize" [pageSizeOptions]="dataSize" [pageIndex]="milestones.currentPage" (page)="changePage($event)" > </mat-paginator>
                <button *ngIf="this.userRoles && this.userRoles.includes(21)" mat-raised-button color="primary" style="margin-left: 440px;margin-bottom: 10px;" (click)="assignActivities()" >Assign</button>&nbsp;&nbsp;
                <button mat-raised-button color="primary" (click)="goBack()" >Back</button>

            </div>
        </div>
    </div> 
</div>