import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { setLocalStorageItem } from 'app/helpers/utils';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { Subscription } from 'rxjs';
import { AngularFireAuth }  from '@angular/fire/auth';
import { User,UserQuery } from '../../models/types';
import firebase from "firebase/app";
import "firebase/auth";
import { SpinnerService } from '../../../shared/spinner.service';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
interface updateProfileResponse {
  data: {
    adminProfileUpdate: {
      status: string,
      code: string,
      message: string
    };
  };
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userData:User;
  profileForm: FormGroup;
  passwordChange;
  getUserQuery:QueryRef<any>;
  private querySubscription: Subscription;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  constructor(private _helper: HelperService,private _form: FormBuilder,
    private _apollo: Apollo,private authFire: AngularFireAuth,private spinnerService: SpinnerService) {
    }

  ngOnInit(): void {
    this.setProfileForm();
    this.passwordChange = new FormGroup({
      currentpassword: new FormControl('', Validators.required),
      newpassword: new FormControl('', [Validators.required,Validators.minLength(8)]),
      confirmpassword: new FormControl('',[Validators.required]),
    });

    this.getProfileData();
  }

  setProfileForm(){
    this.profileForm = new FormGroup({
      firstname: new FormControl((this.userData && this.userData.firstname)?this.userData.firstname:'',[Validators.required]),
      lastname: new FormControl((this.userData && this.userData.lastname)?this.userData.lastname:'',[Validators.required]),
      emailId: new FormControl((this.userData && this.userData.email)?this.userData.email:'',[Validators.required]),
      // username: new FormControl((this.userData && this.userData.username)?this.userData.username:'')
      username: new FormControl('test')
    });
  }


  getProfileData() {
    this.getUserQuery = this._apollo.watchQuery<UserQuery>({
      query: gql`
          query adminDetails{
            adminDetails{
              id
              username
              firstname
              lastname
              email
              profilePic
              userType
              roles{
                userType
              }
              status
            }
          }`
    });
    this.querySubscription = this.getUserQuery.valueChanges.subscribe(({data}) => {
      this.userData = data.adminDetails;
      setLocalStorageItem('DETAILS', this.userData);
      this._helper.setUserDetails(this.userData);
      this.setProfileForm();
    });

  }

  profileUpdate() {
    if(this.profileForm.invalid){
      return;
    }
    this._apollo.mutate({
      mutation: gql`
          mutation adminProfileUpdate($username: String,$firstname: String,$lastname: String,$email: String){
            adminProfileUpdate(username: $username,firstname: $firstname,lastname: $lastname,email: $email){
              status
              code
              message
            }
          }`,
          variables: { username: this.profileForm.value.username,lastname:this.profileForm.value.lastname,firstname:this.profileForm.value.firstname, email:this.profileForm.value.emailId},
    }).subscribe(
      ({ data }: updateProfileResponse) => {
        if(data.adminProfileUpdate.code == '200'){
          this.getUserQuery.refetch();
          this._helper.showToast(1,'Profile Updated Successfully');
        } else{
          this._helper.showToast(4,'Something Went Wrong..Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }

  async changePassword() {
    if(this.passwordChange.invalid){
      return;
    }
    
    if(this.passwordChange.value.newpassword !== this.passwordChange.value.confirmpassword){
      this._helper.showToast(4,'New Password And Confirm Password Should be same');
      return;
    }
    
    const currUser = this.authFire.currentUser;
    this.spinnerService.show();
    const credential = firebase.auth.EmailAuthProvider.credential((await currUser).email, this.passwordChange.value.currentpassword);
    (await currUser).reauthenticateWithCredential(credential).then(async (res1) => {        
      (await currUser).updatePassword(this.passwordChange.value.newpassword).then(success => {
        this.spinnerService.hide();
        this._helper.showToast(1,'Password Updated Successfully');
        this._helper.goToPage('/profile');
      }).catch(error => {
        this._helper.showToast(4,'Something Went Wrong..Try Again');
      });
    }).catch(error => {
      this._helper.showToast(4,'Something Went Wrong..Try Again');
    });
  }
}
