<div id="profile" class="page-layout simple tabbed">
  <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" >
    <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" >
      <div class="name">Dashboard</div>
    </div>
  </div>

  <div>
    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [min]="mindate" [max]="maxdate">
        <input matStartDate formControlName="startdate"  placeholder="Start date">
        <input matEndDate formControlName="enddate" #sdate (dateChange)="change($event)" placeholder="End date" #input>
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <!-- <button mat-raised-button class="reset" (click)="resetFilter()">Reset</button> -->
   <mat-icon class="reset" type="button"  (click)="resetFilter()">close</mat-icon>
   <!-- <button type="button" class="btn export-btn mb-xxl-0 mb-4" (click)="FileDownload()" style="margin-left: 50px;"><i class="far fa-file-word me-2"></i>Generate Report </button> -->
   <div style="float: right;margin: 20px; display: flex;" [matTooltip]="!sdate.value? 'please select a date range':null">
    <button [disabled]="sdate.value =='' "  mat-raised-button color="primary" (click)=FileDownload()>Export</button>

   </div>
  </div>

  

  
  
  <div class="about-content" *ngIf="dashboardData">
      <div id="about"  style="margin-top: 25px;" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Total no. of families</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.familyCount}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Total no. of children</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.childCount}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Total no. of caregivers</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.careGiversCount}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Total no. of activities</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.activityCount}} </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Total admin users</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{'2'}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Total no. of girls</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.girlChild}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Total no. of boys</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.boyChild}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The number of children whose sex is unspecified" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Total no. of unspecified children</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.unspecifiedChild}} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title"> Total no. of completed activities</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.completedActivityCount}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The total number of photos and videos added on the app" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Total no. of media files shared</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.mediaFileShareCount}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The total number of invitations sent to download the app" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Total no. of invitations sent</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.invitationsCount}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The total number of conversions received from the invitations sent" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Total no. of conversions on invitations sent</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.invitationConvertionCount}} </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="about"  class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none"  >
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent" >
              <mat-icon class="view" matTooltip="The number of milestones achieved" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Total no. of milestones achieved</div>
              
            
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.milestoneAcheivedCount}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The total number of screens users have visited in a session" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Total no. of screens per visit</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.totalScreenVisits}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The screens that users are repeatedly using on the app" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Most visited screens</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.mostVisitedScreen}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The features that users are repeatedly using on the app" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Most used features</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.mostVisitedFeature}} </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <!-- <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The amount of time the app takes to start" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">App start time</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{"0"}} </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The total no. of app downloads" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Total downloads</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.totalDownloads}} </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The average amount of time spent on the app by a user" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Average session length</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.averageSession}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20" fxFlex.gt-md="20">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <mat-icon class="view" matTooltip="The number of users who visited your store listing and installed your app, who didn't have it installed on any other devices at the time" matTooltipPosition="above" matTooltipClass="tooltip" >info</mat-icon>
              <div class="title">Store listing acquisitions</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">{{dashboardData.storeCount}} </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="93" fxFlex.gt-sm="93" fxFlex.gt-md="93">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Average age of children registered (active and non-active)</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">
                  <div  *ngIf="dashboardDataActivityhard">
                    <mat-table class="agents-table" #table [dataSource]="ageDatasource" fusePerfectScrollbar>
                      <ng-container matColumnDef="age1">
                        <mat-header-cell *matHeaderCellDef>AgeGroup 0-1</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.agegroup0_1}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="age2">
                        <mat-header-cell *matHeaderCellDef>AgeGroup 1-2</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.agegroup1_2}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="age3">
                        <mat-header-cell *matHeaderCellDef>AgeGroup 2-3</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.agegroup2_3}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="age4">
                        <mat-header-cell *matHeaderCellDef>AgeGroup 3-4</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.agegroup3_4}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="age5">
                        <mat-header-cell *matHeaderCellDef>AgeGroup 4-5</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.agegroup4_5}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="age6">
                        <mat-header-cell *matHeaderCellDef>AgeGroup 5-6</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.agegroup5_6}}
                        </mat-cell>
                      </ng-container>
        
                      <mat-header-row *matHeaderRowDef="ageDisplayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let agent; columns: ageDisplayedColumns" class="agent" matRipple> </mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="93" fxFlex.gt-sm="93" fxFlex.gt-md="93">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Top 5 hard activities</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">
                  <div  *ngIf="dashboardDataActivityhard">
                    <mat-table class="agents-table" #table [dataSource]="hardDatasource" fusePerfectScrollbar>
                      <ng-container matColumnDef="Activity">
                        <mat-header-cell *matHeaderCellDef>Activity Name</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.activity}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Area">
                        <mat-header-cell *matHeaderCellDef>Area</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.area}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Skill">
                        <mat-header-cell *matHeaderCellDef>Skill</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.skill}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Code">
                        <mat-header-cell *matHeaderCellDef>Activity Code</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.activitycode}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.status == '1' ? 'Active':'Inactive'}}
                        </mat-cell>
                      </ng-container>
        
                      <mat-header-row *matHeaderRowDef="hardDisplayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let agent; columns: hardDisplayedColumns" class="agent" matRipple> </mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="93" fxFlex.gt-sm="93" fxFlex.gt-md="93">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Top 5 easy activities</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">
                  <div  *ngIf="dashboardDataActivityeasy">
                    <mat-table class="agents-table" #table [dataSource]="easyDatasource" fusePerfectScrollbar>
                      <ng-container matColumnDef="Activity">
                        <mat-header-cell *matHeaderCellDef>Activity Name</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.activity}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Area">
                        <mat-header-cell *matHeaderCellDef>Area</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.area}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Skill">
                        <mat-header-cell *matHeaderCellDef>Skill</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.skill}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Code">
                        <mat-header-cell *matHeaderCellDef>Activity Code</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.activitycode}}
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="Status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{u.status == '1' ? 'Active':'Inactive'}}
                        </mat-cell>
                      </ng-container>
        
                      <mat-header-row *matHeaderRowDef="easyDisplayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let agent; columns: easyDisplayedColumns" class="agent" matRipple> </mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="93" fxFlex.gt-sm="93" fxFlex.gt-md="93">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Highest viewed blog</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">
                  <div  *ngIf="highestView">
                    <mat-table class="agents-table" #table [dataSource]="highestViewDatasource" fusePerfectScrollbar>
                      <ng-container matColumnDef="caption">
                        <mat-header-cell *matHeaderCellDef>Caption</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{highestView.caption}}
                        </mat-cell>
                      </ng-container>
                     
                      <ng-container matColumnDef="viewCount">
                        <mat-header-cell *matHeaderCellDef>View Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{highestView.viewCount}}
                        </mat-cell>
                      </ng-container>
                      
        
                      <mat-header-row *matHeaderRowDef="highestViewDisplayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let agent; columns: highestViewDisplayedColumns" class="agent" matRipple> </mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="93" fxFlex.gt-sm="93" fxFlex.gt-md="93">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Least viewed blog</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">
                  <div  *ngIf="leastView">
                    <mat-table class="agents-table" #table [dataSource]="leastViewDatasource" fusePerfectScrollbar>
                      <ng-container matColumnDef="caption">
                        <mat-header-cell *matHeaderCellDef>Caption</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{leastView.caption}}
                        </mat-cell>
                      </ng-container>
                     
                      <ng-container matColumnDef="viewCount">
                        <mat-header-cell *matHeaderCellDef>View Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{leastView.viewCount}}
                        </mat-cell>
                      </ng-container>
                      
        
                      <mat-header-row *matHeaderRowDef="leastViewDisplayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let agent; columns: leastViewDisplayedColumns" class="agent" matRipple> </mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" class="p-25" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
        <div class="about-content" fxLayout="column" fxFlex="93" fxFlex.gt-sm="93" fxFlex.gt-md="93">
          <div class="profile-box info-box general" fxLayout="column">
            <header class="accent">
              <div class="title">Last viewed blog</div>
            </header>

            <div class="content">
              <div class="info-line">
                <div class="info">
                  <div  *ngIf="lastViewed">
                    <mat-table class="agents-table" #table [dataSource]="lastViewedDatasource" fusePerfectScrollbar>
                      <ng-container matColumnDef="caption">
                        <mat-header-cell *matHeaderCellDef>Caption</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{lastViewed.caption}}
                        </mat-cell>
                      </ng-container>
                     
                      <ng-container matColumnDef="viewCount">
                        <mat-header-cell *matHeaderCellDef>View Count</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                          {{lastViewed.viewCount}}
                        </mat-cell>
                      </ng-container>
                      
        
                      <mat-header-row *matHeaderRowDef="lastViewDisplayedColumns; sticky: true"></mat-header-row>
                      <mat-row *matRowDef="let agent; columns: lastViewDisplayedColumns" class="agent" matRipple> </mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
