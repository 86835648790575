import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HelperService } from 'app/services/helper.service';
import { Apollo } from "apollo-angular";
import { GET_FEEDING_TRACKER_COUNT, GET_HEALTH_TRACKER_COUNT, GET_DEVELOPMENT_TRACKER_COUNT, GET_SLEEP_TRACKER_COUNT, GET_GROWTH_TRACKER_COUNT, GET_DIAPER_TRACKER_COUNT, GET_ACTIVITY_TRACKER_COUNT, GET_IMMUNIZATION_TRACKER_COUNT, GET_LEAST_MOST_USED_TRACKER_COUNT, GET_AVERAGE_FORMULA_MILK_COUNT, GET_AVERAGE_FEEDING_TIME, GET_AVERAGE_BREAST_TIME, GET_NO_OF_CHILDRN_HAVING_BREASTMILK_PER_DAY, GET_CDC_MILESTONE_COUNT, GET_CDC_MILESTONE_ACHIEVED_COUNT } from './child-growth.service';
import { Subscription } from 'rxjs';

interface CDCMilestoneAchievedResponse {
  getCDCMilestoneAchieved: {
    message: string;
    status: string;
    data: string;
  };
}
@Component({
  selector: 'app-child-growth',
  templateUrl: './child-growth.component.html',
  styleUrls: ['./child-growth.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ChildGrowth implements OnInit {

  selectedMonth: string = null;
  selectedMonthCDCCount: String;
  trackers: { label: string; count: number }[] = [];
  feedingTrackerCount: number = 0;
  healthTrackerCount: number = 0;
  sleepTrackerCount: number = 0;
  growthTrackerCount: number = 0;
  diaperTrackerCount: number = 0;
  activityTrackerCount: number = 0;
  immunizationTrackerCount: number = 0;
  developmentTrackerCount: number = 0;
  LeastUsedTracker: string
  MostUsedTracker: string
  averageformulaMilk: string;
  averageFeedingTime: string;
  averageBreastMilkTime: string;
  noOfChildrenHavingBreastMilkPerDay: number;
  noOfGetCDCMilestoneCount: number;


  private subscriptions: Subscription[] = [];
  constructor(private _helper: HelperService, private _apollo: Apollo) {
  }


  ngOnInit(): void {
    this.selectedMonth = '1 months';
    console.log('selected', this.selectedMonth)
    this.fetchFeedingTrackerCount();
    this.fetchHealthTrackerCount();
    this.fetchSleepTrackerCount();
    this.fetchdevelopmentTrackerCount();
    this.fetchgrowthTrackerCount();
    this.fetchLeastUsedTracker();
    this.fetchMostUsedTracker();
    this.fetchImmunizationTrackerCount();
    this.fetchDiaperTrackerCount();
    this.fetchActivityTrackerCount();
    this.fetchAverageformulaMilk();
    this.fetchAverageFeedingTime();
    this.fetchBreastFeedingTime();
    this.fetchNoOfChildrenHavingBreastMilkPerDay();
    this.fetchGetCDCMilestoneCount();
    this.fetchCDCMilestoneAchieved()

  }

  months: any[] = [

    { value: "1 month", viewValue: "1 month " },
    { value: "2 months", viewValue: "2 months" },
    { value: "3 months", viewValue: "3 months " },
    { value: "4 months", viewValue: "4 months " },
    { value: "5 months", viewValue: "5 months " },
    { value: "6 months", viewValue: "6 months " },
    { value: "7 months", viewValue: "7 months " },
    { value: "8 months", viewValue: "8 months " },
    { value: "9 months", viewValue: "9 months " },
    { value: "10 months", viewValue: "10 months" },
    { value: "11 months", viewValue: "11 months " },
    { value: "1 year", viewValue: "1 Year" },
    { value: "15 months", viewValue: "15 months" },
    { value: "18 months", viewValue: "18 months" },
    { value: "2 years", viewValue: "2 Years " },
    { value: "30 months", viewValue: "30 months " },
    { value: "3 years", viewValue: "3 Years " },
    { value: "4 years", viewValue: "4 Years " },
    { value: "5 years", viewValue: "5 years " },

  ];

  onMonthSelected(month: string) {

    this.selectedMonth = month;
    this.fetchCDCMilestoneAchieved()

  }
  fetchFeedingTrackerCount() {
    this._apollo.query<any>({
      query: GET_FEEDING_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.feedingTrackerCount = data.getFeedingTrackerCount.count;
    });
  }
  fetchHealthTrackerCount() {
    this._apollo.query<any>({
      query: GET_HEALTH_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.healthTrackerCount = data.getHealthTrackerCount.count;
    });
  }
  fetchSleepTrackerCount() {
    this._apollo.query<any>({
      query: GET_SLEEP_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.sleepTrackerCount = data.getSleepTrackerCount.count;
    });
  }
  fetchdevelopmentTrackerCount() {
    this._apollo.query<any>({
      query: GET_DEVELOPMENT_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.developmentTrackerCount = data.getDevelopTrackerCount.count;
    });
  }
  fetchgrowthTrackerCount() {
    this._apollo.query<any>({
      query: GET_GROWTH_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.growthTrackerCount = data.getGrowthTrackerCount.count;
    });
  }
  fetchLeastUsedTracker() {
    this._apollo.query<any>({
      query: GET_LEAST_MOST_USED_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.LeastUsedTracker = data.getLeastMostUsedTracker.leastUsedTracker;
    });
  }
  fetchMostUsedTracker() {
    this._apollo.query<any>({
      query: GET_LEAST_MOST_USED_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.MostUsedTracker = data.getLeastMostUsedTracker.mostUsedTracker;
    });
  }
  fetchImmunizationTrackerCount() {
    this._apollo.query<any>({
      query: GET_IMMUNIZATION_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.immunizationTrackerCount = data.getImmunizationTrackerCount.count;
    });
  }
  fetchDiaperTrackerCount() {
    this._apollo.query<any>({
      query: GET_DIAPER_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.diaperTrackerCount = data.getDiaperTrackerCount.count;
    });
  }
  fetchActivityTrackerCount() {
    this._apollo.query<any>({
      query: GET_ACTIVITY_TRACKER_COUNT
    }).subscribe(({ data }) => {
      this.activityTrackerCount = data.getActivityTrackerCount.count;
    });
  }

  fetchAverageformulaMilk() {
    this._apollo.query<any>({
      query: GET_AVERAGE_FORMULA_MILK_COUNT
    }).subscribe(({ data }) => {
      this.averageformulaMilk = data.getAverageFormulaMilk.average.averageTime;
    });
  }
  fetchAverageBreastMilk() {
    this._apollo.query<any>({
      query: GET_AVERAGE_FORMULA_MILK_COUNT
    }).subscribe(({ data }) => {
      this.averageformulaMilk = data.getAverageFormulaMilk.average.averageTime;
    });
  }

  fetchAverageFeedingTime() {
    this._apollo.query<any>({
      query: GET_AVERAGE_FEEDING_TIME
    }).subscribe(({ data }) => {
      this.averageFeedingTime = data.getAverageFeedingTime.average;
    });
  }

  fetchBreastFeedingTime() {
    this._apollo.query<any>({
      query: GET_AVERAGE_BREAST_TIME
    }).subscribe(({ data }) => {
      this.averageBreastMilkTime = data.getAverageBreastMilk.average;
    });
  }
  fetchNoOfChildrenHavingBreastMilkPerDay() {
    this._apollo.query<any>({
      query: GET_NO_OF_CHILDRN_HAVING_BREASTMILK_PER_DAY
    }).subscribe(({ data }) => {
      this.noOfChildrenHavingBreastMilkPerDay = data.getNoOfChildrenHavingBreastMilkPerDay.count;
    });
  }
  fetchGetCDCMilestoneCount() {
    this._apollo.query<any>({
      query: GET_CDC_MILESTONE_COUNT
    }).subscribe(({ data }) => {
      this.noOfGetCDCMilestoneCount = data.getCDCMilestoneCount.data;
    });
  }
  fetchCDCMilestoneAchieved() {
    this._apollo
      .query<CDCMilestoneAchievedResponse>({
        query: GET_CDC_MILESTONE_ACHIEVED_COUNT,
        variables: { filter: this.selectedMonth },
      })
      .subscribe(({ data }) => {
        this.selectedMonthCDCCount = data.getCDCMilestoneAchieved.data
      });

  }
}