import { gql } from "apollo-angular";

export const GET_FEEDING_TRACKER_COUNT = gql`
  query {
    getFeedingTrackerCount {
      message
      status
      count
    }
  }
`;

export const GET_HEALTH_TRACKER_COUNT = gql`
  query {
    getHealthTrackerCount {
      message
      status
      count
    }
  }
`;

export const GET_DEVELOPMENT_TRACKER_COUNT = gql`
  query {
    getDevelopTrackerCount {
      message
      status
      count
    }
  }
`;

export const GET_SLEEP_TRACKER_COUNT = gql`
  query {
    getSleepTrackerCount {
      message
      status
      count
    }
  }
`;

export const GET_GROWTH_TRACKER_COUNT = gql`
  query {
    getGrowthTrackerCount {
      message
      status
      count
    }
  }
`;

export const GET_DIAPER_TRACKER_COUNT = gql`
  query {
    getDiaperTrackerCount {
      message
      status
      count
    }
  }
`;

export const GET_ACTIVITY_TRACKER_COUNT = gql`
  query {
    getActivityTrackerCount {
      message
      status
      count
    }
  }
`;

export const GET_IMMUNIZATION_TRACKER_COUNT = gql`
  query {
    getImmunizationTrackerCount {
      message
      status
      count
    }
  }
`;

export const GET_LEAST_MOST_USED_TRACKER_COUNT = gql`
  query {
    getLeastMostUsedTracker {
      message
      status
      leastUsedTracker
      mostUsedTracker
    }
  }
  
`;
export const GET_AVERAGE_FORMULA_MILK_COUNT = gql`
query{
  
  getAverageFormulaMilk {
    message
    status
    average {
      milkType
      averageTime
    }
  
}
}
`
export const GET_AVERAGE_FEEDING_TIME = gql`
query{
  getAverageFeedingTime {
    message
    status
    average
  }
}
`

export const GET_AVERAGE_BREAST_TIME = gql`
query{
  getAverageBreastMilk {
    message
    status
    average
  }
}
`
export const GET_NO_OF_CHILDRN_HAVING_BREASTMILK_PER_DAY = gql`
query{
  getNoOfChildrenHavingBreastMilkPerDay {
    message
    status
    count
  }

}
`
export const GET_CDC_MILESTONE_COUNT = gql`
query{
  getCDCMilestoneCount {
    message
    status
    data
  }
}
` 
export const GET_CDC_MILESTONE_ACHIEVED_COUNT = gql`
  query GetCDCMilestoneAchieved($filter: String!) {
    getCDCMilestoneAchieved(filter: $filter) {
      message
      status
      data
    }
  }
`;