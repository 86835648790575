<div id="login" fxLayout="column">
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div
            id="login-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
            style="background-color: #ffffff"
        >
            <div class="logo">
                <img src="assets/images/logos/logo.png" />
            </div>
            <div class="title">LOGIN</div>
            <form name="loginForm" [formGroup]="loginForm" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>Email Id</mat-label>
                    <input matInput formControlName="emailId" type="text" placeholder="Email Id" />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.controls['emailId'].hasError('pattern')">Invalid Email Id </mat-error>
                    <mat-error *ngIf="loginForm.controls['emailId'].hasError('required')"> Email Id is required </mat-error>
               
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" placeholder="Password" />
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="loginForm.controls['password'].hasError('minlength')"> Min Length 8 </mat-error>
                    <mat-error *ngIf="loginForm.controls['password'].hasError('required')"> Password is required </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password text-center" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                     <mat-checkbox class="remember-me" formControlName="isRemember" aria-label="Remember Me">
                        Remember Me
                    </mat-checkbox>
                    <a class="forgot-password"  [routerLink]="'/forgotpassword'">
                        Forgot Password?
                    </a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                    [disabled]="loginForm.invalid" (click)="loginUser()" > LOGIN  </button>
            </form>
        </div>
    </div>
</div>
