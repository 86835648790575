import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { map } from 'rxjs/operators';
import { familyGroup, familyQuery, memberList } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss']
})
export class MembersListComponent implements OnInit {

  loading: boolean = false;
  members:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;

  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['Firstname','lastname','emailId','Status'];
  constructor(private _helper: HelperService,private _apollo: Apollo, private _model: MatDialog) {
    this.getMembers();
   }

  ngOnInit(): void {
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;

    this.getMembers();
  }
  searchMembers(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getMembers();
  }
  getMembers() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : ''}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this._apollo.watchQuery<familyQuery>({
      query: gql`
          query registeredUserList($page: Int, $size: Int,$search: String){
            registeredUserList(page: $page, size: $size,search: $search){
              totalItems
              data{
                id
                emailId
                firstname
                lastname
                myReferralCode
                status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex),
            size: parseInt(pagesize),
            search:search
          },
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.registeredUserList)).subscribe((result) => {
      if (result.totalItems) {
        const ELEMENT_DATA:any = result.data.map((mem: memberList) => ({
          id: mem.id,
          firstName: mem.firstname,
          lastName: mem.lastname,
          emailId:mem.emailId,
          Status: mem.status,
        }));
        this.dataSource = new MatTableDataSource<familyQuery>(ELEMENT_DATA);
        this.members = result;
      } else {
        this._helper.showToast(4,'No Data Found');
      }
    });

  }
}
