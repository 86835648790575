import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map  } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User,UserQuery,adminUsersProfileUpdate } from '../../../models/types';
import { AngularFireAuth }  from '@angular/fire/auth';
import { SpinnerService } from '../../../../shared/spinner.service';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['../users.component.scss'],
})
export class AllUsersComponent implements OnInit {
  errMsg: string = '';
  userData:any;
  roles:any;
  editedData:any;
  viewId:number = -1;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  pageEvent: PageEvent;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  datasource: null;
  pageSize: number;
  editQuery:QueryRef<any>;
  getUserQuery:QueryRef<any>;
  currentPage: number;
  dataSize: number[] = [10, 50, 100];
  private querySubscription: Subscription;
  dataSource: any;
  displayedColumns: string[] = ['FirstName', 'LastName', 'Email', 'Role', 'Status'];

  constructor(private _helper: HelperService,private spinnerService: SpinnerService,private _apollo: Apollo, private _model: MatDialog,private authFire: AngularFireAuth) {
    if(this.userRoles && (this.userRoles.includes(14) || this.userRoles.includes(28) || this.userRoles.includes(49) || this.userRoles.includes(50))){
      this.displayedColumns.push('menu')
    }
  }

  ngOnInit(): void {
    this.getUsers();
  }

  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;

    this.getUsers();
  }

  searchUsers(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getUsers();
  }

  getUsers() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : 0}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getUserQuery = this._apollo.watchQuery<UserQuery>({
      query: gql`
          query adminUsersAll($page: Int, $size: Int, $search: String){
            adminUsersAll(page: $page, size: $size, search: $search){
              totalItems
              data{
                id
                username
                firstname
                lastname
                email
                profilePic
                roles{
                  id
                  userType
                }
                userType
                status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex) ,
            size: parseInt(pagesize),
            search: search
          },
          fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getUserQuery.valueChanges.pipe(map(result => result.data.adminUsersAll)).subscribe((result) => {
      if (result.totalItems) {
        const ELEMENT_DATA:any = result.data.map((user: User,index) => ({
          index : index,
          id: user.id,
          FirstName: user.firstname,
          LastName: user.lastname,
          // Username: user.username,
          Email: user.email,
          Role: user.roles.userType,
          Status: user.status,
        }));
        
        this.dataSource = new MatTableDataSource<UserQuery>(ELEMENT_DATA);
        this.userData = result;
        
      } else {
        this._helper.showToast(4,'No Data Found');
      }
    });

  }

  passwordReset(index) {
    this.viewId = index;
    if(this.userData && this.userData.data && this.userData.data[this.viewId] && this.userData.data[this.viewId].email){
      this.spinnerService.show();
      this.authFire.sendPasswordResetEmail(this.userData.data[this.viewId].email).then((res) => {
        this.spinnerService.hide();
        this._helper.showToast(1,'Password Reset Mail Send Successfully');
      },(err) => {
        this._helper.showToast(4 , err.message);
      })
    }else{
      this._helper.showToast(4,'Email ID is Required For Reset Password');
    }
 
  }
  
  editProfile(userId){
    this._helper.goToPage('edit-user',{state: {userId}});
  }

  changeStatus(id,status){
    let newStatus = (status == 0) ? 0 : ((parseInt(status) === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation adminUsersProfileUpdate($id: ID!,$status: Int){
          adminUsersProfileUpdate(id:$id,status:$status){
            code
            status
          }
        }`,
      variables: {id: parseInt(id),status:newStatus},
    }).subscribe(
      ({ data }: adminUsersProfileUpdate) => {
        if(data.adminUsersProfileUpdate.code == '200'){
          this._helper.showToast(1,(status == 0)?'Users Deleted Successfully':(status == 1)?'Users Disabled Successfully':'Users Activated Successfully');
          this.getUserQuery.refetch();
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
