<app-loader [loading]="loading"></app-loader>

<div class="err-msg" *ngIf="!loading && errMsg">
  <h1 (click)="getSettings()">{{ errMsg }}</h1>
  <h1 [routerLink]="'/admin'">Go Home</h1>
</div>

<div id="settings" class="page-layout simple tabbed">
  <div
    class="header p-24"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between end"
  >
    <div
      class="user-info"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center"
    >
      <div class="name">Admin Settings</div>
    </div>

    <div class="actions" fxLayout="row" fxLayoutAlign="end center">
      <button mat-raised-button aria-label="Save" style="margin-right: 5px" (click)="updateSettings()">Save</button>
    </div>
  </div>

  <div class="settings-content">
    <form name="form" [formGroup]="settingsForm">
      <div id="settings-cont" class="p-24" fxLayout="row wrap">
        <div class="settings-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50">
          <!-- SMTP SETTINGS -->
          <div class="content-box info-box" fxLayout="column">
            <header class="accent">
              <div class="title">SMTP Settings</div>
            </header>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>SMTP Host</mat-label>
                  <input matInput formControlName="smtpHost" />
                </mat-form-field>
              </div>
            </div>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>SMTP User</mat-label>
                  <input matInput formControlName="smtpUser" />
                </mat-form-field>
              </div>
            </div>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>SMTP Port</mat-label>
                  <input type="number" matInput formControlName="smtpPort" />
                </mat-form-field>
              </div>
            </div>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>SMTP Password</mat-label>
                  <input matInput formControlName="smtpPassword" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="settings-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="50">
          <!-- GENERAL SETTINGS -->
          <div class="content-box info-box" fxLayout="column">
            <header class="accent">
              <div class="title">General Settings</div>
            </header>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>Admin Email</mat-label>
                  <input matInput formControlName="adminEmail" />
                </mat-form-field>
              </div>
            </div>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>API Key</mat-label>
                  <input matInput formControlName="apiKey" />
                </mat-form-field>
              </div>
            </div>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>Commission</mat-label>
                  <input matInput type="number" formControlName="commission" />
                  <mat-icon [inline]="true" matSuffix>%</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="settings-cont" class="p-24" fxLayout="row wrap">
        <div class="settings-content" fxLayout="column" fxFlex="50">
          <!-- 1VOICES SETTINGS -->
          <div class="content-box info-box" fxLayout="column">
            <header class="accent">
              <div class="title">1Voices Settings</div>
            </header>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>Terms & Conditions</mat-label>
                  <textarea matInput formControlName="term"></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>Privay & Policies</mat-label>
                  <textarea matInput formControlName="privacy"></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="content">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
                  <mat-label>About Us</mat-label>
                  <textarea matInput formControlName="about"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
