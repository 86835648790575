
<div id="profile" class="page-layout simple tabbed" *ngIf="postDetails">
    <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" >
        <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"  fxLayoutAlign.gt-sm="start center">
            <div class="name">{{postDetails.caption}}</div>
        </div>
        <button mat-raised-button (click)="viewPosts()"><mat-icon>arrow_back</mat-icon>Back</button>
    </div>
    
    <div class="about-content">
        <mat-card >
            <mat-card-content>
                <div id="about" class="p-24" fxLayout="row wrap">
                    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">
                        <div class="profile-box info-box general" fxLayout="column">
                            <header class="accent">
                                <div class="title">Other Details</div>
                            </header>
                            
                            <div class="content">
                                <div class="info-line">
                                    <div class="title">Description</div>
                                    <div class="info"  [innerHTML]="postDetails.description"></div>
                                </div>
                                <!-- <div class="info-line">
                                    <div class="title">Duration</div>
                                    <div class="info">{{blogDetails.duration}}</div>
                                </div> -->
                                <div class="info-line">
                                    <div class="title">Status</div>
                                    <div class="info" *ngIf="postDetails.status === 1">Published</div>
                                    <div class="info" *ngIf="postDetails.status === 2">Not Published</div>
                                    <!-- <div class="info" *ngIf="postDetails.status === 0">Deleted</div> -->
                                </div>  
                            </div>
                        </div>
                    </div>
                    
                    <div class="about-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="35">
                        <div class="profile-box info-box general" fxLayout="column">
                            <div class="content">
                                <div class="info-line">
                                    <div class="title">Media</div>
                                    <div class="info"></div>
                                </div>
                                <div>
                                    <img *ngIf="postDetails.mediaType =='image'" [src]="postDetails.media" onerror="'assets/images/no_image.png';" />
                                    <video *ngIf="postDetails.mediaType =='video'"   controls [attr.src]="postDetails.media" style="max-width: -webkit-fill-available;"></video>                    
                                </div>  
                                                        
                                
                                <!-- <div>
                                    <img [src]="blogDetails.thumb" onerror="'assets/images/no_image.png';" />
                                </div>    -->
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
