import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Role,RoleQuery,updateRole } from '../../../models/types';
import { AddRoleComponent } from '../add-role/add-role.component';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";

export function removeSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      return  {removeSpaces: {value: null}};
    }
    return;
  };
}
@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
  loader: boolean;
  roles:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  roleForm:FormGroup;
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;
  viewId:number = -1;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  editedData:any;
  editRoleQuery:QueryRef<any>;
  getRoleQuery:QueryRef<any>;
  private querySubscription: Subscription;
count:any;
  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['RoleName', 'Status'];
  constructor(private _helper: HelperService,private _apollo: Apollo, private _model: MatDialog) {
    if(this.userRoles && (this.userRoles.includes(19) || this.userRoles.includes(29) || this.userRoles.includes(45) || this.userRoles.includes(46))){
      this.displayedColumns.push('menu')
    }
   }

  ngOnInit(): void {
    this.getRoles();
    
    this.setUserForm();
  }

  searchRoles(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getRoles();
  }

  getRoles() {
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getRoleQuery = this._apollo.watchQuery<RoleQuery>({
      query: gql`
          query RoleList($search:String){
            RoleList(search:$search){
             id
             userType
             status
            }
          }`,
          variables: {
            search: search 
          },
      fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getRoleQuery.valueChanges.pipe(map(result => result.data.RoleList)).subscribe((result) => {
      if(result){
         const ELEMENT_DATA:any = result.map((role: Role,index) => ({
          index:index,
          id: role.id,
          RoleName: role.userType,
          Status: role.status,
        }));
        this.dataSource = new MatTableDataSource<RoleQuery>(ELEMENT_DATA);
        this.roles = result;
        this.count = this.roles.length;
      }else{
        this._helper.showToast(4,'No Roles Found');
      }
    });

  }

  setUserForm() {
    if(!this.editedData){
      return false;
    }
    this.roleForm = new FormGroup({
      id: new FormControl(this.editedData.id|| '', [Validators.required]),
      rolename: new FormControl(this.editedData.userType|| '', [Validators.required,removeSpacesValidator()]),
      status: new FormControl((this.editedData)?((this.editedData.status === 0) ? 0 :this.editedData.status): '', [Validators.required])
    })
  }

  closeModel(): void {
    this._model.closeAll();
  }

  async editPopup(view,index,id) {
    this.viewId = index;
    let showPopup = true;
    this.editRoleQuery = this._apollo.watchQuery<RoleQuery>({
      query: gql`
          query RoleOne($id: ID!){
            RoleOne(id: $id){
              id
              userType
              status
            }
          }`,
          variables: { id: id  },
    });
    this.querySubscription = await this.editRoleQuery.valueChanges.pipe(map(result => result.data.RoleOne)).subscribe((result) => {
      this.editedData = result;
      this.setUserForm();
      if(showPopup == true){
        this._model.open(view,{restoreFocus: false,disableClose: true});
        showPopup = false;
      }
    })
  }

  openAddRole(){
    const dialogRef = this._model.open(AddRoleComponent, {width:"800px", restoreFocus: false,disableClose: true});
    dialogRef.afterClosed().subscribe(() => {
      this.getRoleQuery.refetch();
    });
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;

    this.getRoles();
  }

  editRole() {
    if(this.roleForm.invalid){
      return;
    }
    this._apollo.mutate({
      mutation: gql`
          mutation updateRole($id:ID!,$status: String,$usertype: String){
            updateRole(id:$id,userType: $usertype,status:$status)
          }`,
          variables: { usertype:this.roleForm.value.rolename,id:this.roleForm.value.id,status:(this.roleForm.value.status).toString()},
    }).subscribe(
      ({ data }: updateRole) => {
        if(data.updateRole[0] == true){
          const index = this.dataSource.data.findIndex(x => x.id === this.roleForm.value.id);
          if(this.roleForm.value.status == 2){
            this.dataSource.data.splice(index,1);
          }else{
            this.dataSource.data[index].RoleName = this.roleForm.value.rolename;
            this.dataSource.data[index].Status = this.roleForm.value.status
          }
          this.dataSource._updateChangeSubscription();
          this._model.closeAll();
          this.getRoleQuery.refetch();
          this._helper.showToast(1,'Role Updated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }

  permissions(userType,name){
    this._helper.goToPage('user-permissions',{state: {userType:userType,username:name}});
  }

  changeStatus(id,status){
    let newStatus = (status == 0) ? 0 : ((status === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation updateRole($id: ID!,$status: String){
          updateRole(id:$id,status:$status)
        }`,
      variables: {id: parseInt(id),status:newStatus.toString()},
    }).subscribe(
      ({ data }: updateRole) => {
        if(data.updateRole[0] == true){
          this.getRoleQuery.refetch();
          this._helper.showToast(1,(status == 0)?'Activity Deleted Successfully':(status == 1)?'Activity Disabled Successfully':'Activity Activated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
