import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { LoaderComponent } from './loader/loader.component';
import { SearchBarComponent } from './search-bar/search-bar.component';

@NgModule({
  declarations: [LoaderComponent, SearchBarComponent],
  imports: [CommonModule, MatIconModule],
  exports: [LoaderComponent, SearchBarComponent],
  providers: [],
})
export class UIModule {}
