import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { User } from 'app/_admin/models';
import { getUserStatus, UserStatus } from '../user-status';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  userId: number = +this.activatedRoute.snapshot.paramMap.get('id');
  loading: boolean = false;
  errMsg: string = '';

  userDetails: User;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.getUserDetails();
  }

  getStatus(status: number): UserStatus {
    return getUserStatus(status);
  }

  openDoc(file: string) {
    window.open(file);
  }

  getUserDetails() {
    this.loading = true;
    this.errMsg = '';

  }

  getUserType(type: number) {
    switch (type) {
      case 1:
        return 'Admin';

      case 2:
        return 'Pro User';

      case 3:
        return 'User';

      case 4:
        return 'Sub Admin';

      default:
        return '';
    }
  }

  // Call GET Methods on Tab Change
  onTabChanged(event: MatTabChangeEvent) {
    switch (event.index) {
      case 0:
        this.getUserDetails();
        break;

      case 1:
        break;

      case 2:
        break;
    }
  }
}
