import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { SkillQuery,AreaQuery,updateSkill} from '../../../models/types';
import { SpinnerService } from '../../../../shared/spinner.service';
import { getLocalStorageRoleItem, ROLES } from 'app/helpers/utils';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && !control.value.replace(/\s/g, '').length) {
//       return  {removeSpaces: {value: null}};
//     }
//     return;
//   };
// }
@Component({
  selector: 'app-edit-skills',
  templateUrl: './edit-skills.component.html',
  styleUrls: ['./edit-skills.component.scss']
})
export class EditSkillsComponent implements OnInit {
  skillForm:FormGroup; 
  skill:any;
  filename: string = null;
  imageUrl: any;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];

  skillId: any;
  imageData: File;
  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,private spinnerService: SpinnerService) {
    if(!history || !history.state || !history.state.skillId){
      this._helper.goToPage('view-area');
    }
    this.skillId = history.state.skillId;
    if(this.skillId){
      this.getSkillById();
      this.setForm();
    }
   }

  ngOnInit(): void {}

  setForm(){
    this.skillForm = new FormGroup({
      skillname: new FormControl((this.skill && this.skill.skill)?this.skill.skill:'', [Validators.required,removeSpacesValidator()]),
      // areaId: new FormControl((this.areas && this.skill)?(this.areas.find(c => parseInt(c.id) == this.skill.areaId)):'', [Validators.required]),
      skillIcon: new FormControl(''),
      status: new FormControl((this.skill)?((this.skill.status === 0) ? 0 :this.skill.status): '', [Validators.required])
    })
  }
  getSkillById() {
    this._apollo.watchQuery<SkillQuery>({
      query: gql`
          query skillOne($id: ID!){
            skillOne(id: $id){
              id
              skill
              skillIcon
              area{
                areaName
                id
              }
              status
            }
          }`,
          variables: { id: this.skillId  },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.skillOne)).subscribe((result) => {
      this.skill = result;
      this.imageUrl = this.skill.skillIcon;
      // this.filename = this.skill.skillIcon;
      this.setForm();
    })
  }
  
  async editSkill(){
    if(this.skillForm.invalid){
      return;
    }
    if(this.imageData && this.imageData.name){
      this.getImageUrl();
    }else{
      this.updateSkillData();
    }
  }

  updateSkillData(){
    this._apollo.mutate({
      mutation: gql`
        mutation updateSkill($skill: String,$skillIcon:String,$areaId:Int,$id:ID!,$status:Int){
          updateSkill(skill: $skill,skillIcon:$skillIcon,areaId:$areaId,id:$id,status:$status)
        }`,
      variables: {id: this.skillId ,skill:this.skillForm.value.skillname,
        skillIcon:(this.filename)?this.filename:this.skill.skillIcon,status:this.skillForm.value.status,
        areaId:parseInt(this.skill.area.id)},
    }).subscribe(
      ({ data }: updateSkill) => {
        if(data.updateSkill[0] == true){
          this._helper.showToast(1,'Skill Updated Successfully');
          this._helper.goToPage('view-skills',{state: {areaId:this.skill.area.id,areaname:this.skill.area.areaName}});
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }

  getImageUrl(){
    let imgnam = Date.now()+'-'+this.imageData.name;
    const fileRef = this.storage.ref(`/skills/${imgnam}`);
    this.spinnerService.show();
    this.storage.upload(`/skills/${imgnam}`, this.imageData).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){            
            this.filename = url;
            this.updateSkillData();
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })
    ).subscribe();
  }

  onFileChanged(event) {
    if(!event.target.files || !event.target.files[0]) {
      this.imageUrl = false;
      return false;
    }

    this.imageData = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imageData);

    reader.onload = (event) => {
      this.imageUrl = reader.result;
      this.filename = this.imageData.name;
    }
  }
  
  cancel(){
    this._helper.goToPage('/admin/view-skills')
  }
}
