import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Activity, DashboardData } from '../../models/types';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { result } from 'lodash';
import {MatIconModule} from '@angular/material/icon';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation:ViewEncapsulation.None 
})
export class DashboardComponent implements OnInit {
  dashboardData:any;
  dashboardDataActivityhard:any
  dashboardDataActivityeasy:any
  highestView:any
  hardDatasource: any;
  easyDatasource: any;
  highestViewDatasource:any;
  leastViewDatasource:any;
  lastViewedDatasource:any;
  leastView:any;
  lastViewed:any;
  ageDatasource: any;
  startdate:any;
  enddate:any;
  filter:string;
  sdate:any;
  edate:any;
  dates:any;
  dateobj:any;
  myDate = new Date();
  avg:any;
  mindate=new Date("2021-08-01");
  maxdate=new Date();
  

  

  hardDisplayedColumns: string[] = ['Activity','Area','Skill','Code','Status'];
  easyDisplayedColumns: string[] = ['Activity','Area','Skill','Code','Status'];
  ageDisplayedColumns: string[] = ['age1','age2','age3','age4','age5','age6'];
  highestViewDisplayedColumns: string[] = ['caption','viewCount',];
  leastViewDisplayedColumns: string[] = ['caption','viewCount',];
  lastViewDisplayedColumns: string[] = ['caption','viewCount',];
  constructor(private _helper: HelperService,private _apollo: Apollo,private datePipe: DatePipe) {
    this.getDashboardData();
  }

  range=new FormGroup({
    startdate:new FormControl(null),
    enddate:new FormControl(null)
  });

  ngOnInit(): void {
   
  }

  

  getDashboardData(){

    // this.range.value.startdate=this.datePipe.transform(this.range.value.startdate,"yyyy/MM/dd")
    // this.range.value.enddate=this.datePipe.transform(this.range.value.enddate,"yyyy/MM/dd")
   
    this._apollo.query<DashboardData>({
      query: gql`
      query dashboardDataList( $filtertype: Int, $startingDate: String, $endingDate: String){
        dashboardDataList(filtertype:$filtertype,startingDate:$startingDate,endingDate:$endingDate){
    familyCount
    childCount
    careGiversCount
    activityCount
    adminUsersCount
    boyChild
    girlChild
    unspecifiedChild
    hardChildActivity {
      id
      activityName
      media
      mediaType
      interactions
      indicators
      duration
      materials
      objective
      description
      comment
      evaluation
      place
      other
      areaId
      skillId
     
      priority
      activityCode
      indicatorSkillIds
     
      ageGroup
      indSkill
      multiMilestones
      status
    }
    easychildActivity {
      id
      activityName
      media
      mediaType
      interactions
      indicators
      duration
      materials
      objective
      description
      comment
      evaluation
      place
      other
      areaId
      skillId
      
      priority
      activityCode
      indicatorSkillIds
      
      ageGroup
      indSkill
      multiMilestones
      status
    }
    increaseUserCOunt
    resolvedCount
    newTicketsCount
    processingCount
    milestoneAcheivedCount
    sessionActiveCount
    userEngageinTotalCount
    blogView {
      id
      caption
      viewCount
    }
    highestView {
      id
      caption
      viewCount
    }
    leastView {
      id
      caption
      viewCount
    }
    lastViewed {
      id
      caption
      viewCount
    }
    storeCount
    mostVisitedFeature
    totalScreenVisits
    mostVisitedScreen
    completedActivityCount
    mediaFileShareCount
    invitationsCount
    invitationConvertionCount
    childAgeGroup0_1
    childAgeGroup1_2
    childAgeGroup2_3
    childAgeGroup3_4
    childAgeGroup4_5
    childAgeGroup5_6
    mostVisitedScreen
    totalScreenVisits
    mostVisitedFeature
    storeCount
    totalDownloads
    appTime
    averageSession
  
  }
      }`,variables:{filtertype: 3,startingDate:this.range.value.startdate ? this.datePipe.transform(this.range.value.startdate,"yyyy-MM-dd") :"2021-8-1",endingDate:this.range.value.enddate ? this.datePipe.transform(this.range.value.enddate,"yyyy-MM-dd"): this.datePipe.transform(this.myDate,"yyyy-MM-dd")},  
    }).pipe(map(result => result.data.dashboardDataList)).subscribe((result) => {
      
      if(result){
        this.dashboardData = result;
        // this.avg= this.dashboardData.averageSession.split(':')[0]
        
        const ELEMENT_DATA:any = this.dashboardData.hardChildActivity.map((acti: Activity) => ({
          activity: acti.activityName,
          area: (acti && acti.area && acti.area.areaName)?acti.area.areaName:'',
          skill : (acti && acti.skills && acti.skills.skill)?acti.skills.skill:'',
          activitycode : acti.activityCode,
          indicators : acti.indicators,
          status: acti.status,
        }));
        this.hardDatasource = new MatTableDataSource<DashboardData>(ELEMENT_DATA);
        this.dashboardDataActivityhard = this.dashboardData.easychildActivity;

        const ELEMENT_DATA_EASY:any = this.dashboardData.easychildActivity.map((acti: Activity) => ({
          activity: acti.activityName,
          area: (acti && acti.area && acti.area.areaName)?acti.area.areaName:'',
          skill : (acti && acti.skills && acti.skills.skill)?acti.skills.skill:'',
          status: acti.status,
          activitycode: acti.activityCode,
          indicators: acti.indicators,
        }));
        this.easyDatasource = new MatTableDataSource<DashboardData>(ELEMENT_DATA_EASY);
        this.dashboardDataActivityeasy = this.dashboardData.easychildActivity;
        


        const ELEMENT_DATA_highestView:any = [this.dashboardData.highestView]
        this.highestViewDatasource = new MatTableDataSource<DashboardData>(ELEMENT_DATA_highestView);
        this.highestView=this.dashboardData.highestView;

        const ELEMENT_DATA_leastView:any = [this.dashboardData.leastView]
        this.leastViewDatasource = new MatTableDataSource<DashboardData>(ELEMENT_DATA_highestView);
        this.leastView=this.dashboardData.leastView;

        const ELEMENT_DATA_lastViewed:any = [this.dashboardData.lastViewed]
        this.lastViewedDatasource = new MatTableDataSource<DashboardData>(ELEMENT_DATA_highestView);
        this.lastViewed=this.dashboardData.lastViewed;
        
        const ELEMENT_DATA_AGEGROUP:any = [{
          agegroup0_1: this.dashboardData.childAgeGroup0_1,
          agegroup1_2: this.dashboardData.childAgeGroup1_2,
          agegroup2_3 : this.dashboardData.childAgeGroup2_3,
          agegroup3_4: this.dashboardData.childAgeGroup3_4,
          agegroup4_5:this.dashboardData.childAgeGroup4_5,
          agegroup5_6:this.dashboardData.childAgeGroup5_6,
        }];
        this.ageDatasource = new MatTableDataSource<DashboardData>(ELEMENT_DATA_AGEGROUP);
      

      }
      
    
      
    },(er)=>{
   
      
    });
  }

  FileDownload(){
    this.dateobj=JSON.parse(localStorage.getItem("lastname"))
  this._apollo.watchQuery<any>({
    query:gql`query dashboardExport($filtertype:Int , $startingDate: String, $endingDate: String){
      dashboardExport(filtertype: $filtertype, startingDate: $startingDate, endingDate: $endingDate) {
        fileName
      }
    }`,variables:{filtertype: this.dateobj.sd && this.dateobj.ed ? 3 : 1,startingDate:this.dateobj.sd,endingDate:this.dateobj.ed},  
    
  }).valueChanges.subscribe((result)=>{
    const file:any=result;

    window.open(file.data.dashboardExport.fileName, '_blank', );
    
  })
  }


 
  

  change(event){
   
    
     this.sdate=this.datePipe.transform(this.range.value.startdate,"yyyy-MM-dd")
  this.edate=  this.datePipe.transform(this.range.value.enddate,"yyyy-MM-dd")
    if (this.sdate && this.edate){

 this.dates={sd:this.sdate,ed:this.edate}
localStorage.setItem("lastname", JSON.stringify(this.dates));
// localStorage.getItem("lastname")





      this.getDashboardData()
    }

    
  }

  resetFilter(){
    
      this.startdate=null,
      this.enddate=null,
      this.range.get("startdate").setValue(null)
      this.range.get("enddate").setValue(null)
    
  }
  
}
