import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, finalize} from 'rxjs/operators';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { CMSQuery,updateSettingData} from '../../../models/types';
import { AngularEditorConfig } from '@kolkov/angular-editor';
@Component({
  selector: 'app-cms-management',
  templateUrl: './cms-management.component.html',
  styleUrls: ['./cms-management.component.scss']
})
export class CmsManagementComponent implements OnInit {

  cmsForm:FormGroup;
  cms:any;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      minHeight: '10rem',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: []
};

  constructor(private _helper: HelperService,private _apollo: Apollo) {
    this.getCMS();
    this.setForm();
   }

  ngOnInit(): void {

  }
  setForm(){
    this.cmsForm = new FormGroup({
      helpguide: new FormControl((this.cms && this.cms.help)?this.cms.help:'', [Validators.required]),
      privacy: new FormControl((this.cms && this.cms.privacy)?this.cms.privacy:'', [Validators.required]),
      terms: new FormControl((this.cms && this.cms.term)?this.cms.term:'', [Validators.required]),
      about: new FormControl((this.cms && this.cms.about)?this.cms.about:'', [Validators.required]),
    })
  }

  updateCMS(){
    if(this.cmsForm.invalid){
      return;
    }
    this._apollo.mutate({
      mutation: gql`
        mutation updateSettingData($term: String,$privacy:String,$id:ID!,$help:String,$about:String){
          updateSettingData(term: $term,privacy:$privacy,id:$id,help:$help,about:$about){
            code
            status
          }
        }`,
      variables: {id: parseInt(this.cms.id ),term:this.cmsForm.value.terms,
        privacy:this.cmsForm.value.privacy,help:this.cmsForm.value.helpguide,about:this.cmsForm.value.about},
    }).subscribe(
      ({ data }: updateSettingData) => {
        if(data.updateSettingData.code == '200'){
          this._helper.showToast(1,'CMS Data Updated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
          
  }
  getCMS() {
    this._apollo.watchQuery<CMSQuery>({
      query: gql`
          query getSettings{
            getSettings{
              term
              privacy
              help
              id
              about
            }
          }`,
          variables: {search: ''},
          fetchPolicy:'network-only'
    }).valueChanges.pipe(map(result => result.data.getSettings)).subscribe((result) => {
      if(result){
        this.cms = result;
        this.setForm();
      }
    });
  }
  
}
