import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { HelperService } from "app/services/helper.service";
import { AngularFireAuth }  from '@angular/fire/auth';
import { Apollo, gql } from "apollo-angular";
import { SpinnerService } from '../shared/spinner.service';
@Component({
  selector: 'forgot-password',
  templateUrl: './forgotpsw.component.html',
  styleUrls: ['./forgotpsw.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ForgotpswComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  isMessage: any;
  loader: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _helper: HelperService,
        private spinnerService: SpinnerService,
        private authFire: AngularFireAuth
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void
    {
        this.forgotPasswordForm = this._formBuilder.group({
            emailId : ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]]
        });
    }
    
    forgotPassword(): void {
        if (this.forgotPasswordForm.valid) {
            this.spinnerService.show();
            this.authFire.sendPasswordResetEmail(this.forgotPasswordForm.value.emailId).then((res) => {
                this._helper.showToast(1,'Password Reset Mail Send Successfully');
            },(err) => {
                this._helper.showToast(4 , err.message);
            })
            this.spinnerService.hide();
        }
    }
}
