<div id="agents" class="page-layout carded fullwidth">
  <div class="top-bg accent"></div>

  <div class="center">
    <div
      class="header accent"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center"
    >
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16"> home </mat-icon>
        <span class="logo-text h1"> Family List </span>
      </div>

      <!-- SEARCH -->
      <app-search-bar placeholder="Search for Family" (searchEvent)="searchFamily($event)"> </app-search-bar>
      
      <!-- <button mat-raised-button [routerLink]="'/admin/add-activity'"><mat-icon>add</mat-icon> Add Activity</button> -->
    </div>

    <div class="content-card" style="overflow-y: scroll">
      <h1 class="err-msg" *ngIf="!familyDetails" (click)="getRoles()">No Data Found</h1>

      <div *ngIf="familyDetails">
        <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
          <!-- Name Column -->
          <ng-container matColumnDef="FamilyName">
            <mat-header-cell *matHeaderCellDef>Family Name</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate">{{u.familyName}} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="CareGiver">
            <mat-header-cell *matHeaderCellDef>CareGiver </mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate">{{u.firstName }} {{ u.lastName}} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="CreatedAt">
            <mat-header-cell *matHeaderCellDef>Created</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate">{{u.createdAt | date : 'yyyy-MM-dd'}} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="MemberCount">
            <mat-header-cell *matHeaderCellDef>CareGiver Count</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate">{{u.memberCount }} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ChildCount">
            <mat-header-cell *matHeaderCellDef>Child Count</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate">{{u.childCount }} </p>
            </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="Status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate">
                {{u.Status == '1' ? 'Active':'Inactive'}}
              </p>
            </mat-cell>
          </ng-container>

          <!-- Controls Column -->
          <ng-container matColumnDef="menu">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let u">
              <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ id: u.id, status: u.status}" aria-label="Menu" >
                <mat-icon>more_vert</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-id="id" let-status="status">
              <button mat-menu-item  (click)="viewDetails(id)">
                <mat-icon>visibility</mat-icon>
                <span>View Details</span>
              </button>
            </ng-template>
          </mat-menu>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

          <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
        </mat-table>

        <mat-paginator
          #paginator
          [length]="familyDetails.totalItems"
          [pageSize]="pSize"
          [pageSizeOptions]="dataSize"
          [pageIndex]="familyDetails.currentPage"
          (page)="changePage($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
