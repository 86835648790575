<div mat-dialog-content>
    <div class="profile-box info-box general" fxLayout="column">
        <mat-card class="case-document">
            <div class="content-card" style="overflow-y: scroll">
                <img [src]="data.image"  onerror="this.src='./assets/images/no_image.png';"/>
                
            </div>
        </mat-card>
    </div>
</div>

<div mat-dialog-actions>
  <!-- <button mat-button (click)="closeModal()">Close</button> -->
  <button style="margin:0 auto;background: #585178;"  (click)="closeModal()" mat-raised-button color="primary" mat-dialog-close>Close</button>

</div>