import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { HelperService } from 'app/services/helper.service';
import { SpinnerService } from '../../../../shared/spinner.service';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
import { result } from 'lodash';
interface AddMilestoneTrackerResponse {
  data: {
    addDevMilestoneTracker: {
      message: string;
      status: string;
      data: {
        id: string;
        name: string;
        img: string;
        milestone: string;
        createdAt: string;
        status: number;
      };
    };
  };
}





@Component({
  selector: 'app-add-tracker-milestone',
  templateUrl: './add-tracker-milestone.component.html',
  styleUrls: ['./add-tracker-milestone.component.scss']
})
export class AddAMilestoneComponent implements OnInit {

  @Output() onTrackerMilestoneAdded = new EventEmitter();
  @Output() selectedMonthChange = new EventEmitter<string>();
  @Output() milestoneAdded = new EventEmitter<void>();
  @Input() trackerId: string;
  @Input() milestoneMonth: string;
  milestoneForm:FormGroup; 
  filename: string = null;
  imageUrl: any;
  imageData: File;


  months: any[] = [
    
    { value: "1 month", viewValue: "1 month " },
    { value: "2 months", viewValue: "2 months" },
    { value: "3 months", viewValue: "3 months " },
    { value: "4 months", viewValue: "4 months " },
    { value: "5 months", viewValue: "5 months " },
    { value: "6 months", viewValue: "6 months " },
    { value: "7 months", viewValue: "7 months " },
    { value: "8 months", viewValue: "8 months " },
    { value: "9 months", viewValue: "9 months " },
    { value: "10 months", viewValue: "10 months" },
    { value: "11 months", viewValue: "11 months " },
    { value: "1 year", viewValue: "1 Year" },
    { value: "15 months", viewValue: "15 months" },
    { value: "18 months", viewValue: "18 months" },
    { value: "2 years", viewValue: "2 Years " },
    { value: "30 months", viewValue: "30 months " },
    { value: "3 years", viewValue: "3 Years " },
    { value: "4 years", viewValue: "4 Years " },
    { value: "5 years", viewValue: "5 years " },
    
  ];



  

  

  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,private spinnerService: SpinnerService) { }

  ngOnInit(): void {

    this.milestoneForm = new FormGroup({
      month: new FormControl(this.milestoneMonth, [Validators.required,removeSpacesValidator()]),
      milestoneValue:new FormControl('',[Validators.required,removeSpacesValidator()]),
      milestoneIcon: new FormControl('', [Validators.required])
    
    })
    
  }
  emitSelectedMonth() {
    const selectedMonth = this.milestoneForm.get('month').value;
    this.selectedMonthChange.emit(selectedMonth)
  }


  resetForm(formDirective: FormGroupDirective) {
    this.milestoneForm.get('milestoneValue').reset('');
    this.milestoneForm.get('milestoneIcon').reset('');
    formDirective.resetForm({
      month: this.milestoneForm.get('month').value
    });
    this.filename = null;
    this.imageUrl = null;
    this.imageData = null;
    
  }
  addMilestone(formDirective: FormGroupDirective){
    if(this.milestoneForm.invalid){
      return;
    }
    let imgnam = Date.now()+'-'+this.imageData.name;
    const fileRef = this.storage.ref(`/categories/${imgnam}`);
    this.spinnerService.show();
    this.storage.upload(`/categories/${imgnam}`, this.imageData).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){
            this._apollo.mutate({
              mutation: gql`
                mutation addDevMilestoneTracker($milestone: String, $img: String, $months: String, $developmentTrackerId: Int) {
                  addDevMilestoneTracker(milestone: $milestone, img: $img, months: $months, developmentTrackerId: $developmentTrackerId) {
                    message
                    status
                    data {
                      id
                      milestone
                      img
                      months
                      createdAt
                      status
                    }
                  }
                }`,
              variables: {
                developmentTrackerId: this.trackerId,
                milestone: this.milestoneForm.value.milestoneValue,
                img: url,
                months: this.milestoneForm.value.month
              }
            }).subscribe(
              ({ data }: AddMilestoneTrackerResponse) => {
                if(data.addDevMilestoneTracker.data.id){
                  this._helper.showToast(1,'Milestone Added Successfully');
                  const selectedMonth = this.milestoneForm.get('month').value;
                  this.milestoneAdded.emit(selectedMonth);
                  this.resetForm(formDirective);
                }else{
                  this._helper.showToast(4,'Something Went Wrong.. Try Again');
                }
              },(err) => {
                this._helper.showToast(4,err.message);
              }
            );
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })
    ).subscribe();
  }

  
  
  onFileChanged(event) {
    if(!event.target.files || !event.target.files[0]) {
      this.imageUrl = false;
      return false;
    }

    this.imageData = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imageData);

    reader.onload = (event) => {
      this.imageUrl = reader.result;
      this.filename = this.imageData.name;
    }
  }
}
