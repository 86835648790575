<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    
    <div class="center">
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16"> supervised_user_circle </mat-icon>
                <span class="logo-text h1"> Add User</span>
            </div>
        </div>
        
        <div class="content-card" style="overflow-y: scroll">
            <div >
              <mat-card >
                <mat-card-content>
                  <form [formGroup]="addUserForm" (ngSubmit)="addUser()">
                    <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                      <mat-label>First Name</mat-label>
                      <input matInput formControlName="firstname" type="text" placeholder="First Name" />
                      <mat-error *ngIf="addUserForm.controls['firstname'].hasError('required')">First Name is Required </mat-error>
                      <mat-error *ngIf="addUserForm.controls['firstname'].hasError('pattern')">Invalid First Name </mat-error>
                      <mat-error *ngIf="addUserForm.controls['firstname'].hasError('removeSpaces')">Invalid First Name</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                      <mat-label>Last Name</mat-label>
                      <input matInput formControlName="lastname" type="text" placeholder="Last Name" />
                      <mat-error *ngIf="addUserForm.controls['lastname'].hasError('pattern')">Invalid Last Name </mat-error>
                      <mat-error *ngIf="addUserForm.controls['lastname'].hasError('required')">Last Name is Required </mat-error>
                      <mat-error *ngIf="addUserForm.controls['lastname'].hasError('removeSpaces')">Invalid Last Name</mat-error>
                    </mat-form-field>
            
                    <!-- <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                      <mat-label>UserName</mat-label>
                      <input matInput formControlName="username" type="text" placeholder="Username" />
                      <mat-error *ngIf="addUserForm.controls['username'].hasError('required')">UserName is Required </mat-error>
                    </mat-form-field> -->
            
                    <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                      <mat-label>Email ID</mat-label>
                      <input matInput formControlName="emailid" type="text" placeholder="Email Id" />
                      <mat-error *ngIf="addUserForm.controls['emailid'].hasError('pattern')">Invalid Email Id </mat-error>
                      <mat-error *ngIf="addUserForm.controls['emailid'].hasError('required')">Email Id is Required </mat-error>

                    </mat-form-field>
            
                    <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                      <mat-label>UserType</mat-label>
                      <mat-select formControlName="usertype" placeholder="Choose UserType">
                        <mat-option selected [value]="-1" disabled>Choose UserType</mat-option>
                        <mat-option *ngFor="let role of roles" [value]="role.id">
                          {{role.userType}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="addUserForm.controls['usertype'].hasError('required')">UserType is Required </mat-error>
                  </mat-form-field>
            
                    <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                      <mat-label>Password</mat-label>
                      <input matInput formControlName="password" type="password" placeholder=" Password" />
                      <mat-error *ngIf="addUserForm.controls['password'].hasError('required')">Password is Required </mat-error>
                      <mat-error *ngIf="addUserForm.controls['password'].hasError('minlength')">Min Length is 8</mat-error>
                    </mat-form-field>
                    <br/>
                    <br/>
                    <button mat-raised-button color="primary"  [disabled]="addUserForm.invalid" >
                      <span>Save</span>
                    </button>
                    &nbsp;&nbsp;
                    <button mat-stroked-button  (click)="cancel()" >
                        <span>Cancel</span>
                    </button>
                  </form>
                </mat-card-content>
              </mat-card>
            </div>
        </div>
    </div>
</div>

