<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    
    <div class="center">
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16"> flare </mat-icon>
                <span class="logo-text h1" > Set Permissions - {{username}}</span>
            </div>
            <button mat-raised-button [routerLink]="'/admin/roles'"><mat-icon>arrow_back</mat-icon>Back</button>
            
        </div>
        
        <div class="content-card" style="overflow-y: scroll;">
            
            <mat-grid-list cols="4"  rowHeight="300px" *ngIf="modules">
                <mat-grid-tile *ngFor="let roles of modules ">
                    <mat-card  style="width: 100%;height: 96%;">
                        <mat-grid-header>
                            <b>{{(roles.id == 5 )? 'UserRoles' : (roles.name)}}  </b> 
                        </mat-grid-header><br/><br/> 
                        <mat-list >
                            <mat-list-item *ngFor="let items of roles.module; let i=index">
                                &nbsp;<mat-checkbox [checked]="checkedBoxorNot(items.id)" (change)= "getSelectedValue($event.checked,items.id,roles.id,items.display)" [value]="items.id" class="example-margin"> </mat-checkbox>
                                {{items.display}}<br/><br/>
                            </mat-list-item>
                        </mat-list>
                    </mat-card>
                </mat-grid-tile>
            </mat-grid-list><br/><br/>
            <div class="text-center bottom-buttons">
                <button *ngIf="this.userRoles && this.userRoles.includes(22)" mat-raised-button color="primary"  (click)="assignRoles()"  class="w-10-p">
                    <span>Save</span>
                </button>&nbsp;&nbsp;
                <button mat-raised-button color="primary"   [routerLink]="'/admin/roles'" class="w-10-p" >
                    <span>Cancel</span>
                </button>
            </div>

        </div>
    </div>
</div>