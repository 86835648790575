import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Apollo, gql, QueryRef, } from "apollo-angular";
import { Subscription } from 'rxjs';
import { TrackerQuery, deleteTrack, MilestoneTrackerQuery, DevMilestoneTrackerByIdQuery, ChangeMilestoneStatusResponse } from '../../../models/types';
import { map, take } from 'rxjs/operators';
import { HelperService } from 'app/services/helper.service';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';



export interface Element {
  id: number;
  name: string;
  value: string;
  count: number;
}

@Component({
  selector: 'app-view-milestones',
  templateUrl: './ViewTrackMilestones.component.html',
  styleUrls: ['./ViewTrackMilestones.component.scss']
})
export class ViewTrackMilestonesComponent implements OnInit {
  pIndex: number = 0;
  pSize: number = 10;
  totalItems: any;
  currentPage: number;
  dataSize: number[] = [10, 50, 100];
  searchKeyword: string;
  displayedColumns: string[] = ['MileStone', 'Icon', 'menu'];
  trackerForm: FormGroup;
  dataSource = new MatTableDataSource<any>([]);
  milestones: any;
  trackerId: number;
  trackerName: string;
  getMilestoneQuery: QueryRef<any>;
  milestoneMonth: string = '1 month'
  private querySubscription: Subscription;
  constructor(private _helper: HelperService, private _apollo: Apollo, public dialog: MatDialog, private storage: AngularFireStorage, private route: ActivatedRoute) {
    this.dataSource = new MatTableDataSource<any>([]);

  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.trackerId = +params['trackerId'];
      this.trackerName = params['trackerName'];
      this.milestoneMonth = params['month'] || '1 month';


      this.getMilstoneData()
    });
  }


  handleSelectedMonthChange(selectedMonth: string) {
    this.milestoneMonth = selectedMonth ? selectedMonth : this.milestoneMonth;
    this.getMilstoneData();
  }

  searchMilestone(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;
    this.getMilstoneData();
  }


  onMilestoneAdded(selectedMonth: string) {
    this.milestoneMonth = selectedMonth;
    this.getMilstoneData();
  }

  getMilstoneData() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
   
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;
    this.getMilestoneQuery = this._apollo.watchQuery<MilestoneTrackerQuery>({
      query: gql`
        query devTrackersMilestone($id: ID!, $month: String, $search: String) {
          devTrackersMilestone(
            id: $id
            filter: { month: $month }
            search: $search
          ) {
            message
            status
            data {
              id
              milestone
              img
              months
              createdAt
              status
              developmentTrackerId
            }
            
            }
        }
      `,
      variables: {
        id: this.trackerId,
        month: this.milestoneMonth, 
        search: search,
      },
      fetchPolicy: 'no-cache',
    });
    
    this.querySubscription = this.getMilestoneQuery.valueChanges.pipe(map(result => result)).subscribe((result) => {
      if (result) {
        console.log(result)
        const milestoneData = result.data.devTrackersMilestone.data;
        this.totalItems = milestoneData.length
        const ELEMENT_DATA: any = milestoneData.map((item, index) => ({
          index: index,
          milestone: item.milestone,
          months: item.months,
          id: item.id,
          status: item.status,
          icon: item.img,
        }))
        this.dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
        this.dataSource = new MatTableDataSource<TrackerQuery>(ELEMENT_DATA);
       

      }
    });


  }




  editTrack(id: any) {
    this._helper.goToPage('edit-milestone', { queryParams: { id: id, trackerId: this.trackerId, trackerName: this.trackerName, month: this.milestoneMonth } });
  }



  deleteItem(id: any) {
    if (id) {
      this._apollo.mutate({
        mutation: gql`
    mutation deleteDevMilestoneTracker($id:ID ) {
      deleteDevMilestoneTracker(id: $id) {
               message
               status
               success
             }
           }
    `,
        variables: { id: id },
      }).subscribe(
        ({ data }: deleteTrack) => {
          this.getMilstoneData();
          this._helper.showToast(1, 'Milestone deleted Successfully');

        },
        (err) => {
          this._helper.showToast(4, err.message);
        }
      )
    }
  }


  changeStatus(id, status) {
    let newStatus = (status == 0) ? 0 : ((parseInt(status) === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation changeDevStatusMilestoneTracker($id: ID, $status: Int) {
          changeDevStatusMilestoneTracker(id: $id, status: $status) {
            message
            status
            success
          }
        }
      `,
      variables: { id: parseInt(id), status: newStatus },
    }).subscribe(
      ({ data }: ChangeMilestoneStatusResponse) => {
        if (data.changeDevStatusMilestoneTracker.success === true) {
          this._helper.showToast(1, 'Milestone Status Updated Successfully');
          this.getMilstoneData();
        }
      },
      (err) => {
        this._helper.showToast(4, err.message);
      }
    );
  }
}



