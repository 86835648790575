export interface UserStatus {
  icon: string;
  title: string;
}

export const getUserStatus = (status: number): UserStatus => {
  switch (status) {
    case 1:
      return { icon: 'done', title: 'Accepted' };

    case 2:
      return { icon: 'access_time', title: 'Requested' };

    case 3:
      return { icon: 'close', title: 'Rejected' };

    default:
      return { icon: '', title: status.toString() };
  }
};
