import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsfeeddetails',
  templateUrl: './newsfeeddetails.component.html',
  styleUrls: ['./newsfeeddetails.component.scss']
})
export class NewsfeeddetailsComponent implements OnInit {
  @Input() newsData: any;
  constructor() { 
    
  }

  ngOnInit(): void {
    
  }

}
