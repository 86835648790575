import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Tips,TipQuery, updateTips } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";

@Component({
  selector: 'app-viewtip',
  templateUrl: './viewtip.component.html',
  styleUrls: ['./viewtip.component.scss']
})
export class ViewtipComponent implements OnInit {
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;
  getTipQuery:QueryRef<any>;
  private querySubscription: Subscription;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['Caption','Message','Learn','Status'];
  tips:any;

  constructor(private _helper: HelperService,private _apollo: Apollo) {
    if(this.userRoles && (this.userRoles.includes(32) || this.userRoles.includes(51) || this.userRoles.includes(52))){
      this.displayedColumns.push('menu')
    }
    this.getTips();
   }

  ngOnInit(): void {
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;
    this.getTips();
  }
  searchTips(keyword: string){
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getTips();
  }
  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  getTips() {    
    const pageindex = `${this.pIndex ? `${this.pIndex}` : 0}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getTipQuery = this._apollo.watchQuery<TipQuery>({
      query: gql`
          query tipsList($page: Int, $size: Int,$search:String){
            tipsList(page: $page, size: $size,search:$search){
              totalItems
              data{
                id
                caption
                message
                learn{
                  caption
                }
                status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex) ,
            size: parseInt(pagesize),
            search: search 
          },
      fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getTipQuery.valueChanges.pipe(map(result => result.data.tipsList)).subscribe((result) => {
      if(result.totalItems){
        const ELEMENT_DATA:any = result.data.map((tip: Tips,index) => ({
          index:index,
          id: tip.id,
          caption: tip.caption,
          message: tip.message,
          learn : (tip.learn && tip.learn.caption)?tip.learn.caption:'',
          Status: tip.status,
        }));
        this.dataSource = new MatTableDataSource<TipQuery>(ELEMENT_DATA);
        this.tips = result;
      }else{
        this._helper.showToast(4,'No Data Found');
      }
    });
  }

  refresh() {
    this.getTipQuery.refetch();
  }

  editTip(tipId){
    this._helper.goToPage('edit-tip',{state: {tipId}});
  }
  changeStatus(id,status){
    let newStatus = (status == 0) ? 0 : ((parseInt(status) === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation updateTips($id: ID!,$status: Int){
          updateTips(id:$id,status:$status)
        }`,
      variables: {id: parseInt(id),status:newStatus},
    }).subscribe(
      ({ data }: updateTips) => {
        if(data.updateTips[0] == true){
          this.getTipQuery.refetch();
          this._helper.showToast(1,(status == 0)?'Activity Deleted Successfully':(status == 1)?'Activity Disabled Successfully':'Activity Activated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
