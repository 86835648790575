<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
  
    <div class="center">
      <div
        class="header accent"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between center"
      >
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16"> people </mat-icon>
          <span class="logo-text h1"> Members</span>
        </div>
  
        <!-- SEARCH -->
        <app-search-bar placeholder="Search for a Member" (searchEvent)="searchMembers($event)"> </app-search-bar>
        
      </div>
  
      <div class="content-card" style="overflow-y: scroll">
        <h1 class="err-msg" *ngIf="!members" >No Data Found</h1>
  
        <div *ngIf="members">
          <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
            <!-- Name Column -->
            <ng-container matColumnDef="Firstname">
              <mat-header-cell *matHeaderCellDef>Firstname</mat-header-cell>
              <mat-cell  *matCellDef="let u">
                <p class="text-truncate">{{u.firstName}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="lastname">
              <mat-header-cell *matHeaderCellDef>Lastname</mat-header-cell>
              <mat-cell  *matCellDef="let u">
                <p class="text-truncate">{{u.lastName}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="emailId">
              <mat-header-cell *matHeaderCellDef>Email Id</mat-header-cell>
              <mat-cell  *matCellDef="let u">
                <p class="text-truncate">{{u.emailId}} </p>
              </mat-cell>
            </ng-container>
            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
              <mat-cell  *matCellDef="let u">
                <p class="text-truncate">
                  {{u.Status == '1' ? 'Active':'Inactive'}}
                </p>
              </mat-cell>
            </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  
            <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
          </mat-table>
  
          <mat-paginator
            #paginator
            [length]="members.totalItems"
            [pageSize]="pSize"
            [pageSizeOptions]="dataSize"
            [pageIndex]="members.currentPage"
            (page)="changePage($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  