import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AreaQuery} from '../../../models/types';
import { SpinnerService } from '../../../../shared/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
interface addSkill {
  data: {
    addSkill: {
      id: any,
      status: any
    };
  };
}
// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && !control.value.replace(/\s/g, '').length) {
//       return  {removeSpaces: {value: null}};
//     }
//     return;
//   };
// }
@Component({
  selector: 'app-add-skills',
  templateUrl: './add-skills.component.html',
  styleUrls: ['./add-skills.component.scss']
})
export class AddSkillsComponent implements OnInit {
  // areaId: number = +this.activatedRoute.snapshot.paramMap.get('id');
  areaId: any;

  skillForm:FormGroup; 
  filename: string = null;
  imageUrl: any;
  imageData: File;

  @Output() onSkillAdded = new EventEmitter();

  constructor(
    private _helper: HelperService,
    private _apollo: Apollo,
    private storage: AngularFireStorage,
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute
  ) {
    if(!history || !history.state || !history.state.areaId){
      this._helper.goToPage('view-area');
    }
    this.areaId = parseInt(history.state.areaId);
  }

  ngOnInit(): void {
    
    this.skillForm = new FormGroup({
      skillname: new FormControl('', [Validators.required,removeSpacesValidator()]),
      skillIcon: new FormControl('', [Validators.required])
    })
  }
  addSkill(formDirective: FormGroupDirective){
    if(this.skillForm.invalid){
      return;
    }
    let imgnam = Date.now()+'-'+this.imageData.name;
    const fileRef = this.storage.ref(`/skills/${imgnam}`);
    this.spinnerService.show();
    this.storage.upload(`/skills/${imgnam}`, this.imageData).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){
            this._apollo.mutate({
              mutation: gql`
                mutation addSkill($skill: String,$skillIcon:String,$areaId:Int){
                  addSkill(skill: $skill,skillIcon:$skillIcon,areaId:$areaId){
                    id
                    status
                  }
                }`,
                variables: {
                  skill: this.skillForm.value.skillname,
                  skillIcon: url,
                  areaId: this.areaId,
                },
            }).subscribe(
              ({ data }: addSkill) => {
                if(data.addSkill.id){
                  this._helper.showToast(1,'Skill Added Successfully');
                  this.resetForm(formDirective);
                  this.onSkillAdded.emit();
                }else{
                  this._helper.showToast(4,'Something Went Wrong.. Try Again');
                }
              },(err) => {
                this._helper.showToast(4,err.message);
              }
            );
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })
    ).subscribe();
  }
  onFileChanged(event) {
    if(!event.target.files || !event.target.files[0]) {
      this.imageUrl = false;
      return false;
    }

    this.imageData = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imageData);

    reader.onload = (event) => {
      this.imageUrl = reader.result;
      this.filename = this.imageData.name;
    }
  }

  resetForm(formDirective: FormGroupDirective) {
    this.skillForm.reset();
    formDirective.resetForm(); // to remove mat-errors

    this.filename = null;
    this.imageUrl = null;
    this.imageData = null;
  }
}
