import { Injectable } from '@angular/core';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  userRoles : any;
  constructor() {}

  isAuthorized(allowedRoles: any): boolean {
    
    if (allowedRoles == null ) {
      return true;
    }

    this.userRoles = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];

    if (!this.userRoles) {
      return false;
    }

    return this.userRoles.includes(allowedRoles);
  }
}
