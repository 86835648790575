import { Component, EventEmitter, OnInit, Output} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { SpinnerService } from '../../../../shared/spinner.service';
import { BlogQuery } from 'app/_admin/models/types';
import { map } from 'rxjs/operators';
import { removeSpacesValidator } from '../../../../shared/custom-validators';
interface addTips {
  data: {
    addTips: {
      code: any,
      status: any
    };
  };
}
// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && !control.value.replace(/\s/g, '').length) {
//       return  {removeSpaces: {value: null}};
//     }
//     return;
//   };
// }
@Component({
  selector: 'app-addtip',
  templateUrl: './addtip.component.html',
  styleUrls: ['./addtip.component.scss']
})
export class AddtipComponent implements OnInit {
  tipForm:FormGroup; 
  @Output() onTipAdded = new EventEmitter();
  blogs: unknown;
  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,private spinnerService: SpinnerService) {
    this.getLearn()
   }

  ngOnInit(): void {
    this.tipForm = new FormGroup({
      caption: new FormControl('', [Validators.required,removeSpacesValidator()]),
      message: new FormControl('', [Validators.required,removeSpacesValidator()]),
      learn: new FormControl('')
    })
  }
  addtips(formDirective: FormGroupDirective){
    if(this.tipForm.invalid){
      return;
    }
    
    this._apollo.mutate({
      mutation: gql`
        mutation addTips($caption: String,$message: String,$learnId: Int){
          addTips(caption: $caption,learnId:$learnId,message:$message){
            code
            status
          }
        }`,
      variables: { caption:this.tipForm.value.caption, message:this.tipForm.value.message,learnId:(this.tipForm.value.learn)?parseInt(this.tipForm.value.learn):0},
    }).subscribe(
      ({ data }: addTips) => {
        if(data.addTips.status){
          this._helper.showToast(1,'Tips Added Successfully');
          this.resetForm(formDirective);
          this.onTipAdded.emit();
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
          
  }
  getLearn(){
    this._apollo.watchQuery<BlogQuery>({
      query: gql`
          query learnActiveList($search: String){
            learnActiveList(search: $search){
              id
              caption
            }
          }`,
          variables: {
            search: ''
          },
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.learnActiveList)).subscribe((result) => {
      if (result) {
        this.blogs = result;
      } else {
        this._helper.showToast(4,'No Data Found');
      }
    });
  }
  resetForm(formDirective: FormGroupDirective) {
    this.tipForm.reset();
    formDirective.resetForm(); // to remove mat-errors

  }

}
