import { Component, OnInit} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { SpinnerService } from '../../../../shared/spinner.service';
import { BlogQuery, TipQuery,updateTips } from '../../../models/types';
import { map } from 'rxjs/operators';
import { getLocalStorageRoleItem, ROLES } from 'app/helpers/utils';
import { removeSpacesValidator } from '../../../../shared/custom-validators';

// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && !control.value.replace(/\s/g, '').length) {
//       return  {removeSpaces: {value: null}};
//     }
//     return;
//   };
// }
@Component({
  selector: 'app-edittip',
  templateUrl: './edittip.component.html',
  styleUrls: ['./edittip.component.scss']
})
export class EdittipComponent implements OnInit {
  tipForm: FormGroup;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  tipId: any;
  tipData: any;
  blogs: any;
  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,private spinnerService: SpinnerService) { 
    if(!history || !history.state || !history.state.tipId){
      this._helper.goToPage('view-tips');
    }
    this.tipId = history.state.tipId;
    this.getLearn();
    this.setForm();
    if(this.tipId){
      this.getTipDataById();
    }
  }

  ngOnInit(): void {

  }
  setForm(){
    this.tipForm = new FormGroup({
      caption: new FormControl((this.tipData && this.tipData.caption)?this.tipData.caption:'', [Validators.required,removeSpacesValidator()]),
      message: new FormControl((this.tipData && this.tipData.caption)?this.tipData.message:'', [Validators.required,removeSpacesValidator()]),
      learn: new FormControl((this.blogs && this.tipData && this.tipData.learnId)?(this.blogs.find(c => parseInt(c.id) == this.tipData.learnId)):''),
      status: new FormControl((this.tipData)?((this.tipData.status === 0) ? 0 :this.tipData.status): '', [Validators.required])
    })
  }
  getTipDataById(){
    this._apollo.watchQuery<TipQuery>({
      query: gql`
        query tipsOne($id: ID!){
          tipsOne(id: $id){
            id
            caption
            learnId
            message
            createdAt
            status
          }
        }`,
        variables: { id: this.tipId  },
        fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.tipsOne)).subscribe((result) => {
      this.tipData = result;
      this.setForm();
    })
  }

  edittips(){
    if(this.tipForm.invalid){
      return;
    }
    
    let varArr = {};
    varArr= { id:this.tipId, message:this.tipForm.value.message,caption:this.tipForm.value.caption,status:this.tipForm.value.status};
    if(this.tipForm.value.learn && this.tipForm.value.learn.id ){
      varArr= { id:this.tipId, message:this.tipForm.value.message,caption:this.tipForm.value.caption,
        status:this.tipForm.value.status,learnId:parseInt(this.tipForm.value.learn.id)};
    }
    this._apollo.mutate({
      mutation: gql`
        mutation updateTips($id: ID!,$learnId: Int,$message: String,$caption: String,$status: Int){
          updateTips(id: $id,learnId: $learnId,caption: $caption,status: $status,message:$message)
        }`,
      variables: varArr,
    }).subscribe(
      ({ data }: updateTips) => {
        if(data.updateTips[0] == true){
          this._helper.showToast(1,'Tips Updated Successfully');
          this._helper.goToPage('view-tips');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );  
  }
  getLearn(){
    this._apollo.watchQuery<BlogQuery>({
      query: gql`
          query learnActiveList($search: String){
            learnActiveList(search: $search){
              id
              caption
            }
          }`,
          variables: {search: ''},
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.learnActiveList)).subscribe((result) => {
      if (result) {
        this.blogs = result;
      } else {
        this._helper.showToast(4,'No Data Found');
      }
    });
  }
  cancel(){
    this._helper.goToPage('view-tips')
  }
}
