
<mat-dialog-content class="mat-typography">
    <div id="profile" class="page-layout simple tabbed" *ngIf="childData">
        <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" >
            <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"  fxLayoutAlign.gt-sm="start center">
                <div class="name">Child - {{childData.firstname}} {{childData.lastname}}</div>
            </div>
        </div>
        
        <div class="about-content">
            <mat-card >
                <mat-card-content>
                    <div id="about" class="p-50" fxLayout="row wrap">
                        <div class="about-content" fxLayout="column" fxFlex="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
                            <div class="profile-box info-box general" fxLayout="column">
        
                                
                                <div class="content">
                                    
                                    <div class="info-line">
                                        <div class="title">FirstName</div>
                                        <div class="info">{{childData.firstname}}</div>
                                    </div>
                                    
                                    <div class="info-line">
                                        <div class="title">Last Name</div>
                                        <div class="info">{{childData.lastname}}</div>
                                    </div>
                                    
                                    <div class="info-line">
                                        <div class="title">Date Of Birth</div>
                                        <div class="info">{{childData.dob}}</div>
                                    </div>
                                    <div class="info-line">
                                        <div class="title">Gender</div>
                                        <div class="info">{{childData.gender}}</div>
                                    </div>
                                    <div class="info-line">
                                        <div class="title">Height</div>
                                        <div class="info">{{childData.height}}</div>
                                    </div>
    
                                    <div class="info-line">
                                        <div class="title">Weight</div>
                                        <div class="info">{{childData.weight}}</div>
                                    </div>
                                    <div class="info-line">
                                        <div class="title">Is Premature</div>
                                        <div class="info">{{childData.isPremature == '1' ? 'Yes' : 'No'}}</div>
                                    </div>
                                    <div class="info-line">
                                        <div class="title">Status</div>
                                        <div class="info">{{childData.status == '1' ? 'Active' : 'InActive'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="about-sidebar" fxLayout="column" fxFlex="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
                            <div class="profile-box info-box general" fxLayout="column">
                                <div class="content">
                                    <div class="info-line">
                                        <div class="title">Profile Picture</div>
                                        <div class="info"></div>
                                    </div>
                                    <div>
                                        <img *ngIf="childData.profilePic" style="max-height: 350px;"  [src]="childData.profilePic" onerror="'assets/images/no_image.png'" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</mat-dialog-content><br/>
<mat-dialog-actions>
<button style="margin:0 auto;" mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>