<div id="agents" class="page-layout carded fullwidth">
  <div class="top-bg accent"></div>

  <div class="center">
    <div
      class="header accent"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center"
    >
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16"> toys </mat-icon>
        <span class="logo-text h1"> Activities List</span>
      </div>

      <!-- SEARCH -->
      <app-search-bar placeholder="Search for an Activity, Area or Skill" style="width: 380px;" (searchEvent)="searchActivity($event)"> </app-search-bar>
      
      <button mat-raised-button *ngIf="this.userRoles && this.userRoles.includes(1)" [routerLink]="'/admin/add-activity'"><mat-icon>add</mat-icon> Add Activity</button>
    </div>

    <div class="content-card" style="overflow-y: scroll">
      <h1 class="err-msg" *ngIf="!activities" (click)="getRoles()">No Data Found</h1>

      <div *ngIf="activities">
        <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
          <!-- Name Column -->
          <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate" matTooltip="{{u.activityName}}"  [matTooltipPosition]="'above'">{{u.activityName}} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Area">
            <mat-header-cell *matHeaderCellDef>Area</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate" matTooltip="{{u.areaName}}"  [matTooltipPosition]="'above'">{{u.areaName}} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Skill">
            <mat-header-cell *matHeaderCellDef>Skill</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate" matTooltip="{{u.skillName}}"  [matTooltipPosition]="'above'">{{u.skillName}} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="IndicatorSkill">
            <mat-header-cell *matHeaderCellDef>Indicator Skill</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate" matTooltip="{{u.indSkill}}"  [matTooltipPosition]="'above'">{{u.indSkill}} </p>
            </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="Status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
              <p class="text-truncate" >
                {{u.Status == '1' ? 'Active':'Inactive'}}
              </p>
            </mat-cell>
          </ng-container>

          <!-- Controls Column -->
          <ng-container matColumnDef="menu">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let u">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{ id: u.id, status: u.Status}"
                aria-label="Menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-id="id" let-status="status">
              <button mat-menu-item  (click)="editActivity(id)" *ngIf="this.userRoles && this.userRoles.includes(3) ">
                <mat-icon>edit</mat-icon>
                <span>Edit Activity</span>
              </button>
              <button mat-menu-item (click)="changeStatus(id,status)" *ngIf="this.userRoles && this.userRoles.includes(41) ">
                <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                <span>{{status == '1'? 'Inactive' : 'Active'}}</span>
              </button>
              <button mat-menu-item (click)="changeStatus(id,0)" *ngIf="this.userRoles && this.userRoles.includes(42) ">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </ng-template>
          </mat-menu>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

          <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
        </mat-table>

        <mat-paginator
          #paginator
          [length]="activities.totalItems"
          [pageSize]="pSize"
          [pageSizeOptions]="dataSize"
          [pageIndex]="activities.currentPage"
          (page)="changePage($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
