import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { mileStoneQuery, mileStoneActivityList } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
interface updateMilestone {
  data: {
    updateMilestone:{
      true: any
    }
  };
}
@Component({
  selector: 'app-view-assigned-activities',
  templateUrl: './view-assigned-activities.component.html',
  styleUrls: ['./view-assigned-activities.component.scss']
})
export class ViewAssignedActivitiesComponent implements OnInit {
  @Input('milestoneIDInp') milestoneIDInp:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;
  currentPage: number;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['Name','Area', 'Skill','IndicatorSkill','Status'];
  skills:any;
  milestoneId:any;
  milestones:any;
  selectedId:any[] = new Array();
  getActivityQuery:QueryRef<any>;
  private querySubscription: Subscription;
  constructor(private _helper: HelperService,private _apollo: Apollo) { 
    if(this.userRoles && (this.userRoles.includes(33) || this.userRoles.includes(34))){
      this.displayedColumns.push('menu')
    }
    // if(!history || !history.state || !history.state.milestoneId){
    //   this._helper.goToPage('milestones');
    // }
    this.milestoneId = history.state.milestoneId;
    if(this.milestoneId ){
      this.getActivitiesBasedOnMileStone();
    }
  }

  ngOnInit(): void {
    this.milestoneId = this.milestoneIDInp;
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;
    this.getActivitiesBasedOnMileStone();
  }

  getActivitiesBasedOnMileStone() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : 0}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getActivityQuery = this._apollo.watchQuery<any>({
      query: gql`
          query activityList($page: Int, $size: Int, $search: String, $multiMilestone: String){
            activityList(page: $page, size: $size, search: $search, multiMilestone:$multiMilestone){
              totalItems
              data{
                id
                activityName
                multiMilestones
                area {
                  id
                  areaName
                }
                skills {
                  id
                  skill
                }
                indicatorSkill {
                  id
                  indicatorSkill
                }
                indSkill
                status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex) ,
            size: parseInt(pagesize),
            search: search,
            multiMilestone:this.milestoneId
          },
      fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getActivityQuery.valueChanges.pipe(map(result => result.data.activityList)).subscribe((result) => {
      if(result.totalItems !== 0){
        const ELEMENT_DATA:any = result.data.map((mile: any,index) => ({
          index:index,
          id:mile.id,
          activityId: mile.id,
          milestone: mile.multiMilestones,
          activityName:mile.activityName,
          SkillName: mile.skills.skill,
          AreaName:mile.area.areaName,
          indicatorSkill:mile.indSkill,
          ActivityStatus: mile.status,
          Status: mile.status
        }));
        this.dataSource = new MatTableDataSource<mileStoneQuery>(ELEMENT_DATA);
        this.milestones = result;
        ELEMENT_DATA.forEach(ele => {
          this.selectedId.push(ele.activityId);
        });
      }else{
        this.milestones = '';
      }
    });
  }

  assignActivities(){    
    this._helper.goToPage('assign-activity',{state: {milestoneId:this.milestoneId,selectedId:this.selectedId}});
  }

  changeStatus(id,status,activityId){
    let newStatus = (status == 0) ? 0 : ((parseInt(status) === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation updateMilestone($id: ID!, $milestone: String,$activityId: Int,$status: Int){
          updateMilestone(id:$id, milestone: $milestone,activityId: $activityId,status:$status)
        }`,
      variables: {id: parseInt(id),milestone:this.milestoneId,activityId:parseInt(activityId),status:newStatus},
    }).subscribe(
      ({ data }: updateMilestone) => {
        if(data.updateMilestone[0] == true){
          this._helper.showToast(1,(newStatus == 2)?'Activity Disabled Successfully':((newStatus == 1)?'Activity Enabled Successfully':'Activity Deleted Successfully'));
          this.selectedId = new Array();
          this.getActivityQuery.refetch();
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
