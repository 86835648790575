<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
  
    <div class="center">
      <div
        class="header accent"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between center"
      >
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16"> forum </mat-icon>
          <span class="logo-text h1"> Post List</span>
        </div>
  
        <app-search-bar placeholder="Search for a Post" (searchEvent)="searchPost($event)"> </app-search-bar>
        
        <div class="btn-group">
          <button mat-raised-button *ngIf="this.userRoles && this.userRoles.includes(62)" [routerLink]="'/admin/add-post'"><mat-icon>add</mat-icon> Add Post</button>
        </div>
      </div>
  
      <div class="content-card" style="overflow-y: scroll">
        <h1 class="err-msg" *ngIf="!posts" >No Data Found</h1>
  
        <div *ngIf="posts">
          <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
            
            <ng-container matColumnDef="Caption">
              <mat-header-cell *matHeaderCellDef>Caption</mat-header-cell>
              <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
                <p class="text-truncate">{{u.caption}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Description">
              <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
              <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
                <p class="text-truncate">{{u.description}} </p>
              </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
              <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
                <p class="text-truncate">
                  {{u.status === 1 ? 'Published':''}}
                  {{u.status === 2 ? 'Not Published':''}}
                  {{u.status === 0 ? 'Inactive':''}}
                </p>
              </mat-cell>
            </ng-container>
  
            
            <ng-container matColumnDef="menu">
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{ id: u.id, status: u.status}"
                  aria-label="Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
  
            <mat-menu #menu="matMenu">
              <ng-template matMenuContent let-id="id" let-status="status">
                <button mat-menu-item  (click)="editPost(id)" *ngIf="this.userRoles && this.userRoles.includes(64)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit Learn</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,status)" *ngIf="this.userRoles && this.userRoles.includes(65)">
                  <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                  <span>{{status == '1'? 'Unpublish' : 'Publish'}}</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,0)" *ngIf="this.userRoles && this.userRoles.includes(66)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </ng-template>
            </mat-menu>
  
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  
            <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
          </mat-table>
  
          <mat-paginator
            #paginator
            [length]="posts.totalItems"
            [pageSize]="pSize"
            [pageSizeOptions]="dataSize"
            [pageIndex]="posts.currentPage"
            (page)="changePage($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  