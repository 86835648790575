import { Component, OnInit , Input} from '@angular/core';
import { familyMembers } from '../../../models/types';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { SpinnerService } from '../../../../shared/spinner.service';
import { AngularFireAuth }  from '@angular/fire/auth';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
@Component({
  selector: 'app-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent implements OnInit {
  @Input() members: familyMembers[];
  familyDetails:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;

  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;

  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['FirstName', 'LastName','Email','Status'];
  constructor(private _helper: HelperService,private spinnerService: SpinnerService,
    private _apollo: Apollo, private _model: MatDialog,private authFire: AngularFireAuth) { 
      if(this.userRoles && this.userRoles.includes(16)){
        this.displayedColumns.push('menu')
      }
    }

  ngOnInit(): void {
    if(this.members){
      const ELEMENT_DATA:any = this.members.map((memb: familyMembers) => ({
        id: memb.careGiver.id,
        firstName: memb.careGiver.firstname,
        lastName: memb.careGiver.lastname,
        email: memb.careGiver.emailId,
        Status: memb.careGiver.status,
      }));
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    }
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex
  }
  passwordReset(email){
    if(email){
      this.spinnerService.show();
      this.authFire.sendPasswordResetEmail(email).then((res) => {
        this.spinnerService.hide();
        this._helper.showToast(1,'Password Reset Mail Send Successfully');
      },(err) => {
        this._helper.showToast(4 , err.message);
      })
    }else{
      this._helper.showToast(4,'Email ID is Required For Reset Password');
    }
  }
}
