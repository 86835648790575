<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    <div class="center">
        <div
      class="header accent"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center"
    >
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>track_changes</mat-icon>
        <span class="logo-text h1"> Edit Milestone</span>
      </div>
</div>
    <mat-card>
        <mat-card-content>
          <form [formGroup]="milestoneForm"  #formDirective="ngForm">
            <!-- <div class="form-fields"> -->
                <mat-form-field appearance="outline" class="w-80-p" floatLabel="always">
                    <mat-label>Select Month</mat-label>
                    <mat-select placeholder="Select Milestone" formControlName="month">
                      <mat-option *ngFor="let months of months" [value]="months.value">
                        {{months.viewValue}}   
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
    
              <mat-form-field appearance="outline" class="w-80-p" floatLabel="always" >
                <mat-label>Milestone</mat-label>
                <input matInput formControlName="milestoneValue" placeholder="Enter milestone..." />
              </mat-form-field>
      
              <mat-form-field class="w-80-p" floatPlaceholder="never" >
                <mat-label>Icon</mat-label>
                <input [hidden]="true" (change)="onFileChanged($event)" type="file" onclick="this.value=null;" accept="all/*" #fileSelect />
                <input readonly matInput style="cursor: pointer" formControlName="milestoneIcon" (click)="fileSelect.click()"
                  [(ngModel)]="filename" placeholder="Select file..." />
                <div style="float: right; cursor: pointer;margin-top: -25px;" (click)="fileSelect.click();">
                  <mat-icon>attach_file</mat-icon>
                </div>
              </mat-form-field>
              <img *ngIf="imageUrl" class="uploadTempnail" id="image_id" [src]="imageUrl" onerror="'assets/images/no_image.png'" />
      <br/><br/>
            <button mat-raised-button color="primary" style="margin-right: 5px" [disabled]="areFormValuesUnchanged() || milestoneForm.invalid" (click)="editMilestone()">update</button>
            <button type="button" (click)="cancel()" mat-stroked-button style="margin-right: 5px">Cancel</button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
</div>

  