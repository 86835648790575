import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { map } from 'rxjs/operators';
import { Apollo, gql } from "apollo-angular";
import { User,RoleQuery } from '../../../models/types';
import { AngularFireAuth }  from '@angular/fire/auth';
import { removeSpacesValidator } from '../../../../shared/custom-validators';

interface adminUserSignUp{
  data:{
    adminUserSignUp:{
      token:string,
      admin:User
    }
  }
}
// export function removeSpaces(control: AbstractControl) {
//   if (control && control.value && !control.value.replace(/\s/g, '').length) {
//     control.setValue('');
//   }
//   return null;
// }
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  loader:boolean;
  addUserForm:FormGroup;
  roles:any;

  constructor(private _helper: HelperService,private _apollo: Apollo,private authFire: AngularFireAuth) {
    this.loader = false;
    this.getRoles();
   }

  ngOnInit(): void {
    this.addUserForm = new FormGroup({
      firstname: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z ]*$"),removeSpacesValidator()]),
      lastname: new FormControl('', [Validators.required,Validators.pattern("^[a-zA-Z ]*$"),removeSpacesValidator()]),
      username: new FormControl('test', [Validators.required,removeSpacesValidator()]),
      emailid: new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"),removeSpacesValidator()]),
      password: new FormControl('', [Validators.required,Validators.minLength(8),removeSpacesValidator()]),
      usertype: new FormControl('', [Validators.required])
    })
  }
  addUser() {
    this.loader = true;
    if(this.addUserForm.invalid){
      return;
    }
    this.authFire.createUserWithEmailAndPassword(this.addUserForm.value.emailid, this.addUserForm.value.password).then((userCredential) => {
      if(userCredential && userCredential.user && userCredential.user.uid){
        this.authFire.sendPasswordResetEmail(this.addUserForm.value.emailid).then((res) => {
          this._apollo.mutate({
            mutation: gql`
                mutation adminUserSignUp($username: String,$firstname: String,$lastname: String,$email:String!,$usertype: Int!,$password:String,$uuid:String!){
                  adminUserSignUp(username: $username,firstname: $firstname,lastname: $lastname,email: $email,userType: $usertype,password:$password,uuid:$uuid){
                    token
                  }
                }`,
                variables: { username: this.addUserForm.value.username,lastname:this.addUserForm.value.lastname,
                              firstname:this.addUserForm.value.firstname, email:this.addUserForm.value.emailid,
                              usertype:parseInt(this.addUserForm.value.usertype),password:this.addUserForm.value.password,
                              uuid:userCredential.user.uid},
          }).subscribe(
            ({ data }: adminUserSignUp) => {
              if(data.adminUserSignUp.token){
                this._helper.showToast(1,'User Added Successfully');
                this._helper.goToPage('/admin/users')
              } else{
                this._helper.showToast(4,'Something Went Wrong .. Try Again');
              }
            },(err) => {
              this._helper.showToast(4,err.message);
            });
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      }else{
          this.loader = false;
          this._helper.showToast(4,'Invalid EmailId or Password');
      }
    },(err)=>{
      this._helper.showToast(4,'The Email Address is already in use by another account');
    })
    this.loader = false;
  }
  
  getRoles() {
    this.loader = true;
    this._apollo.watchQuery<RoleQuery>({
      query: gql`
          query {
            RoleList{
             id
             userType
             status
            }
          }`,
      fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.RoleList)).subscribe((result) => {
      if(result){
        this.roles = result;
      }else{
        this._helper.showToast(4,'No Roles Found');
      }
    });

  }

  cancel(){
    this._helper.goToPage('/admin/users')
  }
}
