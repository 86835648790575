<div class="profile-box info-box general" fxLayout="column">
    <mat-card class="case-document">
        <div *ngIf="child?.length; then CHILD; else NO_CHILD"></div>
        
        <ng-template #NO_CHILD>
            <span>No Members Found</span>
        </ng-template>
        
        <ng-template #CHILD>
            <div class="content-card" style="overflow-y: scroll">
                <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
                    <ng-container matColumnDef="FirstName">
                        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                        <mat-cell  *matCellDef="let u">
                            <p class="text-truncate">{{u.firstName}} {{u.lastName}}</p>
                        </mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="Gender">
                        <mat-header-cell *matHeaderCellDef>Gender</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                            <p class="text-truncate">{{u.gender}} </p>
                        </mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="Status">
                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                            <p class="text-truncate">
                                {{u.Status == '1' ? 'Active' : 'Inactive'}}
                            </p>
                        </mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="menu">
                        <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                        <mat-cell *matCellDef="let u">
                            <button #menuTrigger mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ id: u.id, index: u.index}" aria-label="Menu" >
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    
                    <mat-menu #menu="matMenu">
                        <ng-template matMenuContent let-id="id" let-index="index">
                            <button mat-menu-item (click)="viewDetails(index)">
                                <mat-icon>visibility</mat-icon>
                                <span>View Details</span>
                            </button>
                        </ng-template>
                    </mat-menu>
                    
                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    
                    <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
                </mat-table>
                
                <mat-paginator #paginator [length]="child.length" [pageSize]="pSize"  [pageSizeOptions]="dataSize" [pageIndex]="0" (click)="changePage($event)"></mat-paginator>
            </div>
        </ng-template>
    </mat-card>
</div>
