import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { MatSort } from '@angular/material/sort';
import { activityCount, MileQuery } from '../../../models/types';
import { map } from 'rxjs/operators';
export interface Element {
  id: number;
  name: string;
  value: string;
  count: number;
}

@Component({
  selector: 'app-view-milestones',
  templateUrl: './view-milestones.component.html',
  styleUrls: ['./view-milestones.component.scss']
})
export class ViewMilestonesComponent implements OnInit {
  pSize: number = 10;
  dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);

  dataSize: number[] = [10, 50, 100];
  displayedColumns: string[] = ['MileStone','Count', 'ViewDetails'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchKeyword: string;
  constructor(private _helper: HelperService,private _apollo: Apollo) {
    this.getMileCount();
  }

  ngOnInit(): void {
  } 
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    
    this.dataSource.sort = this.sort;
  }

  viewActivity(milestoneId){
    this._helper.goToPage('milestone-indicators',{state: {milestoneId}});
  }

  searchMilestone(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getMileCount(){
    this._apollo.watchQuery<MileQuery>({
      query: gql`
          query activityCountPerMilestone{
            activityCountPerMilestone{
              milestone
              activityCount
            }
          }`,
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.activityCountPerMilestone)).subscribe((result) => {
      this.dataSource.filteredData.forEach((data,i)=>{
        data.count = result[i].activityCount;
      });
    });
  }

  
 
}



const ELEMENT_DATA: Element[] = [
  {id:0,name:"Month 00", value:"M00",count: 0},
  {id:1,name:"Month 01", value:"M01",count: 0},
  {id:2,name:"Month 02", value:"M02",count: 0},
  {id:3,name:"Month 03", value:"M03",count: 0},
  {id:4,name:"Month 04", value:"M04",count: 0},
  {id:5,name:"Month 05", value:"M05",count: 0},
  {id:6,name:"Month 06", value:"M06",count: 0},
  {id:7,name:"Month 07", value:"M07",count: 0},
  {id:8,name:"Month 08", value:"M08",count: 0},
  {id:9,name:"Month 09", value:"M09",count: 0},
  {id:10,name:"Month 10", value:"M10",count: 0},
  {id:11,name:"Month 11", value:"M11",count: 0},
  {id:12,name:"Month 12", value:"M12",count: 0},
  {id:13,name:"Month 13", value:"M13",count: 0},
  {id:14,name:"Month 14", value:"M14",count: 0},
  {id:15,name:"Month 15", value:"M15",count: 0},
  {id:16,name:"Month 16", value:"M16",count: 0},
  {id:17,name:"Month 17", value:"M17",count: 0},
  {id:18,name:"Month 18", value:"M18",count: 0},
  {id:19,name:"Month 19", value:"M19",count: 0},
  {id:20,name:"Month 20", value:"M20",count: 0},
  {id:21,name:"Month 21", value:"M21",count: 0},
  {id:22,name:"Month 22", value:"M22",count: 0},
  {id:23,name:"Month 23", value:"M23",count: 0},
  {id:24,name:"Month 24", value:"M24",count: 0},
  {id:25,name:"Month 25", value:"M25",count: 0},
  {id:26,name:"Month 26", value:"M26",count: 0},
  {id:27,name:"Month 27", value:"M27",count: 0},
  {id:28,name:"Month 28", value:"M28",count: 0},
  {id:29,name:"Month 29", value:"M29",count: 0},
  {id:30,name:"Month 30", value:"M30",count: 0},
  {id:31,name:"Month 31", value:"M31",count: 0},
  {id:32,name:"Month 32", value:"M32",count: 0},
  {id:33,name:"Month 33", value:"M33",count: 0},
  {id:34,name:"Month 34", value:"M34",count: 0},
  {id:35,name:"Month 35", value:"M35",count: 0},
  {id:36,name:"Month 36", value:"M36",count: 0},
  {id:37,name:"Month 37", value:"M37",count: 0},
  {id:38,name:"Month 38", value:"M38",count: 0},
  {id:39,name:"Month 39", value:"M39",count: 0},
  {id:40,name:"Month 40", value:"M40",count: 0},
  {id:41,name:"Month 41", value:"M41",count: 0},
  {id:42,name:"Month 42", value:"M42",count: 0},
  {id:43,name:"Month 43", value:"M43",count: 0},
  {id:44,name:"Month 44", value:"M44",count: 0},
  {id:45,name:"Month 45", value:"M45",count: 0},
  {id:46,name:"Month 46", value:"M46",count: 0},
  {id:47,name:"Month 47", value:"M47",count: 0},
  {id:48,name:"Month 48", value:"M48",count: 0},
  {id:49,name:"Month 49", value:"M49",count: 0},
  {id:50,name:"Month 50", value:"M50",count: 0},
  {id:51,name:"Month 51", value:"M51",count: 0},
  {id:52,name:"Month 52", value:"M52",count: 0},
  {id:53,name:"Month 53", value:"M53",count: 0},
  {id:54,name:"Month 54", value:"M54",count: 0},
  {id:55,name:"Month 55", value:"M55",count: 0},
  {id:56,name:"Month 56", value:"M56",count: 0},
  {id:57,name:"Month 57", value:"M57",count: 0},
  {id:58,name:"Month 58", value:"M58",count: 0},
  {id:59,name:"Month 59", value:"M59",count: 0},
  {id:60,name:"Month 60", value:"M60",count: 0},
  {id:61,name:"Month 61", value:"M61",count: 0},
  {id:62,name:"Month 62", value:"M62",count: 0},
  {id:63,name:"Month 63", value:"M63",count: 0},
  {id:64,name:"Month 64", value:"M64",count: 0},
  {id:65,name:"Month 65", value:"M65",count: 0},
  {id:66,name:"Month 66", value:"M66",count: 0},
  {id:67,name:"Month 67", value:"M67",count: 0},
  {id:68,name:"Month 68", value:"M68",count: 0},
  {id:69,name:"Month 69", value:"M69",count: 0},
  {id:70,name:"Month 70", value:"M70",count: 0},
  {id:71,name:"Month 71", value:"M71",count: 0},
  {id:72,name:"Month 72", value:"M72",count: 0}
];

