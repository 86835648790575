
    <div id="agents" class="page-layout carded fullwidth">
        <div class="top-bg accent"></div>
        
        <div class="center">
            <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
                <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="logo-icon s-32 mr-16"> flare </mat-icon>
                    <span class="logo-text h1"> Edit Tip</span>
                </div>
            </div>
            
            <div class="content-card" >
                <div >
                    <mat-card>
                        <mat-card-content>
                            <form [formGroup]="tipForm">
                                <mat-form-field appearance="outline" class="w-70-p" floatLabel="always">
                                    <mat-label>Caption</mat-label>
                                    <input matInput formControlName="caption" type="text" placeholder="Caption" />
                                    <mat-error *ngIf="tipForm.controls['caption'].hasError('required')">Caption is Required </mat-error>
                                    <mat-error *ngIf="tipForm.controls['caption'].hasError('removeSpaces')">Invalid Caption</mat-error>

                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-70-p" floatLabel="always">
                                    <mat-label>Message</mat-label>
                                    <input matInput formControlName="message" type="text" placeholder="Message" />
                                    <mat-error *ngIf="tipForm.controls['message'].hasError('required')">Message is Required </mat-error>
                                    <mat-error *ngIf="tipForm.controls['message'].hasError('removeSpaces')">Message Caption</mat-error>

                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                                    <mat-label>Learn</mat-label>
                                    <mat-select formControlName="learn" placeholder="Choose Learn">
                                        <mat-option selected [value]="-1" disabled>Choose Learn</mat-option>
                                        <mat-option *ngFor="let blog of blogs" [value]="blog">
                                            {{blog.caption}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-radio-group aria-label="Select an option" formControlName="status" *ngIf="this.userRoles && (this.userRoles.includes(51) || this.userRoles.includes(52))">
                                    <mat-radio-button *ngIf="this.userRoles.includes(52)" [value]="1">Active</mat-radio-button>
                                    <mat-radio-button *ngIf="this.userRoles.includes(52)" [value]="2">Inactive</mat-radio-button>
                                    <mat-radio-button *ngIf="this.userRoles.includes(51)" [value]="0">Delete</mat-radio-button>
                                </mat-radio-group>
                                  <mat-error *ngIf="tipForm.controls['status'].hasError('required')">Status is Required </mat-error>
                                
                  <br/>
                  <br/>
                                <button mat-raised-button color="primary" style="margin-right: 5px"  [disabled]="tipForm.invalid"  (click)="edittips()"> Save </button>
                                &nbsp;&nbsp;
                                <button mat-stroked-button  (click)="cancel()" >  <span>Cancel</span> </button>
                            </form>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    
    