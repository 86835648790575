import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Skill,SkillQuery,updateSkill } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
@Component({
  selector: 'app-view-skills',
  templateUrl: './view-skills.component.html',
  styleUrls: ['./view-skills.component.scss']
})
export class ViewSkillsComponent implements OnInit {
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;
  areaId:any;
  areaName:any;
  getSkillQuery:QueryRef<any>;
  private querySubscription: Subscription;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['SkillName','Icon','Status'];
  skills:any;

  constructor(private _helper: HelperService,private _apollo: Apollo) {
    if(!history || !history.state || !history.state.areaId || !history.state.areaname){
      this._helper.goToPage('view-area');
    }
    if(this.userRoles && (this.userRoles.includes(37) || this.userRoles.includes(53) || this.userRoles.includes(54))){
      this.displayedColumns.push('menu')
    }
    this.areaId = parseInt(history.state.areaId);
    this.areaName = history.state.areaname;
    this.getSkills();
   }

  ngOnInit(): void {
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;
    this.getSkills();
  }
  searchSkill(keyword: string){
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getSkills();
  }
  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  getSkills() {    
    const pageindex = `${this.pIndex ? `${this.pIndex}` : 0}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getSkillQuery = this._apollo.watchQuery<SkillQuery>({
      query: gql`
          query areaIdBasedskill($page: Int, $size: Int, $areaId: Int,$search:String){
            areaIdBasedskill(page: $page, size: $size, areaId: $areaId,search:$search){
              totalItems
              data{
                id
                skill
                skillIcon
                status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex) ,
            size: parseInt(pagesize),
            areaId: this.areaId ,
            search: search 
          },
      fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getSkillQuery.valueChanges.pipe(map(result => result.data.areaIdBasedskill)).subscribe((result) => {
      if(result.totalItems){
        const ELEMENT_DATA:any = result.data.map((skill: Skill,index) => ({
          index:index,
          id: skill.id,
          SkillName: skill.skill,
          Icon:skill.skillIcon,
          Status: skill.status,
        }));
        this.dataSource = new MatTableDataSource<SkillQuery>(ELEMENT_DATA);
        this.skills = result;
      }else{
        this._helper.showToast(4,'No Data Found');
      }
    });
  }

  refresh() {
    this.getSkillQuery.refetch();
  }

  editSkill(skillId){
    this._helper.goToPage('edit-skill',{state: {skillId}});
  }

  viewIndicator(skillId,skillname){
    this._helper.goToPage('view-indicator',{state: {skillId:skillId,name:skillname,areaId:this.areaId,areaName:this.areaName}});
  }
  changeStatus(id,status){
    let newStatus = (status == 0) ? 0 : ((parseInt(status) === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation updateSkill($id: ID!,$status: Int){
          updateSkill(id:$id,status:$status)
        }`,
      variables: {id: parseInt(id),status:newStatus},
    }).subscribe(
      ({ data }: updateSkill) => {
        if(data.updateSkill[0] == true){
          this.getSkillQuery.refetch();
          this._helper.showToast(1,(status == 0)?'Activity Deleted Successfully':(status == 1)?'Activity Disabled Successfully':'Activity Activated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
