import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-child-list-details',
  templateUrl: './child-list-details.component.html',
  styleUrls: ['./child-list-details.component.scss']
})
export class ChildListDetailsComponent implements OnInit {
  @Input() childData: any;
  constructor() { }
  
  ngOnInit(): void {}
}
