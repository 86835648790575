import { Route } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotpswComponent } from './forgotpsw/forgotpsw.component';
import { DenyAuthGuard } from './services/auth.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin',
  },
  {
    path: 'login',
    canActivate: [DenyAuthGuard],
    component: LoginComponent,
  },
  {
    path: 'forgotpassword',
    canActivate: [DenyAuthGuard],
    component: ForgotpswComponent,
  },

  {
    path: 'admin',
    loadChildren: () => import('./_admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
