import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

// import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { getLocalStorageItem, getToken ,delLocalStorageItem,AUTH_TOKEN} from './helpers/utils';
import { Router } from '@angular/router';
import { HelperService } from './services/helper.service';

import { SpinnerService } from './shared/spinner.service';
@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  
   isLoading: Subject<boolean>;
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService, // Don't Remove this, it will result in infinite loader overlay
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _router: Router,
    private _helper: HelperService,
    private spinnerService: SpinnerService
  ) {
    this.isLoading = this.spinnerService.visibility;

    this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

    this._translateService.use('en');

    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    
    this.initializePermissions();

    this._helper.getMenu().subscribe((data: any[]) => {
      this.setSideMenu(data);
    });
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.fuseConfig = config;
      if (this.fuseConfig.layout.width === 'boxed') {
        this.document.body.classList.add('boxed');
      } else {
        this.document.body.classList.remove('boxed');
      }

      // Color theme - Use normal for loop for IE11 compatibility
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.document.body.classList.length; i++) {
        const className = this.document.body.classList[i];
        if (className.startsWith('theme-')) {
          this.document.body.classList.remove(className);
        }
      }
      this.document.body.classList.add(this.fuseConfig.colorTheme);
    });
    
    let sideData = getLocalStorageItem('side-menu');
    if (!getToken() || !sideData?.length) {
      delLocalStorageItem(AUTH_TOKEN)
      this._router.navigateByUrl('/login');
      return;
    }
  }


  
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  initializePermissions(){    
    const data = getLocalStorageItem('side-menu')
    this._helper.setMenu(data);
  }
  setSideMenu(data:any){
    this._fuseNavigationService.unregister('main');
    const navigation = [{id: '',title: '',translate: '',type: 'group',children: data}];
    this.navigation = navigation;
    this._fuseNavigationService.register('main', this.navigation);
    this._fuseNavigationService.setCurrentNavigation('main');

  }
}
