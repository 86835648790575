// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";
import { setContext } from '@apollo/client/link/context';
// Material UI
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// Fuse
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { FuseProgressBarComponent } from '@fuse/components/progress-bar/progress-bar.component';

// Theme
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';

// User App
import { appRoutes } from './app.routes';
import { AppComponent } from 'app/app.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoginModule } from './login/login.module';
import { ForgotpswModule } from './forgotpsw/forgotpsw.module'
// Admin Panel
import { AdminModule } from './_admin/admin.module';

// Common
import { UIModule } from './ui-components/ui.module';
import { CustomHttpInterceptor } from './shared/http.interceptor';
import { environment } from "environments/environment";

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule }  from '@angular/fire/auth';

import { ToastrModule } from 'ng6-toastr-notifications';
import { SpinnerService} from './shared/spinner.service';
 
import { VideoProcessingService } from './services/video-processing-service'
import {onError} from '@apollo/client/link/error';
import { FormsModule } from '@angular/forms';

const error = onError(({graphQLErrors, networkError}) => {
  if(networkError &&  networkError['error'] && networkError['error']['errors'] &&  networkError['error']['errors'][0] && networkError['error']['errors'][0]['extensions'] && networkError['error']['errors'][0]['extensions']['code'] && networkError['error']['errors'][0]['extensions']['code'] === 'UNAUTHENTICATED' ){
    localStorage.clear();
    location.reload();
  }
});
const firebaseConfig = environment.firebase;
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('AUTH_TOKEN');
  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
    }
  }
});

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    NgOtpInputModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot(),
    ToastrModule.forRoot(),

    // Material modules
    MatMenuModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    MatChipsModule,
    MatButtonModule,
    MatIconModule,
    MatStepperModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    SampleModule,
    LoginModule,
    AdminModule,
    ForgotpswModule,
    UIModule,

    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    FormsModule
  ],
exports:[
  MatProgressSpinnerModule,
],
  providers: [
    SpinnerService,
    VideoProcessingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
          return {
              cache: new InMemoryCache(),
              link: error.concat( authLink.concat(httpLink.create({
                      uri: environment.graphqlServer,
                  }),
              )),
          };
      },
      deps: [HttpLink],
  },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
