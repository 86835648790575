import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from 'app/services/helper.service';
@Component({
  selector: 'app-search-bar',
  template: `
    <div class="search-wrapper mx-32 mx-md-0">
      <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <input (input)="search($event)" (onkeydown)="search($event)" [placeholder]="placeholder" />
      </div>
    </div>
  `,

  styleUrls: ['../styles.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder: string;


  @Output() searchEvent = new EventEmitter<string>();
  constructor(private _helper: HelperService) {
  }

  ngOnInit(): void {

  }

  search(e: any) {
    this._helper.getData().subscribe(data=>{
      if(data == 'clear'){
        e.target.value = '';
        this._helper.setData('');
      }
    })
    const keyword: string = e.target.value;

    if (keyword.trim().length != 0 && keyword.trim().length % 3 === 0) this.searchEvent.emit(keyword);
    if (!keyword || keyword.trim().length == 0){
      e.target.value = '';
      this.searchEvent.emit();
    } 
  }
}
