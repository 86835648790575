import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, map, take } from 'rxjs/operators';
import { HelperService } from 'app/services/helper.service';
import { SpinnerService } from '../../../../shared/spinner.service';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
import { DevMilestoneTrackerByIdQuery } from 'app/_admin/models/types';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { result } from 'lodash';
interface AddMilestoneTrackerResponse {
  data: {
    addDevMilestoneTracker: {
      message: string;
      status: string;
      data: {
        id: string;
        name: string;
        img: string;
        milestone: string;
        createdAt: string;
        status: number;
      };
    };
  };
}

@Component({
  selector: 'app-edit-tracker-milestone',
  templateUrl: './edit-tracker-milestone.component.html',
  styleUrls: ['./edit-tracker-milestone.component.scss']
})
export class EditMilestoneComponent implements OnInit {

  milestoneId:any;
  milestoneForm:FormGroup; 
  filename: string ;
  imageUrl: any;
  imageData: File;
  milestone:string;
  month:string;
  milestoneTracker:any;
  trackerName:any;
  trackerId:any;
  milestoneMonth:any;
  originalFormValues: any;


  months: any[] = [
    
    { value: "1 month", viewValue: "1 month " },
    { value: "2 months", viewValue: "2 months" },
    { value: "3 months", viewValue: "3 months " },
    { value: "4 months", viewValue: "4 months " },
    { value: "5 months", viewValue: "5 months " },
    { value: "6 months", viewValue: "6 months " },
    { value: "7 months", viewValue: "7 months " },
    { value: "8 months", viewValue: "8 months " },
    { value: "9 months", viewValue: "9 months " },
    { value: "10 months", viewValue: "10 months" },
    { value: "11 months", viewValue: "11 months " },
    { value: "1 year", viewValue: "1 Year" },
    { value: "15 months", viewValue: "15 months" },
    { value: "18 months", viewValue: "18 months" },
    { value: "2 years", viewValue: "2 Years " },
    { value: "30 months", viewValue: "30 months " },
    { value: "3 years", viewValue: "3 Years " },
    { value: "4 years", viewValue: "4 Years " },
    { value: "5 years", viewValue: "5 years " },
    
  ];

  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,private spinnerService: SpinnerService,private route: ActivatedRoute,  private location: Location) { 
  }

  ngOnInit(): void {
    this.setForm()
    this.route.queryParams.subscribe(params => {
       this.milestoneId = +params['id']
       this.milestoneMonth = params['']
       this.trackerId = +params['trackerId']
       this.trackerName =params['trackerName']
    })
    if(this.milestoneId){
      this.getMilestoneById()
    }
    
  }



  setForm(){
    this.milestoneForm = new FormGroup({
      month: new FormControl(this.month, [Validators.required,removeSpacesValidator()]),
      milestoneValue:new FormControl(this.milestone,[Validators.required,removeSpacesValidator()]),
      milestoneIcon: new FormControl('')
    
    })
    this.originalFormValues = this.milestoneForm.value;
  }

  areFormValuesUnchanged(): boolean {
    const currentFormValues = this.milestoneForm.value;
  return (
    JSON.stringify(this.originalFormValues) === JSON.stringify(currentFormValues)
  );
  }
  
  getMilestoneById(){
    const queryRef = this._apollo.watchQuery<DevMilestoneTrackerByIdQuery>({
      query: gql`
          query devMilestoneTrackerById($id: ID!){
            devMilestoneTrackerById(id: $id){
              message
              status
              data {
                id
                milestone
                img
                months
                createdAt
                status
                developmentTrackerId
              }
            }
          }`,
          variables: { id: this.milestoneId  },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.devMilestoneTrackerById.data)).subscribe((result) => {
     this.milestoneTracker = result;
     this.month = this.milestoneTracker.months;
     this.milestone = this.milestoneTracker.milestone;
     this.imageUrl = this.milestoneTracker.img

     this.setForm()
    })

   
  
  }


  cancel(){
    this._helper.goToPage('track-milestone', { queryParams: { trackerId: this.trackerId, trackerName:this.trackerName,month:this.milestoneForm.value.month?this.milestoneForm.value.month:this.month } });
  }
 
  
  editMilestone(){
    if(this.milestoneForm.invalid){
      return;
    }
    if(this.imageData && this.imageData.name){
      this.getImageUrl();
    }else{
      this.updateAreaData();
    }
  }



  getImageUrl(){
    let imgnam = Date.now()+'-'+this.imageData.name;
    const fileRef = this.storage.ref(`/categories/${imgnam}`);
    this.storage.upload(`/categories/${imgnam}`, this.imageData).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){
            this.filename = url;
            this.updateAreaData();
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })
    ).subscribe();
  }


  updateAreaData(){
    this._apollo.mutate({
      mutation: gql `
        mutation updateDevMilestoneTracker($id: ID!, $milestone: String, $img: String, $status: Int,$months:String) {
          updateDevMilestoneTracker(id: $id, milestone: $milestone, img: $img, status: $status ,months:$months) {
            message
            status
            success
          }
        }
      `,
      variables:{id:this.milestoneId,milestone:this.milestoneForm.value.milestoneValue,img:this.filename?this.filename:this.milestoneTracker.img,status:this.milestoneTracker.status,months:this.milestoneForm.value.month}
    }).subscribe(
      () => {
        this._helper.goToPage('track-milestone', { queryParams: { trackerId: this.trackerId, trackerName:this.trackerName,month:this.milestoneForm.value.month?this.milestoneForm.value.month:this.month } });
      },
    )
    
    
  }
  onFileChanged(event) {
    if(!event.target.files || !event.target.files[0]) {
      this.imageUrl = false;
      return false;
    }

    this.imageData = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imageData);

    reader.onload = (event) => {
      this.imageUrl = reader.result;
      this.filename = this.imageData.name;
    }
  }
}
