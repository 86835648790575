<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
  
    <div class="center">
      <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16"> place </mat-icon>
          <span class="logo-text h1"  matTooltip="{{skillName}}" [matTooltipPosition]="'above'"> {{(skillName.length > 15)? (skillName | slice:0:15)+'..':(skillName)}} Skill - Indicator List </span>
        </div>
        
        <!-- SEARCH -->
        <app-search-bar placeholder="Search for a Indicator" (searchEvent)="searchIndicator($event)"> </app-search-bar>
        <button mat-raised-button (click)="getBackToSkill()"><mat-icon>arrow_back</mat-icon>Back</button>
      </div>

      <div style="margin-bottom: 20px;" *ngIf="this.userRoles && this.userRoles.includes(39)">
        <app-add-indicator (onIndicatorAdded)="refresh()" ></app-add-indicator>
      </div>
  
      <div class="content-card" style="overflow-y: scroll">
        <h1 class="err-msg" *ngIf="!indicators">No Indicators Found</h1>
        <div *ngIf="indicators">
          <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
            <!-- Name Column -->
            <ng-container matColumnDef="indicatorname">
              <mat-header-cell *matHeaderCellDef>Indicator Name</mat-header-cell>
              <mat-cell   *matCellDef="let u">
                <p class="text-truncate">{{u.indicatorName}} </p>
              </mat-cell>
            </ng-container>
            
            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
              <mat-cell   *matCellDef="let u">
                <p class="text-truncate">{{u.Status == '1' ? 'Active':'Inactive'}}</p>
              </mat-cell>
            </ng-container>
  
            <!-- Controls Column -->
            <ng-container matColumnDef="menu">
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ id: u.id, index: u.index,status: u.Status}" aria-label="Menu" >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
  
            <mat-menu #menu="matMenu">
              <ng-template matMenuContent let-id="id" let-index="index" let-status="status">
                <button mat-menu-item (click)="editIndicator(id)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit Indicator</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,status)" *ngIf="this.userRoles && this.userRoles.includes(56) ">
                  <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                  <span>{{status == '1'? 'Inactive' : 'Active'}}</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,0)" *ngIf="this.userRoles && this.userRoles.includes(55) ">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </ng-template>
            </mat-menu>
  
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  
            <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
          </mat-table>
  
          <mat-paginator
            #paginator
            [length]="indicators.totalItems"
            [pageSize]="pSize"
            [pageSizeOptions]="dataSize"
            [pageIndex]="indicators.currentPage"
            (page)="changePage($event)"
            >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>