<mat-card>
  <mat-card-header style="display: block; margin-bottom: 10px">
    <mat-card-title>Create Role</mat-card-title>
    <br />
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="roleForm" (ngSubmit)="addRole()">
      <!-- Cause Name -->
      <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
        <mat-label>Role Name</mat-label>
        <input matInput formControlName="rolename" type="text" placeholder="Role Name" />
        <mat-error *ngIf="roleForm.controls['rolename'].hasError('required')">Role Name is Required </mat-error>
        <mat-error *ngIf="roleForm.controls['rolename'].hasError('removeSpaces')">Invalid Role Name</mat-error>
        <!-- <mat-error *ngIf="!roleForm.hasError('removeSpaces') || roleForm.controls['rolename'].hasError('required')"
          >Name is Required
        </mat-error>
        <mat-error *ngIf="roleForm.controls['rolename'].hasError('removeSpaces')">Invalid Role Name</mat-error> -->
      </mat-form-field>
      <br /><br />
      <button mat-raised-button color="primary" style="margin-right: 5px" [disabled]="roleForm.invalid">Save</button>
      <button mat-stroked-button (click)="closeModel()">Close</button>
    </form>
  </mat-card-content>
</mat-card>
