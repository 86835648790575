import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { map } from 'rxjs/operators';
import { UserQuery,RoleQuery,adminUsersProfileUpdate} from '../../../models/types';
import { getLocalStorageRoleItem, ROLES } from 'app/helpers/utils';
import { removeSpacesValidator } from '../../../../shared/custom-validators';

// export function removeSpaces(control: AbstractControl) {
//   if (control && control.value && !control.value.replace(/\s/g, '').length) {
//     control.setValue('');
//   }
//   return null;
// }
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  userId: any;
  editedData:any;
  addUserForm:FormGroup;
  roles:any;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];

  constructor(private _helper: HelperService,private _apollo: Apollo,) { 
    if(!history || !history.state || !history.state.userId){
      this._helper.goToPage('users');
    }
    this.userId = history.state.userId;
    this.getRoles();
    if(this.userId){
     this.editProfile();
    }
    this.setUserForm();
  }

  ngOnInit(): void {
  }
  
  editProfile() {
    this._apollo.watchQuery<UserQuery>({
      query: gql`
          query adminUser($id: ID!){
            adminUser(id: $id){
              id
              username
              firstname
              lastname
              email
              profilePic
              userType
              status
            }
          }`,
          variables: { id: this.userId  },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.adminUser)).subscribe((result) => {
      this.editedData = result;
      this.setUserForm();
    })
  }

  setUserForm() {
    this.addUserForm = new FormGroup({
      id: new FormControl((this.editedData && this.editedData.id)?this.editedData.id:'', [Validators.required]),
      firstname: new FormControl((this.editedData && this.editedData.firstname)? this.editedData.firstname:'', [Validators.required,Validators.pattern("^[a-zA-Z ]*$"),removeSpacesValidator()]),
      lastname: new FormControl((this.editedData && this.editedData.lastname )?this.editedData.lastname:'', [Validators.required,Validators.pattern("^[a-zA-Z ]*$"),removeSpacesValidator()]),
      username: new FormControl((this.editedData && this.editedData.username)?this.editedData.username: '', [Validators.required,removeSpacesValidator()]),
      emailid: new FormControl((this.editedData && this.editedData.email)?this.editedData.email:'', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"),removeSpacesValidator()]),
      usertype: new FormControl((this.roles && this.editedData)?(this.roles.find(c => parseInt(c.id) == this.editedData.userType)) :'', [Validators.required]),
      status: new FormControl((this.editedData)?((this.editedData.status === 0) ? 0 :this.editedData.status): '', [Validators.required])
    })
  }
  editUser() {    
    if(this.addUserForm.invalid){
      return;
    }
    this._apollo.mutate({
      mutation: gql`
          mutation adminUsersProfileUpdate($id:ID!,$username: String,$firstname: String,$lastname: String,$email: String,$usertype: Int,$status:Int){
            adminUsersProfileUpdate(id:$id,username: $username,firstname: $firstname,lastname: $lastname,email: $email,userType: $usertype,status:$status){
              status
              code
              message
            }
          }`,
          variables: { username: this.addUserForm.value.username,lastname:this.addUserForm.value.lastname,
                        firstname:this.addUserForm.value.firstname, email:this.addUserForm.value.emailId,
                        usertype:parseInt(this.addUserForm.value.usertype.id),id:this.addUserForm.value.id,
                        status:parseInt(this.addUserForm.value.status)},
    }).subscribe(
      ({ data }: adminUsersProfileUpdate) => {
        if(data.adminUsersProfileUpdate.code == '200'){
          this._helper.showToast(1,'Profile Updated Successfully');
          
          this._helper.goToPage('/admin/users')
        } else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }

  getRoles() {
    this._apollo.watchQuery<RoleQuery>({
      query: gql`
          query {
            RoleList{
             id
             userType
             status
            }
          }`
    }).valueChanges.pipe(map(result => result.data.RoleList)).subscribe((result) => {
      if(result){
        this.roles = result;
      }else{
        this._helper.showToast(4,'No Roles Found');
      }
    });
  }

  cancel(){
    this._helper.goToPage('users');
  }

}
