<mat-card>
    <mat-card-content>
      <form [formGroup]="indicatorForm" (ngSubmit)="addIndicator(formDirective)" #formDirective="ngForm">
        <div class="form-fields">
          <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
            <mat-label>Indicator Name</mat-label>
            <input matInput formControlName="name" type="text" placeholder="Indicator Name" />
            <mat-error *ngIf="indicatorForm.controls['name'].hasError('required')">Indicator Name is Required </mat-error>
            <mat-error *ngIf="indicatorForm.controls['name'].hasError('removeSpaces')">Invalid Indicator Name</mat-error>

          </mat-form-field>
          </div>
        <button mat-raised-button color="primary" style="margin-right: 5px" [disabled]="indicatorForm.invalid">Save</button>
        <button type="button" (click)="resetForm(formDirective)" mat-stroked-button style="margin-right: 5px">Cancel</button>
      </form>
    </mat-card-content>
  </mat-card>
  
