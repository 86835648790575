<mat-dialog-content class="mat-typography">
    <div id="profile" class="page-layout simple tabbed" *ngIf="newsData">
        <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" >
            <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"  fxLayoutAlign.gt-sm="start center">
                <div class="name"  matTooltip="{{newsData.caption}}"  [matTooltipPosition]="'above'">{{(newsData.caption.length > 35)? (newsData.caption | slice:0:35)+'..':(newsData.caption)}}</div>
            </div>
        </div>
        
        <div class="about-content">
            <mat-card >
                <mat-card-content>
                    <div id="about" class="p-50" fxLayout="row wrap">
                        <div class="about-content" fxLayout="column" fxFlex="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
                            <div class="profile-box info-box general" fxLayout="column">
        
                                
                                <div class="content">
                                    
                                    <div class="info-line">
                                        <div class="title">Posted By</div>
                                        <div class="info">{{newsData.postedBy}}</div>
                                    </div>
                                    <div class="info-line">
                                        <div class="title">Activity</div>
                                        <div class="info">{{newsData.activityName}}</div>
                                    </div>
                                    
                                    <div class="info-line">
                                        <div class="title">First Name</div>
                                        <div class="info">{{newsData.careGiver.firstname}}</div>
                                    </div>
                                    <div class="info-line">
                                        <div class="title">Last Name</div>
                                        <div class="info">{{newsData.careGiver.lastname}}</div>
                                    </div>
                                    <div class="info-line">
                                        <div class="title">Email Id</div>
                                        <div class="info">{{newsData.careGiver.emailId}}</div>
                                    </div>

                                    <div class="info-line">
                                        <div class="title">Status</div>
                                        <div class="info">{{newsData.status == '1' ? 'Active' : 'InActive'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="about-sidebar" fxLayout="column" fxFlex="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
                            <div class="profile-box info-box general" fxLayout="column">
                                <div class="content">
                                    <div class="info-line">
                                        <div class="title">Media</div>
                                        <div class="info"></div>
                                    </div>
                                    <div>
                                        <img *ngIf="newsData.mediaType =='image'" [src]="newsData.media" onerror="'assets/images/no_image.png'" />
                                        <video *ngIf="newsData.mediaType =='video'"   controls [attr.src]="newsData.media" style="max-width: -webkit-fill-available;"></video>                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</mat-dialog-content><br/>
<mat-dialog-actions>
<button style="margin:0 auto;" mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>