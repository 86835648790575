import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Apollo, gql } from 'apollo-angular';
import { getLocalStorageRoleItem, ROLES } from 'app/helpers/utils';
import { HelperService } from 'app/services/helper.service';
import { VideoProcessingService } from 'app/services/video-processing-service';
import { SpinnerService } from 'app/shared/spinner.service';
import { finalize, map } from 'rxjs/operators';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
// export function removeSpaces(control: AbstractControl) {
//   if (control && control.value && !control.value.replace(/\s/g, '').length) {
//     control.setValue('');
//   }
//   return null;
// }
@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {
  postForm:FormGroup; 
  postId:any;
  postDetails:any;
  filename: string = null;
  imageUrl: any;
  videoUrl:any;
  postFormSubmit: boolean;
  imageData: File;
  imageType:any;
  imageIcontoConvert:any;
  imageIcontoConvertUrl:any;
  newImageUrl: any;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      minHeight: '10rem',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: []
};

  constructor(private _helper: HelperService, private _apollo: Apollo, private videoService: VideoProcessingService,private sanitizer: DomSanitizer, private spinnerService:SpinnerService,private storage: AngularFireStorage ) { 
    if(!history || !history.state || !history.state.postId){
      this._helper.goToPage('view-posts');
    }
    this.postId = history.state.postId;
      if(this.postId ){
        this.getPostDetails();
        this.setForm();
      }
  }

  ngOnInit(): void {
  }


  setForm(){
    this.postForm = new FormGroup({
      caption: new FormControl((this.postDetails && this.postDetails.caption)?this.postDetails.caption:'', [Validators.required,removeSpacesValidator()]),
      postIcon: new FormControl(''),
      // duration: new FormControl((this.blogDetails && this.blogDetails.duration)?this.blogDetails.duration:'', [Validators.required,removeSpacesValidator()]),
      description: new FormControl((this.postDetails && this.postDetails.description)? this.postDetails.description:'', [Validators.required,removeSpacesValidator()]),
      status: new FormControl((this.postDetails)?((this.postDetails.status === 0) ? 0 :parseInt(this.postDetails.status)): '', [Validators.required]),
    });
  }
  getPostDetails() {
    this._apollo.watchQuery<any>({
      query: gql`
          query adminpostDetails($id:ID!){
            adminpostDetails(id:$id){
              id
              caption
              type
              media
              mediaType
              description
              adminId
              createdAt
              status
            }
          }`,
          variables: {id:this.postId},
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.adminpostDetails)).subscribe((result) => {
      if(result){
        this.postDetails = result;
        if(this.postDetails.mediaType == 'image' ){
          this.imageUrl = this.postDetails.media;
        }else{
          this.videoUrl = this.postDetails.media;
        }
        this.setForm();
      }else{
        this._helper.showToast(4,'Something Went Wrong.. Try Again');
      }
    });
  }
  cancel(){
    this._helper.goToPage('/admin/view-posts')
  }
  getVideoImage(){
    this.videoService.promptForVideo().then(videoFile => {
      let type = videoFile.type.split("/",1);
      if(type[0] == 'image'){
        return this.videoService.onImageFileChanged(videoFile)
      }else{
        return this.videoService.generateThumbnail(videoFile);
      }
    }).then(result => {      
      if(result && result.status == true){
        this.postFormSubmit = false;
        this.videoUrl = (result.imageUrl)?false:this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result.imageData ))
        this.imageUrl = (result.imageUrl)?result.imageUrl:false;
        this.imageData = result.imageData;
        this.filename = result.filename;
        this.imageIcontoConvert = (result.blobData)?result.blobData:result.url;
        this.imageType = (this.imageIcontoConvert.split(";")[0].split(":"))
      }
    })
  }
  getDuration(e) {    
    if(e.target.duration > 60){
      this.postFormSubmit = true;
      this._helper.showToast(4,'Less Than 1 Min Video is Required');
    }
  }
  editPost(){
    if(this.postForm.invalid){
      return;
    }
    if(this.postFormSubmit){
      this._helper.showToast(4,'Less Than 1 Min Video is Required');
      return;
    }
    if(this.imageData){
      this.updateImageIcon();
    } else{
      this.updatePost()
    }
  }
  updateImageIcon(){
    let imgnam = Date.now()+'-'+this.imageData.name;
    const fileRef = this.storage.ref(`/post/images/${imgnam}`);
    this.spinnerService.show();
    this.storage.upload(`/post/images/${imgnam}`, this.imageData).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){
            this.newImageUrl = url;
            this.updatePost();
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })
    ).subscribe();

  }
  updatePost(){

    this._apollo.mutate({
      mutation: gql`
        mutation updateadminPost($caption: String,$media: String, $mediaType: String,
          $description: String,$id:ID!,$status:Int){
            updateadminPost(caption: $caption,media: $media, mediaType:$mediaType,
            description: $description,id:$id,status:$status)
        }`,
      variables: {id: this.postId,caption:this.postForm.value.caption,
        media:(this.newImageUrl)?this.newImageUrl:this.postDetails.media,mediaType:(this.imageUrl)?'image':'video',description:this.postForm.value.description,
        status:this.postForm.value.status},
    }).subscribe(
      ({ data }: any) => {
        if(data.updateadminPost[0] == true){
          this._helper.showToast(1,'Post Updated Successfully');
          this._helper.goToPage('/admin/view-posts')
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
