<app-loader [loading]="loading"></app-loader>

<div class="err-msg" *ngIf="!loading && errMsg">
  <h1 (click)="getUserDetails()">{{ errMsg }}</h1>
</div>

<div id="profile" class="page-layout simple tabbed" *ngIf="userDetails">
  <div
    class="header p-24"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between end"
  >
    <div
      class="user-info"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center"
    >
      <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/admin/lead-managment'"></button>
      <div class="name">{{ userDetails.displayName }} - {{ getUserType(userDetails?.userTypeId) }}</div>
    </div>
  </div>

  <div class="about-content">
    <mat-tab-group dynamicHeight="true" (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="Info">
        <div id="about" class="p-24" fxLayout="row wrap">
          <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">
            <div class="profile-box info-box general" fxLayout="column">
              <header class="accent">
                <div class="title">General Information</div>
              </header>

              <div class="content">
                <div class="info-line">
                  <div class="title">Email</div>
                  <div class="info">{{ userDetails.emailId }}</div>
                </div>

                <div class="info-line">
                  <div class="title">Phone</div>
                  <div class="info">{{ userDetails.phone }}</div>
                </div>

                <div class="info-line">
                  <div class="title">Status</div>
                  <div class="info">{{ getStatus(userDetails.status).title }}</div>
                </div>

                <div class="info-line">
                  <div class="title">Email Verified</div>
                  <div class="info">{{ userDetails.verificationStatus === 1 ? 'Yes' : 'No' }}</div>
                </div>

                <div class="info-line">
                  <div class="title">Total Donations</div>
                  <div class="info">{{ userDetails.totalDontions }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="about-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="35">
            <div class="profile-box info-box general" fxLayout="column">
              <div *ngIf="userDetails?.proUsersData?.length">
                <header class="accent">
                  <div class="title">Pro User Data</div>
                </header>

                <div class="content">
                  <div class="info-line">
                    <div class="title">Identity</div>
                    <div class="info">{{ userDetails.proUsersData[0]?.identity }}</div>
                  </div>

                  <div class="info-line">
                    <div class="title">Specialization</div>
                    <div class="info">{{ userDetails.proUsersData[0]?.specialization }}</div>
                  </div>

                  <div class="info-line">
                    <div class="title">Years Of Experience</div>
                    <div class="info">{{ userDetails.proUsersData[0]?.experience }}</div>
                  </div>

                  <div class="info-line">
                    <div class="title">City</div>
                    <div class="info">{{ userDetails.proUsersData[0]?.city }}</div>
                  </div>

                  <div class="info-line">
                    <div class="title">Register No</div>
                    <div class="info">{{ userDetails.proUsersData[0]?.regno }}</div>
                  </div>

                  <div class="info-line">
                    <div class="title">Document</div>
                    <div class="info" (click)="openDoc(userDetails.proUsersData[0]?.docImage)">Download</div>
                  </div>
                </div>
              </div>
            </div>

            <app-user-controls
              [userId]="userDetails?.userId || userDetails.id"
              [status]="userDetails?.status"
              [type]="userDetails?.proUsersData[0]?.verifiedUser"
              (userInit)="getUserDetails()"
            >
            </app-user-controls>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Cases">
        <app-user-cases [userID]="userId"></app-user-cases>
      </mat-tab>

      <mat-tab label="Donations">
        <app-user-donations [userID]="userId"></app-user-donations>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
