import { Component, Inject, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog,MatDialogClose, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {ContactQuery,contactUs,updateContactUs } from '../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";

export interface DialogData {
  image: string;
}
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  loading: boolean = false;
  contacts:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;
  getContactQuery:QueryRef<any>;
  private querySubscription: Subscription;
  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['name', 'email','subject','message','Attachment','status'];
  constructor(private _helper: HelperService,private _apollo: Apollo, private _model: MatDialog) { 
    this.getContacts();
    if(this.userRoles && (this.userRoles.includes(59))){
      this.displayedColumns.push('menu')
    }
  }

  ngOnInit(): void {
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;

    this.getContacts();
  }
  searchContact(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getContacts();
  }

  getContacts() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : ''}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getContactQuery = this._apollo.watchQuery<ContactQuery>({
      query: gql`
          query contactUsList($page: Int, $size: Int, $search: String){
            contactUsList(page: $page, size: $size, search: $search){
              totalItems
              data{
                id
                caption
                message
                careGiverId
                careGiver{
                  firstname
                  lastname
                  emailId
                }
                attachment
                attachmentType
                status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex),
            size: parseInt(pagesize),
            search: search
          },
          fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getContactQuery.valueChanges.pipe(map(result => result.data.contactUsList)).subscribe((result) => {
      if (result.totalItems) {
        const ELEMENT_DATA:any = result.data.map((cont: contactUs) => ({
          id: cont.id,
          firstname: (cont.careGiver && cont.careGiver.firstname)?cont.careGiver.firstname:' -- ',
          lastname: (cont.careGiver && cont.careGiver.lastname)?cont.careGiver.lastname:' -- ',
          emailId: (cont.careGiver && cont.careGiver.emailId)?cont.careGiver.emailId:' -- ',
          subject: cont.caption,
          message: cont.message,
          attachment: cont.attachment,
          type: cont.attachmentType,
          status: cont.status,
        }));
        this.dataSource = new MatTableDataSource<ContactQuery>(ELEMENT_DATA);
        this.contacts = result;
      } else {
        this._helper.showToast(4,'No Data Found');
      }
    });

  }


  changeStatus(id,status,curStat){
    if(status == 3 && curStat === 3){
      this._helper.showToast(1,'Issue Already Resolved Successfully');
      return;
    }
    this._apollo.mutate({
      mutation: gql`
        mutation updateContactUs($id: ID!,$status: Int){
          updateContactUs(id:$id,status:$status)
        }`,
      variables: {id: parseInt(id),status:status},
    }).subscribe(
      ({ data }: updateContactUs) => {
        if(data.updateContactUs[0] == true){
          this.getContactQuery.refetch();
          this._helper.showToast(1,(status == 2)?'Issue is Under Processing':'Issue Resolved Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }

  openImage(image){
    const dialogRef = this._model.open(ContactDialog,{
      data: {
        image: image
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });


  }
}

@Component({
  selector: 'contact-dialog',
  templateUrl: 'contact-dialog.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactDialog {
  constructor(private dialog: MatDialogRef<ContactDialog>,@Inject(MAT_DIALOG_DATA) public data: DialogData) { 
  }

  closeModal(){
    this.dialog.close();
  }

}
