<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" style="background-color: #ffffff;">

            <div class="logo">
                <img src="assets/images/logos/logo.png">
            </div>

            <div class="title">RECOVER PASSWORD</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email Id</mat-label>
                    <input matInput formControlName="emailId" type="text" placeholder="Email Id" />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="forgotPasswordForm.controls['emailId'].hasError('pattern')">Invalid Email Id </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.controls['emailId'].hasError('required')"> Email Id is required </mat-error>
               
                </mat-form-field>
                <button mat-raised-button color="accent" class="submit-button" 
                        aria-label="SEND RESET LINK" [disabled]="forgotPasswordForm.invalid" (click)="forgotPassword();">
                    SEND RESET LINK 
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Go back to login</a>
            </div>

        </div>

    </div>

</div>
