<div id="agents" class="page-layout carded fullwidth">
    <div class="center">
        <div class="header" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">

        </div>
        
        <div class="content-card" style="overflow-y: scroll">
            <h1 class="err-msg" >You Have No Permission to Access this Page</h1>
        </div>
    </div> 
</div>