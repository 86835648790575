import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '../../../../shared/spinner.service';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
interface addArea {
  data: {
    addArea: {
      id: any,
      status: any
    };
  };
}

// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && !control.value.replace(/\s/g, '').length) {
//       return  {removeSpaces: {value: null}};
//     }
//     return;
//   };
// }
// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && (/\s/.test(control.value.charAt(0)) || /\s/.test(control.value.charAt(control.value.length - 1)))) {
//       return  {removeSpaces: {value: control.value}};
//     }
//     return null;
//   };
// }


@Component({
  selector: 'app-add-area',
  templateUrl: './add-area.component.html',
  styleUrls: ['./add-area.component.scss']
})
export class AddAreaComponent implements OnInit {
  areaForm:FormGroup; 
  // area:any;
  filename: string = null;
  imageUrl: any;
  imageData: File;

  @Output() onAreaAdded = new EventEmitter();

  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.areaForm = new FormGroup({
      areaname: new FormControl('', [Validators.required,removeSpacesValidator()]),
      areaIcon: new FormControl('', [Validators.required])
    })
  }

  addArea(formDirective: FormGroupDirective){
    if(this.areaForm.invalid){
      return;
    }
    let imgnam = Date.now()+'-'+this.imageData.name;
    const fileRef = this.storage.ref(`/categories/${imgnam}`);
    this.spinnerService.show();
    this.storage.upload(`/categories/${imgnam}`, this.imageData).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){
            this._apollo.mutate({
              mutation: gql`
                mutation addArea($areaName: String,$icon:String!){
                  addArea(areaName: $areaName,icon:$icon){
                    id
                    status
                  }
                }`,
              variables: { areaName:this.areaForm.value.areaname,icon:url},
            }).subscribe(
              ({ data }: addArea) => {
                if(data.addArea.id){
                  this._helper.showToast(1,'Area Added Successfully');
                  this.resetForm(formDirective);
                  this.onAreaAdded.emit();
                }else{
                  this._helper.showToast(4,'Something Went Wrong.. Try Again');
                }
              },(err) => {
                this._helper.showToast(4,err.message);
              }
            );
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })
    ).subscribe();
  }

  resetForm(formDirective: FormGroupDirective) {
    this.areaForm.reset();
    formDirective.resetForm(); // to remove mat-errors

    this.filename = null;
    this.imageUrl = null;
    this.imageData = null;
  }
  
  onFileChanged(event) {
    if(!event.target.files || !event.target.files[0]) {
      this.imageUrl = false;
      return false;
    }

    this.imageData = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imageData);

    reader.onload = (event) => {
      this.imageUrl = reader.result;
      this.filename = this.imageData.name;
    }
  }
}
