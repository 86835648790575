<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
  
    <div class="center">
      <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16"> flare </mat-icon>
          <span class="logo-text h1"   matTooltip="{{areaName}}"  [matTooltipPosition]="'above'"> {{(areaName.length > 15)? (areaName | slice:0:15)+'..':(areaName)}} Area - Skills List </span>
        </div>
        
        <!-- SEARCH -->
        <app-search-bar placeholder="Search for a Skill" (searchEvent)="searchSkill($event)"> </app-search-bar>
        <button mat-raised-button [routerLink]="'/admin/view-area'"><mat-icon>arrow_back</mat-icon>Back</button>
      </div>
  
      <div style="margin-bottom: 20px;"  *ngIf="this.userRoles && this.userRoles.includes(36)">
        <app-add-skills (onSkillAdded)="refresh()" ></app-add-skills>
      </div>

      <div class="content-card" style="overflow-y: scroll">
        <h1 class="err-msg" *ngIf="!skills">No Skills Found</h1>
  
        <div *ngIf="skills">
          <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
            <!-- Name Column -->
            <ng-container matColumnDef="SkillName">
              <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
              <mat-cell  (click)="viewIndicator(u.id,u.SkillName)"  *matCellDef="let u">
                <p class="text-truncate">{{u.SkillName}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Icon">
              <mat-header-cell *matHeaderCellDef>Icon</mat-header-cell>
              <mat-cell (click)="viewIndicator(u.id,u.SkillName)"   *matCellDef="let u">
                <p class="text-truncate"><img [src]="u.Icon" style="max-width: 50px;max-height: 50px;"/><br/><br/> </p>
              </mat-cell>
            </ng-container>
  
            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
              <mat-cell  (click)="viewIndicator(u.id,u.SkillName)"  *matCellDef="let u">
                <p class="text-truncate">{{u.Status == '1' ? 'Active':'Inactive'}}</p>
              </mat-cell>
            </ng-container>
  
            <!-- Controls Column -->
            <ng-container matColumnDef="menu">
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{ id: u.id, skillname: u.SkillName,status: u.Status}"
                  aria-label="Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
  
            <mat-menu #menu="matMenu">
              <ng-template matMenuContent let-id="id" let-skillname="skillname" let-status="status">
                <button mat-menu-item (click)="editSkill(id)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit Skill</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,status)" *ngIf="this.userRoles && this.userRoles.includes(53) ">
                  <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                  <span>{{status == '1'? 'Inactive' : 'Active'}}</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,0)" *ngIf="this.userRoles && this.userRoles.includes(54) ">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </ng-template>
            </mat-menu>
  
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  
            <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
          </mat-table>
  
          <mat-paginator 
            #paginator
            [length]="skills.totalItems"
            [pageSize]="pSize"
            [pageSizeOptions]="dataSize"
            [pageIndex]="skills.currentPage"
            (page)="changePage($event)"
            >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>