import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { Role } from '../../../models/types';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
interface addRole {
  data: {
    addRole: {
      id: any,
      userType: string,
      status: any
    };
  };
}
// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && !control.value.replace(/\s/g, '').length) {
//       return  {removeSpaces: {value: null}};
//     }
//     return;
//   };
// }
@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

  roleForm:FormGroup; 

  constructor(private _helper: HelperService,private _modelRef: MatDialogRef<AddRoleComponent>,private _apollo: Apollo) { 
  }

  ngOnInit(): void {
    this.roleForm = new FormGroup({
      rolename: new FormControl('', [Validators.required,removeSpacesValidator()])
    })
    
  }
  addRole() {
    if(this.roleForm.invalid){
      return;
    }
    this._apollo.mutate({
      mutation: gql`
          mutation addRole($usertype: String!){
            addRole(userType: $usertype){
              id
              userType
              status
            }
          }`,
          variables: { usertype:this.roleForm.value.rolename.trim()},
    }).subscribe(
      ({ data }: addRole) => {
        if(data.addRole.id){
          
          this._helper.goToPage('/user-permissions',{state: {userType:data.addRole.id,username:this.roleForm.value.rolename.trim()}})
          this._modelRef.close();
          this._helper.showToast(1,'Role Updated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }

  closeModel(): void {
    this._modelRef.close();
  }

}
