<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    
    <div class="center">
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16"> flare </mat-icon>
                <span class="logo-text h1"> Edit Skill</span>
            </div>
        </div>
        
        <div class="content-card" >
            <div >
                <mat-card>
                    <mat-card-content>
                        <form [formGroup]="skillForm">
                            <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                                <mat-label>Skill</mat-label>
                                <input matInput formControlName="skillname" type="text" placeholder="Skill" />
                                <mat-error *ngIf="skillForm.controls['skillname'].hasError('required')">Skill is Required </mat-error>
                                <mat-error *ngIf="skillForm.controls['caption'].hasError('removeSpaces')">Invalid Skill</mat-error>

                            </mat-form-field> 
                            <!-- <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                                <mat-label>Area</mat-label>
                                <mat-select formControlName="areaId" placeholder="Choose Area">
                                    <mat-option selected [value]="-1" disabled>Choose Area</mat-option>
                                    <mat-option *ngFor="let area of areas" [value]="area">
                                    {{area.areaName}}
                                  </mat-option>
                                </mat-select>
                                <mat-error *ngIf="skillForm.controls['areaId'].hasError('required')">Area is Required </mat-error>
                            </mat-form-field> -->
                            <mat-form-field  class="w-80-p" floatPlaceholder="never">
                                <mat-label>Icon</mat-label>
                                <input [hidden]="true" (change)="onFileChanged($event)" type="file" accept="all/*" #fileSelect>
                                <input readonly matInput style="cursor: pointer"  (click)="fileSelect.click();"  formControlName="skillIcon"  [(ngModel)]="filename" placeholder="Select file..."/>
                                <div style="float: right; cursor: pointer;margin-top: -25px;" (click)="fileSelect.click();">
                                    <mat-icon>attach_file</mat-icon>
                                  </div>
                            </mat-form-field>
                            <img *ngIf="imageUrl" class="uploadTempnail" id="image_id" [src]="imageUrl" onerror="'assets/images/no_image.png'" /><br/><br/>
                            <mat-radio-group aria-label="Select an option" formControlName="status" *ngIf="this.userRoles && (this.userRoles.includes(53) || this.userRoles.includes(54))">
                                <mat-radio-button *ngIf="this.userRoles.includes(53)" [value]="1">Active</mat-radio-button>
                                <mat-radio-button *ngIf="this.userRoles.includes(53)" [value]="2">Inactive</mat-radio-button>
                                <mat-radio-button *ngIf="this.userRoles.includes(54)" [value]="0">Delete</mat-radio-button>
                            </mat-radio-group>
                              <mat-error *ngIf="skillForm.controls['status'].hasError('required')">Status is Required </mat-error>
              <br/>
              <br/>
                            <button mat-raised-button color="primary" style="margin-right: 5px"  [disabled]="skillForm.invalid"  (click)="editSkill()"> Save </button>
                            &nbsp;&nbsp;
                            <button mat-stroked-button  (click)="cancel()" >
                                <span>Cancel</span>
                            </button>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>

