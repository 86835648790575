<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
  
    <div class="center">
      <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center"
      >
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16"> map </mat-icon>
          <span class="logo-text h1"> Months </span>
        </div>
        <app-search-bar placeholder="Search for Month" (keyup)="searchMilestone($event)"> </app-search-bar>

      </div>
  
      <div class="content-card" style="overflow-y: scroll">  
          <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
            <!-- Name Column -->
            <ng-container matColumnDef="MileStone">
              <mat-header-cell *matHeaderCellDef>Months</mat-header-cell>
              <mat-cell  *matCellDef="let u">
                <p class="text-truncate">{{u.name}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Count">
              <mat-header-cell *matHeaderCellDef>Count</mat-header-cell>
              <mat-cell  *matCellDef="let u">
                <p class="text-truncate">{{u.count}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ViewDetails">
              <mat-header-cell *matHeaderCellDef>View Assigned Activities</mat-header-cell>
              <mat-cell  *matCellDef="let u">
                <button mat-raised-button color="success"   (click)="viewActivity(u.value)">
                  <mat-icon>visibility </mat-icon>
                  <span>  View</span>
                </button>
              </mat-cell>
            </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  
            <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
          </mat-table>
  
          <mat-paginator #paginator [pageSize]="pSize" [pageSizeOptions]="dataSize" > </mat-paginator>
      </div>
    </div>
  </div>
