<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
  
    <div class="center">
      <div
        class="header accent"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between center"
      >
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16"> school </mat-icon>
          <span class="logo-text h1"> Learn / Blog List</span>
        </div>
  
        <!-- SEARCH -->
        <app-search-bar placeholder="Search for a Learn" (searchEvent)="searchBlog($event)"> </app-search-bar>
        
        <div class="btn-group">
          <!-- <button mat-raised-button *ngIf="this.userRoles && this.userRoles.includes(23)" style="margin-right: 10px;"><mat-icon>add</mat-icon> Add Tag</button> -->
          <button mat-raised-button *ngIf="this.userRoles && this.userRoles.includes(23)" [routerLink]="'/admin/add-blog'"><mat-icon>add</mat-icon> Add Learn</button>
        </div>
      </div>
  
      <div class="content-card" style="overflow-y: scroll">
        <div id="about"  style="margin-top: 25px;" fxLayout="row wrap" fxLayoutAlign="space-evenly none" >
          <mat-form-field [attr.fxFlex]="filter_type == '3' ? '40' : '80'" appearance="outline" class="w-50-p">
            <mat-label>Filter By</mat-label>
            <mat-select (selectionChange)="filterBy($event)" [(ngModel)]="filterByInp">
              <mat-option value="1"> 1 Month </mat-option>
              <mat-option value="2"> 1 Week </mat-option>
              <mat-option value="4"> Today </mat-option>
              <mat-option value="3"> Choose Date</mat-option>
            </mat-select>
            </mat-form-field>
          <mat-form-field fxFlex="40" appearance="outline" class="w-50-p" *ngIf="filter_type == '3'">
            <input
              matInput
              placeholder="Select Date Range"
              [satDatepicker]="picker"
              readonly
              [max]="maxDate"
              (dateChange)="getSelectedDate($event)"
            />
            <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
            <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
          </mat-form-field>

            <button mat-raised-button color="accent" style="height: 42px; margin-top: 14px; width:99px; float:right;" (click)="onClear()">Clear</button>
          
        </div>
        <h1 class="err-msg" *ngIf="!blogs" >No Data Found</h1>
  
        <div *ngIf="blogs">
          <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
            <!-- Name Column -->
            <ng-container matColumnDef="Caption">
              <mat-header-cell *matHeaderCellDef>Caption</mat-header-cell>
              <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
                <p class="text-truncate">{{u.caption}} </p>
              </mat-cell>
            </ng-container>
            <!-- <ng-container matColumnDef="Duration">
              <mat-header-cell *matHeaderCellDef>Duration</mat-header-cell>
              <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
                <p class="text-truncate">{{u.duration}} </p>
              </mat-cell>
            </ng-container> -->
            <ng-container matColumnDef="Milestone">
              <mat-header-cell *matHeaderCellDef>Milestone</mat-header-cell>
              <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
                <p class="text-truncate">{{u.milestone}} </p>
              </mat-cell>
            </ng-container>
            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
              <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
                <p class="text-truncate">
                  {{u.Status == '1' ? 'Active':'Inactive'}}
                </p>
              </mat-cell>
            </ng-container>

            <!-- Blog views Column -->
            <ng-container matColumnDef="views">
              <mat-header-cell *matHeaderCellDef>Blog views</mat-header-cell>
              <mat-cell (click)="viewDetails(u.id)" *matCellDef="let u">
                <p class="text-truncate">
                  {{u.blogView}}
                </p>
              </mat-cell>
            </ng-container>
  
            <!-- Controls Column -->
            <ng-container matColumnDef="menu">
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{ id: u.id, status: u.Status}"
                  aria-label="Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
  
            <mat-menu #menu="matMenu">
              <ng-template matMenuContent let-id="id" let-status="status">
                <button mat-menu-item  (click)="editBlog(id)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit Learn</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,status)" *ngIf="this.userRoles && this.userRoles.includes(48) ">
                  <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                  <span>{{status == '1'? 'Inactive' : 'Active'}}</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,0)" *ngIf="this.userRoles && this.userRoles.includes(47) ">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </ng-template>
            </mat-menu>
  
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  
            <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
          </mat-table>
  
          <mat-paginator
            #paginator
            [length]="blogs.totalItems"
            [pageSize]="pSize"
            [pageSizeOptions]="dataSize"
            [pageIndex]="blogs.currentPage"
            (page)="changePage($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  