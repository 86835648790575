<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    
    <div class="center">
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16"> admin_panel_settings </mat-icon>
                <span class="logo-text h1"> CMS Management</span>
            </div>
        </div>
        
        <div class="content-card" style="overflow-y: scroll">
            <mat-card >
                <mat-card-content>
                    <form [formGroup]="cmsForm">
                        <mat-label><b>About Us</b></mat-label>
                        <angular-editor id="about" [config]="editorConfig"  [placeholder]="'About Us'" formControlName="about"></angular-editor>
                        <div *ngIf=" cmsForm.controls['about'].touched">
                            <mat-error *ngIf="cmsForm.controls['about'].hasError('required')">About Us is Required </mat-error>
                        </div>
                        
                        <br/>
                        <mat-label><b>Privacy Policy</b></mat-label>
                        <angular-editor id="privacy" [config]="editorConfig"  [placeholder]="'Privacy Policy'" formControlName="privacy"></angular-editor>
                        <div *ngIf=" cmsForm.controls['privacy'].touched">
                            <mat-error *ngIf="cmsForm.controls['privacy'].hasError('required')">Privacy Policy is Required </mat-error>
                        </div>
                        
                        <br/>
                        <mat-label><b>Help Guide</b></mat-label>
                        <angular-editor id="help" [config]="editorConfig"  [placeholder]="'Help Guide'" formControlName="helpguide"></angular-editor>
                        <div *ngIf=" cmsForm.controls['helpguide'].touched">
                            <mat-error *ngIf="cmsForm.controls['helpguide'].hasError('required')">Help Guide is Required </mat-error>
                        </div>
                        
                        <br/>
                        <mat-label><b>Terms And Conditions</b></mat-label>
                        <angular-editor id="terms" [config]="editorConfig"  [placeholder]="'Terms And Conditions'" formControlName="terms"></angular-editor>
                        <div *ngIf=" cmsForm.controls['terms'].touched">
                            <mat-error *ngIf="cmsForm.controls['terms'].hasError('required')">Terms and Conditions is Required </mat-error>
                        </div>
                        
                        <br/>
                        <button mat-raised-button color="primary"  [disabled]="cmsForm.invalid"  (click)="updateCMS()" >
                            <span>Save</span>
                        </button>   
                        &nbsp;&nbsp;       
                        <br/>
                        <br/>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

