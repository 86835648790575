import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { HelperService } from 'app/services/helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { User,AreaQuery,IndicatorQuery,MaterialQuery } from '../../../models/types';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '../../../../shared/spinner.service';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
// import { AngularEditorConfig } from '@kolkov/angular-editor';
interface addActivity  {
  data: {
    addActivity : {
      id: any,
      status: any
    };
  };
}
// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && !control.value.replace(/\s/g, '').length) {
//       return  {removeSpaces: {value: null}};
//     }
//     return;
//   };
// }
@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit {
  activityForm:FormGroup;
  skills:any;  
  filename: string = null;
  imageUrl: any;
  imageData: File;
  videoUrl: any;
  activityFormSubmit:boolean;
  selectedSkillId:any;
  skillIndicators:any;
  indicator:any;
  materials: any;
  ageGroupList: any;
  milestones = ELEMENT_DATA;
  fruits: string[] = [];
  groups: string[] = [];
  selectable = true;
  removable = true;
  separatorKeysCodesGroup: number[] = [ENTER];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredGroups: Observable<string[]>;
  filteredFruits: Observable<string[]>;
  materialsFC = new FormControl('', [Validators.required]);
  agegroupFC = new FormControl('', [Validators.required,Validators.pattern('^[A-Za-z0-9-, ]+$')]);
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto_1') matAuto1: MatAutocomplete;
  @ViewChild('auto_2') matAuto2: MatAutocomplete;
  area: any;
  selectedAreaId: string;
  constructor(private _helper: HelperService,private _apollo: Apollo,private sanitizer: DomSanitizer,
    private storage: AngularFireStorage,private spinnerService: SpinnerService) {
      this.getAreas();
      this.getAgeGroups();
      this.getMaterials();
      this.setForm();
    }
    
    ngOnInit(): void {
      
    }
    setForm(){
      this.activityForm = new FormGroup({
        activitycode: new FormControl('', [Validators.required,removeSpacesValidator()]),
        activityname: new FormControl('', [Validators.required,removeSpacesValidator()]),
        skillid: new FormControl('', [Validators.required]),
        areaId: new FormControl('', [Validators.required]),
        interactions: new FormControl('', [Validators.required,removeSpacesValidator()]),
        indicators: new FormControl('', [Validators.required]),
        duration: new FormControl('', [Validators.required,removeSpacesValidator()]),
        objective: new FormControl('', [Validators.required,removeSpacesValidator()]),
        media: new FormControl('', [Validators.required]),
        milestone: new FormControl('', [Validators.required]),
        materials: this.materialsFC,
        agegroup: this.agegroupFC,
        description: new FormControl('', [Validators.required,removeSpacesValidator()]),
        evaluation: new FormControl('', [Validators.required,removeSpacesValidator()]),
        place: new FormControl('', [Validators.required]),
        other: new FormControl('', [Validators.required,removeSpacesValidator()]),
      })
    }
    
    addActivity(){
      if(this.activityForm.invalid){
        return;
      }

      let imgnam = Date.now()+'-'+this.imageData.name;
      const fileRef = this.storage.ref(`/activities/${imgnam}`);
      this.spinnerService.show();
      this.storage.upload(`/activities/${imgnam}`, this.imageData).snapshotChanges().pipe(finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){
            this._apollo.mutate({
              mutation: gql`
              mutation addActivity ($activityCode: String,$activityName: String,$media: String,$mediaType: String,
                $interactions: String,$indicators: String,$duration: String,$materials: String,
                $objective: String,$description: String,$evaluation: String,$indicatorSkillIds:String,
                $place: String,$other: String,$skillId: Int,$areaId:Int,$milestone:String,$ageGroup:String){
                  addActivity(activityCode:$activityCode,activityName: $activityName, media: $media, mediaType: $mediaType,
                    interactions: $interactions, indicators: $indicators, duration: $duration,
                    materials: $materials, objective: $objective, description: $description,
                    evaluation: $evaluation, place: $place, other: $other,indicatorSkillIds:$indicatorSkillIds,
                    skillId: $skillId, areaId: $areaId,milestone:$milestone,ageGroup:$ageGroup){
                      id
                      status
                    }
                  }`,
                  variables: { activityCode:this.activityForm.value.activitycode,activityName:this.activityForm.value.activityname,media:url,mediaType:(this.imageUrl)?'image':'video',interactions:this.activityForm.value.interactions,
                  indicators:this.activityForm.value.indicators.toString(),duration:this.activityForm.value.duration,
                  materials:this.fruits.toString(),objective:this.activityForm.value.objective,
                  description:this.activityForm.value.description,indicatorSkillIds:this.activityForm.value.indicators.toString(),
                  evaluation:this.activityForm.value.evaluation,place:this.activityForm.value.place,
                  other:this.activityForm.value.other,skillId:parseInt(this.activityForm.value.skillid.id),areaId:parseInt(this.activityForm.value.areaId.id),
                  milestone:this.activityForm.value.milestone.toString(),ageGroup:this.groups.toString()
                }
            }).subscribe(
              ({ data }: addActivity ) => {
                if(data.addActivity.id){
                  this._helper.showToast(1,'Activity Added Successfully');
                  this._helper.goToPage('/admin/view-activities')
                }else{
                  this._helper.showToast(4,'Something Went Wrong.. Try Again');
                }
              },(err) => {
               this._helper.showToast(4,err.message);
            });
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })).subscribe();
    }
          
    getAreas() {
      this._apollo.watchQuery<AreaQuery>({
        query: gql`
        query areaActiveList($search: String){
          areaActiveList(search: $search){
            id
            areaName
            icon
            status
          }
        }`,
        variables: {search: ''},
        fetchPolicy:'network-only'
      }).valueChanges.pipe(map(result => result.data.areaActiveList)).subscribe((result) => {
        if(result){
          this.area = result;
        }
      });
    }

    getMaterials() {
      this._apollo.watchQuery<MaterialQuery>({
        query: gql`
        query materialList($search: String){
          materialList(search: $search){
            name
          }
        }`,
        variables: {search: ''},
        fetchPolicy:'network-only'
      }).valueChanges.pipe(map(result => result.data.materialList)).subscribe((result) => {
        if(result){
          this.materials = result;
          this.setForm();
          this.filteredFruits = this.materialsFC.valueChanges.pipe(
            startWith(''),
            map((fruit: string | null) => fruit ? this._filter(fruit,1) : this.materials.slice()));
          }
        });
      }

      getAgeGroups() {
      this._apollo.watchQuery<MaterialQuery>({
        query: gql`
        query ageGroupExistList($search: String){
          ageGroupExistList(search: $search){
            ageGroup
          }
        }`,
        variables: {search: ''},
        fetchPolicy:'network-only'
      }).valueChanges.pipe(map(result => result.data.ageGroupExistList)).subscribe((result) => {
        if(result){
          this.ageGroupList = result;
          this.setForm();
          this.filteredGroups = this.agegroupFC.valueChanges.pipe(
            startWith(''),
            map((group: any) => group ? this._filter(group,2) : this.ageGroupList.slice()));
          }
        });
      }
            
      onFileChanged(event) {
        this.activityFormSubmit = false;
        if(!event.target.files || !event.target.files[0]) {
          this.imageUrl = false;
          this.videoUrl = false;
          return false;
        }
        this.imageData = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(this.imageData);
        let type = this.imageData.type.split("/",1);
        
        reader.onload = (event) => {
          if(type[0] == 'video'){
            this.imageUrl = false
            this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.imageData ));
          }else{
            this.videoUrl = false;
            this.imageUrl = reader.result;
          }
          this.filename = this.imageData.name;
        }
      }
      getDuration(e) {    
        if(e.target.duration > 60){
          this.activityFormSubmit = true;
          this._helper.showToast(4,'Less Than 1 Min Video is Required');
        }
      }
            
      cancel(){
        this._helper.goToPage('/admin/view-activities')
      }
      selectSkill(event){
        this.selectedAreaId = (event)?event.value.id:'';
        this._apollo.watchQuery<AreaQuery>({
          query: gql`
          query areaIdBasedskillActive($areaId: Int){
            areaIdBasedskillActive(areaId: $areaId){
              id
              skill
              status
            }
          }`,
          variables: {areaId: parseInt(this.selectedAreaId)},
        }).valueChanges.pipe(map(result => result.data.areaIdBasedskillActive)).subscribe((result) => {
          if(result){
            this.skills = result;
          }
        });
      }
      selectIndicator(event){
        this.selectedSkillId = (event)?event.value.id:'';
        this._apollo.watchQuery<IndicatorQuery>({
          query: gql`
          query skillIdBasedindicatorSkill($skillId: Int){
            skillIdBasedindicatorSkill(skillId: $skillId){
              id
              indicatorSkill
              skills{
                id
                areaId
              }
              status
            }
          }`,
          variables: {skillId: parseInt(this.selectedSkillId)},
          fetchPolicy:"network-only"
        }).valueChanges.pipe(map(result => result.data.skillIdBasedindicatorSkill)).subscribe((result) => {
          if(result){
            this.skillIndicators = result;
          }
        });
      }
            
      add(event: MatChipInputEvent,type): void {
        const value = (event.value || '').trim();
        if (value) {
          if(type == 1){
            this.fruits.push(value);
          }else{
            this.groups = new Array();
            this.groups.push(value);
          }
        }
        
        if (event.input) {
          event.input.value = '';
        }
        if(type == 1){
          this.activityForm.controls['materials'].setValue(this.fruits);
        }else{
          this.activityForm.controls['agegroup'].setValue(this.groups);
        }
      }
            
      remove(item: string,type): void {
        const index = (type == 1) ? this.fruits.indexOf(item):this.groups.indexOf(item);
        if (index >= 0) {
          if(type == 1){
            this.fruits.splice(index, 1);
          }else{
            this.groups.splice(index, 1);
            this.agegroupFC.updateValueAndValidity();
          }
        }
      }
            
      selected(event: MatAutocompleteSelectedEvent,type): void {        
        if(type == 1){
          if(!this.fruits.includes(event.option.viewValue)){
            this.fruits.push(event.option.viewValue);
          }
          this.fruitInput.nativeElement.value = '';
          this.activityForm.controls['materials'].setValue(this.fruits);
        }else{
          this.groups = new Array();
          this.groups.push(event.option.viewValue);
          this.groupInput.nativeElement.value = '';
          this.activityForm.controls['agegroup'].setValue(this.groups);
        }
      }
            
      private _filter(value: string,type): string[] {
        if(typeof(value) == 'object'){
          return;
        }
        if(type == 1){
          return this.materials.filter(fruit => fruit.name.toLowerCase().indexOf(value.toLowerCase()) === 0);
        } else{
          return this.ageGroupList.filter(fruit => fruit.ageGroup.toLowerCase().indexOf(value.toLowerCase()) === 0);
        }
      }
            
      addOnBlur(event: FocusEvent,type) {    
        const target: HTMLElement = event.relatedTarget as HTMLElement;
        if (!target || target.tagName !== 'MAT-OPTION') {
          const matChipEvent: MatChipInputEvent = {
                        input: (type == 1) ? this.fruitInput.nativeElement : this.groupInput.nativeElement,
                        value : (type == 1) ? this.fruitInput.nativeElement.value : this.groupInput.nativeElement.value};
          this.add(matChipEvent,type);
        }
      }
    }


export interface Element {
  id: number;
  name: string;
  value: string;
}

const ELEMENT_DATA: Element[] = [
  {id:0,name:"Month 00", value:"M00"},
  {id:1,name:"Month 01", value:"M01"},
  {id:2,name:"Month 02", value:"M02"},
  {id:3,name:"Month 03", value:"M03"},
  {id:4,name:"Month 04", value:"M04"},
  {id:5,name:"Month 05", value:"M05"},
  {id:6,name:"Month 06", value:"M06"},
  {id:7,name:"Month 07", value:"M07"},
  {id:8,name:"Month 08", value:"M08"},
  {id:9,name:"Month 09", value:"M09"},
  {id:10,name:"Month 10", value:"M10"},
  {id:11,name:"Month 11", value:"M11"},
  {id:12,name:"Month 12", value:"M12"},
  {id:13,name:"Month 13", value:"M13"},
  {id:14,name:"Month 14", value:"M14"},
  {id:15,name:"Month 15", value:"M15"},
  {id:16,name:"Month 16", value:"M16"},
  {id:17,name:"Month 17", value:"M17"},
  {id:18,name:"Month 18", value:"M18"},
  {id:19,name:"Month 19", value:"M19"},
  {id:20,name:"Month 20", value:"M20"},
  {id:21,name:"Month 21", value:"M21"},
  {id:22,name:"Month 22", value:"M22"},
  {id:23,name:"Month 23", value:"M23"},
  {id:24,name:"Month 24", value:"M24"},
  {id:25,name:"Month 25", value:"M25"},
  {id:26,name:"Month 26", value:"M26"},
  {id:27,name:"Month 27", value:"M27"},
  {id:28,name:"Month 28", value:"M28"},
  {id:29,name:"Month 29", value:"M29"},
  {id:30,name:"Month 30", value:"M30"},
  {id:31,name:"Month 31", value:"M31"},
  {id:32,name:"Month 32", value:"M32"},
  {id:33,name:"Month 33", value:"M33"},
  {id:34,name:"Month 34", value:"M34"},
  {id:35,name:"Month 35", value:"M35"},
  {id:36,name:"Month 36", value:"M36"},
  {id:37,name:"Month 37", value:"M37"},
  {id:38,name:"Month 38", value:"M38"},
  {id:39,name:"Month 39", value:"M39"},
  {id:40,name:"Month 40", value:"M40"},
  {id:41,name:"Month 41", value:"M41"},
  {id:42,name:"Month 42", value:"M42"},
  {id:43,name:"Month 43", value:"M43"},
  {id:44,name:"Month 44", value:"M44"},
  {id:45,name:"Month 45", value:"M45"},
  {id:46,name:"Month 46", value:"M46"},
  {id:47,name:"Month 47", value:"M47"},
  {id:48,name:"Month 48", value:"M48"},
  {id:49,name:"Month 49", value:"M49"},
  {id:50,name:"Month 50", value:"M50"},
  {id:51,name:"Month 51", value:"M51"},
  {id:52,name:"Month 52", value:"M52"},
  {id:53,name:"Month 53", value:"M53"},
  {id:54,name:"Month 54", value:"M54"},
  {id:55,name:"Month 55", value:"M55"},
  {id:56,name:"Month 56", value:"M56"},
  {id:57,name:"Month 57", value:"M57"},
  {id:58,name:"Month 58", value:"M58"},
  {id:59,name:"Month 59", value:"M59"},
  {id:60,name:"Month 60", value:"M60"},
  {id:61,name:"Month 61", value:"M61"},
  {id:62,name:"Month 62", value:"M62"},
  {id:63,name:"Month 63", value:"M63"},
  {id:64,name:"Month 64", value:"M64"},
  {id:65,name:"Month 65", value:"M65"},
  {id:66,name:"Month 66", value:"M66"},
  {id:67,name:"Month 67", value:"M67"},
  {id:68,name:"Month 68", value:"M68"},
  {id:69,name:"Month 69", value:"M69"},
  {id:70,name:"Month 70", value:"M70"},
  {id:71,name:"Month 71", value:"M71"},
  {id:72,name:"Month 72", value:"M72"}
];


          