import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Child } from '../../../models/types';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { ChildListDetailsComponent } from "../child-list-details/child-list-details.component"
import { MatMenuTrigger } from '@angular/material/menu';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-child-details',
  templateUrl: './child-details.component.html',
  styleUrls: ['./child-details.component.scss']
})
export class ChildDetailsComponent implements OnInit {
  @Input() child: Child[];
  familyDetails:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;

  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;

  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['FirstName', 'Gender','Status', 'menu'];
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  constructor(private _helper: HelperService,private _apollo: Apollo, private _model: MatDialog) {   }

  ngOnInit(): void {
    if(this.child){
      const ELEMENT_DATA:any = this.child.map((child: Child,index) => ({
        index:index,
        id: child.id,
        firstName: child.firstname ,
        lastName: child.lastname,
        gender: child.gender,
        Status: child.status,
      }));
      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    }
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex
  }

  viewDetails(index){
    const dialogRef = this._model.open(ChildListDetailsComponent, {width:"800px", restoreFocus: false,disableClose: true});
    (<ChildListDetailsComponent>dialogRef.componentInstance).childData = this.child[index];
    dialogRef.afterClosed().subscribe(() => this.menuTrigger.focus());
  }
}
