<div class="profile-box info-box general" fxLayout="column">
    <mat-card class="case-document">
      <div *ngIf="members?.length; then MEMBERS; else NO_MEMBERS"></div>
  
      <ng-template #NO_MEMBERS>
        <span>No Members Found</span>
      </ng-template>
  
      <ng-template #MEMBERS>
        <div class="content-card" style="overflow-y: scroll">
            <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
                <ng-container matColumnDef="FirstName">
                    <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
                    <mat-cell  *matCellDef="let u">
                        <p class="text-truncate">{{u.firstName}} </p>
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="LastName">
                    <mat-header-cell *matHeaderCellDef>Last Name </mat-header-cell>
                    <mat-cell  *matCellDef="let u">
                        <p class="text-truncate">{{u.lastName}} </p>
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="Email">
                    <mat-header-cell *matHeaderCellDef>Email Id</mat-header-cell>
                    <mat-cell *matCellDef="let u">
                        <p class="text-truncate">{{u.email}} </p>
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="Status">
                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                    <mat-cell *matCellDef="let u">
                        <p class="text-truncate">
                            {{u.Status == '1' ? 'Active' : 'Inactive'}}
                        </p>
                    </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="menu">
                    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                    <mat-cell *matCellDef="let u">
                        <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ id: u.id, email: u.email}" aria-label="Menu" >
                            <mat-icon>more_vert</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                
                <mat-menu #menu="matMenu">
                    <ng-template matMenuContent let-id="id" let-email="email">
                        <button mat-menu-item (click) = "passwordReset(email)">
                            <mat-icon>lock</mat-icon>
                            <span>Reset Password</span>
                        </button>
                    </ng-template>
                </mat-menu>
                
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                
                <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
            </mat-table>
            
            <mat-paginator #paginator [length]="members.length" [pageSize]="pSize"  [pageSizeOptions]="dataSize" [pageIndex]="0" (click)="changePage($event)"></mat-paginator>
        </div>
      </ng-template>
    </mat-card>
  </div>
  