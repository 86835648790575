import { Component, OnInit } from '@angular/core';
import { HelperService } from 'app/services/helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Apollo, gql } from "apollo-angular";
import { BlogQuery } from '../../../models/types';

@Component({
  selector: 'app-view-blog-details',
  templateUrl: './view-blog-details.component.html',
  styleUrls: ['./view-blog-details.component.scss']
})
export class ViewBlogDetailsComponent implements OnInit {

  blogId: any;
  page: any;
  blogDetails:any;
  constructor(private _helper: HelperService,private _apollo: Apollo,private sanitizer: DomSanitizer) { 
    if(!history || !history.state || !history.state.blogId ||  (!history.state.page && history.state.page <= -1)){
      this._helper.goToPage('view-blogs');
    }
    this.page = history.state.page;
    this.blogId = history.state.blogId;
    if(this.blogId ){
      this.getBlogDetails();
    }
  }

  ngOnInit(): void {
  }
  getBlogDetails() {
    this._apollo.watchQuery<BlogQuery>({
      query: gql`
          query learnDetails($id:ID!){
            learnDetails(id:$id){
              id
              caption
              thumb
              media
              mediaType
              description
              milestone
              flag
              tag
              status
              metaDescription
              metaTag
            }
          }`,
          variables: {id:this.blogId},
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.learnDetails)).subscribe((result) => {
      if(result){
        this.blogDetails = result;
      }else{
        this._helper.showToast(4,'Something Went Wrong.. Try Again');
      }
    });
  }

  viewBlogs(){    
    this._helper.goToPage('view-blogs',{state: {page:this.page}});
  }
}
