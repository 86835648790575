<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
  
    <div class="center">
      <div
        class="header accent"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between center"
      >
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16"> school </mat-icon>
          <span class="logo-text h1"> Tickets</span>
        </div>
  
        <!-- SEARCH -->
        <app-search-bar placeholder="Search for a Contact" (searchEvent)="searchContact($event)"> </app-search-bar>
        
      </div>
  
      <div class="content-card" style="overflow-y: scroll">
        <h1 class="err-msg" *ngIf="!contacts" >No Data Found</h1>
  
        <div *ngIf="contacts">
          <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <p class="text-truncate">{{u.firstname}} {{u.lastname}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef>Email Id</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <p class="text-truncate"  matTooltip="{{u.emailId}}"  [matTooltipPosition]="'above'">{{u.emailId}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="subject">
              <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
              <mat-cell  *matCellDef="let u">
                <p class="text-truncate"  matTooltip="{{u.subject}}"  [matTooltipPosition]="'above'">{{u.subject}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="message">
              <mat-header-cell *matHeaderCellDef>Message</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <p class="text-truncate"  matTooltip="{{u.message}}"  [matTooltipPosition]="'above'">{{(u.message)?u.message:' -- '}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Attachment">
              <mat-header-cell *matHeaderCellDef>Attachment</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <p class="text-truncate" *ngIf="!u.attachment"> -- </p>
                <p class="text-truncate" *ngIf="u.attachment">
                  <a (click)="openImage(u.attachment)" >Show Image</a>
              </p>
              </mat-cell>
            </ng-container>
            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
              <mat-cell *matCellDef="let u">
                    <div [ngSwitch]="u.status">
                        <div *ngSwitchCase="'1'">Open</div>
                        <div *ngSwitchCase="'2'">Processing</div>
                        <div *ngSwitchCase="'3'">Resolved</div>
                    </div>
              </mat-cell>
            </ng-container>
  
            <!-- Controls Column -->
            <ng-container matColumnDef="menu">
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{ id: u.id, status: u.status}"
                  aria-label="Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
  
            <mat-menu #menu="matMenu">
              <ng-template matMenuContent let-id="id" let-status="status">
                <button mat-menu-item (click)="changeStatus(id,2,status)" *ngIf="status == 1">
                  <mat-icon>update</mat-icon>
                  <span>Processing</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,3,status)" >
                  <mat-icon>{{(status == '1' || status == '2')? 'block' : 'done'}}</mat-icon>
                  <span>{{(status == '1' || status == '2')? 'Resolve' : 'Resolved'}}</span>
                </button>
              </ng-template>
            </mat-menu>
  
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  
            <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
          </mat-table>
  
          <mat-paginator
            #paginator
            [length]="contacts.totalItems"
            [pageSize]="pSize"
            [pageSizeOptions]="dataSize"
            [pageIndex]="contacts.currentPage"
            (page)="changePage($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  