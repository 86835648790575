<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
  
    <div class="center">
      <div
        class="header accent"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between center"
      >
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>track_changes</mat-icon>
          <span class="logo-text h1"> {{ trackerName }} </span>
        </div>
      
        <!-- SEARCH -->
        <app-search-bar placeholder="Search for a milestone" (searchEvent)="searchMilestone($event)"> </app-search-bar>
        <button mat-raised-button [routerLink]="'/admin/trackers'"><mat-icon>arrow_back</mat-icon>Back</button>
        
        <!-- <button mat-raised-button [routerLink]="'/admin/add-area'"><mat-icon>add</mat-icon> Add Area</button> -->
      </div>

       <div style="margin-bottom: 20px;">
        <app-add-tracker-milestone [trackerId]="trackerId"  [milestoneMonth]="milestoneMonth"(selectedMonthChange)="handleSelectedMonthChange($event)"  (milestoneAdded)="onMilestoneAdded($event)"></app-add-tracker-milestone>
      </div>  
  
       <div class="content-card" style="overflow-y: scroll">
        <div  *ngIf="totalItems ===0">
          <h1 class="err-msg">No  Milestones Found</h1>
          <h1 class="err-msg" *ngIf="totalItems === undefined">No Tracks Found</h1>
         </div> 
      
  
         <div  *ngIf="totalItems > 0">
          <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
          
             <ng-container matColumnDef="MileStone">
              <mat-header-cell *matHeaderCellDef>Milestones</mat-header-cell>
              <mat-cell *matCellDef="let u">{{u.milestone}}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="Icon">
              <mat-header-cell *matHeaderCellDef>Icon</mat-header-cell>
              <mat-cell *matCellDef="let u" >
                <p class="text-truncate">
                  <img [src]="u.icon" alt="Tracker Icon" style="max-width: 50px; max-height: 50px;" />
                </p>
              </mat-cell>
            </ng-container>

           
  
            <ng-container matColumnDef="menu" >
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <button 
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{ id: u.id, index: u.index , status:u.status}"
                  aria-label="Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </mat-cell>
            </ng-container>


            <mat-menu #menu="matMenu" >
              <ng-template matMenuContent  let-id="id" let-index="index" let-status ="status">
                <button mat-menu-item (click)="editTrack(id)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit Milestone</span>
                </button>
                <button mat-menu-item  (click)="deleteItem(id)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,status)">
                  <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                  <span>{{status == '1'? 'Inactive' : 'Active'}}</span>
                </button>
              </ng-template>
            </mat-menu>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
          </mat-table>
  
          <!-- <mat-paginator
            #paginator
            [length]="areas.totalItems"
            [pageSize]="pSize"
            [pageSizeOptions]="dataSize"
            [pageIndex]="areas.currentPage"
            (page)="changePage($event)"
            >
          </mat-paginator> -->
        </div> 
      </div>
    </div>
  </div>