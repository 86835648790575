<div id="agents" class="page-layout carded fullwidth">
  <!-- <div class="top-bg accent"></div> -->
  <div class="center">
    
    <!-- <div class="header" style="height: ;">
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16"> map </mat-icon>
        <span class="logo-text h1">Milestone - {{milestoneId}}</span>
      </div>
      
      
    </div> -->
    <div class="action-box">
      <button mat-raised-button style="background-color: #3f51b5; color: white;" *ngIf="this.userRoles && this.userRoles.includes(21)"(click)="assignActivities()" class="assignBtn"><mat-icon>assignment</mat-icon> Assign Activities</button>
    </div>
    
    <div class="content-card" style="overflow-y: scroll">
      <h1 class="err-msg" *ngIf="!milestones">No Activities Found</h1>
      
      <div *ngIf="milestones">
        <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
          <!-- Name Column -->
          <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef>Activity</mat-header-cell>
            <mat-cell  *matCellDef="let u">
              <p class="text-truncate" matTooltip="{{u.activityName}}"  [matTooltipPosition]="'above'">{{u.activityName}} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Skill">
            <mat-header-cell *matHeaderCellDef>Skill</mat-header-cell>
            <mat-cell  *matCellDef="let u">
              <p class="text-truncate" matTooltip="{{u.SkillName}}"  [matTooltipPosition]="'above'">{{u.SkillName}} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Area">
            <mat-header-cell *matHeaderCellDef>Area</mat-header-cell>
            <mat-cell  *matCellDef="let u">
              <p class="text-truncate" matTooltip="{{u.AreaName}}"  [matTooltipPosition]="'above'">{{u.AreaName}} </p>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="IndicatorSkill">
            <mat-header-cell *matHeaderCellDef>Indicator Skill</mat-header-cell>
            <mat-cell  *matCellDef="let u">
              <p class="text-truncate" matTooltip="{{u.indicatorSkill}}"  [matTooltipPosition]="'above'">{{u.indicatorSkill}} </p>
            </mat-cell>
          </ng-container>
          
          <!-- Status Column -->
          <ng-container matColumnDef="Status">
            <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
            <mat-cell  *matCellDef="let u">
              <p class="text-truncate">{{u.Status == '1' ? 'Active':'Inactive'}}</p>
            </mat-cell>
          </ng-container>
          
          <!-- Controls Column -->
          <ng-container matColumnDef="menu">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let u">
              <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ id: u.id, activityId: u.activityId,status:u.Status}" aria-label="Menu" >
                <mat-icon>more_vert</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          
          <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-id="id" let-activityId="activityId" let-status="status">
              <button mat-menu-item (click)="changeStatus(id,status,activityId)" *ngIf="this.userRoles && this.userRoles.includes(33)">
                <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                <span>{{status == '1'? 'Disable' : 'Enable'}}</span>
              </button>
              <button mat-menu-item  (click)="changeStatus(id,0,activityId)" *ngIf="this.userRoles && this.userRoles.includes(34)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </ng-template>
          </mat-menu>
          
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          
          <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
        </mat-table>
        
        <mat-paginator #paginator [length]="milestones.totalItems" [pageSize]="pSize" [pageSizeOptions]="dataSize" [pageIndex]="milestones.currentPage" (page)="changePage($event)" > </mat-paginator>
      </div>
    </div>
  </div>
</div>