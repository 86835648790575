import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div class="loader_common_main_wrapper" *ngIf="loading">
      <div class="loader"></div>
    </div>
  `,

  styles: [``],
})
export class LoaderComponent implements OnInit {
  @Input() loading: boolean;
  constructor() {}

  ngOnInit(): void {}
}
