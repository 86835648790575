import { Component, Inject, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { map } from 'rxjs/operators';
import { familyQuery, newsFeed } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
import { NewsfeeddetailsComponent } from "../newsfeeddetails/newsfeeddetails.component";
@Component({
  selector: 'app-newsfeeds',
  templateUrl: './newsfeeds.component.html',
  styleUrls: ['./newsfeeds.component.scss']
})
export class NewsfeedsComponent implements OnInit {
  @Input() familyId: any;
  loading: boolean = false;
  familyNewsFeeds:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;

  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['caption', 'CareGiver','Activity','postedBy','Status'];
  constructor(private _helper: HelperService,private _apollo: Apollo, private _model: MatDialog) {

   }
  ngOnInit(): void {
    if(this.familyId){
      this.getNewsFeed();
    }
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;

    this.getNewsFeed();
  }
  getNewsFeed(){
    const pageindex = `${this.pIndex ? `${this.pIndex}` : ''}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this._apollo.watchQuery<familyQuery>({
      query: gql`
          query newsFeedList($familyId: Int,$search: String,$type: String,$page: Int,$size: Int){
            newsFeedList(familyId:$familyId,search:$search,type: $type,page: $page,size: $size){
              totalItems
              data{
                id
                caption
                type
                media
                mediaType
                activityName
                postedBy
                careGiver{
                  emailId
                  firstname
                  lastname
                }
                familyId
                status  
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex),
            familyId: parseInt(this.familyId),
            size: parseInt(pagesize),
            search:search,
            type:""
          },
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.newsFeedList)).subscribe((result) => {
      if (result.totalItems) {
        const ELEMENT_DATA:any = result.data.map((news: newsFeed,index) => ({
          index: index,
          id: news.id,
          caption: news.caption,
          postedBy: news.postedBy,
          activity: news.activityName,
          careGiver: news.careGiver.firstname,
          Status: news.status,
        }));
        this.dataSource = new MatTableDataSource<familyQuery>(ELEMENT_DATA);
        this.familyNewsFeeds = result;
      } else {
        // this._helper.showToast(4,'No Data Found');
      }
    });

  }

  viewDetails(index){
    const dialogRef = this._model.open(NewsfeeddetailsComponent, {width:"800px", restoreFocus: false,disableClose: true});
    (<NewsfeeddetailsComponent>dialogRef.componentInstance).newsData = this.familyNewsFeeds.data[index];
    dialogRef.afterClosed().subscribe();
  }
}
