export const getLocalStorageItem = (id: string): any => JSON.parse(localStorage.getItem(id));
export const getLocalStorageRoleItem = (data: string): any => JSON.parse(localStorage.getItem(data));
export const setLocalStorageItem = (id: string, data: any): void => localStorage.setItem(id, JSON.stringify(data));
export const delLocalStorageItem = (id: string): void => localStorage.removeItem(id);


export const getToken = () => getLocalStorageItem(AUTH_TOKEN);

export const getFileIcon = (type: string): string => {
  // type can be material icons like: image, pdf, word, file, ondemand_video
  const fileType = type.split('/')[1];

  switch (fileType) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'ico':
      return 'image';

    case 'mp4':
      return 'ondemand_video';

    case 'doc':
    case 'msword':
      return 'note';

    case 'pdf':
      return 'picture_as_pdf';

    default:
      return 'insert_drive_file';
  }
};

/* ---------------------------- LOCALSTORAGE KEYS --------------------------- */

export const AUTH_TOKEN = 'AUTH_TOKEN';
export const USER_ID = 'USER_ID';
export const ROLES = 'ROLES';
