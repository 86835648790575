import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Activity, ActivityQuery,AreaQuery,updateActivity } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-view-activities',
  templateUrl: './view-activities.component.html',
  styleUrls: ['./view-activities.component.scss']
})
export class ViewActivitiesComponent implements OnInit {
  loading: boolean = false;
  activities:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;

  getActivityQuery:QueryRef<any>;
  private querySubscription: Subscription;
  currentPage: number;
  dataSize: number[] = [10, 50, 100];

  dataSource: any;
  displayedColumns: string[] = ['Name','Area', 'Skill','IndicatorSkill','Status'];
  areas: any;
  selectedAreaId: any;
  skills: any;
  selectedSkillId: any;
  constructor(private _helper: HelperService,private _apollo: Apollo, private _model: MatDialog) { 
    if(history && history.state && history.state.page){
      this.pIndex = history.state.page;
    }
    if(this.userRoles && (this.userRoles.includes(3) || this.userRoles.includes(41) || this.userRoles.includes(42))){
      this.displayedColumns.push('menu')
    }
    
    this.getAreas();
    this.getActivities();
  }

  ngOnInit(): void {
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;

    this.getActivities();
  }
  searchActivity(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getActivities();
  }
  getAreas() {
    this._apollo.watchQuery<AreaQuery>({
      query: gql`
      query areaActiveList($search: String){
        areaActiveList(search: $search){
          id
          icon
          areaName
          status
        }
      }`,variables:{search:''},
      
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.areaActiveList)).subscribe((result) => {
      this.areas = result;
      this.getActivities();
    })
  }
  selectArea(event){
    this.selectedAreaId = (event)?event.value.id:'';
    this._apollo.watchQuery<AreaQuery>({
      query: gql`
      query areaIdBasedskillActive($areaId: Int){
        areaIdBasedskillActive(areaId: $areaId){
          id
          skill
          status
        }
      }`,
      variables: {areaId: parseInt(this.selectedAreaId)},
    }).valueChanges.pipe(map(result => result.data.areaIdBasedskillActive)).subscribe((result) => {
      if(result){
        this.pIndex = 0;
        this.skills = result;
      }
    });
  }
  selectSkill(event){
    this.pIndex = 0;
    this.selectedSkillId = (event)?event.value.id:'';
    this.getActivities();
  }
  getActivities() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : ''}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getActivityQuery = this._apollo.watchQuery<ActivityQuery>({
      query: gql`
          query activityList($page: Int, $size: Int, $search: String){
            activityList(page: $page, size: $size, search: $search){
              totalItems
              data{
                id
                activityName
                area{
                  areaName
                }
                skills{
                  skill
                }
                indSkill
                status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex),
            size: parseInt(pagesize),
            search: search
          },
          fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getActivityQuery.valueChanges.pipe(map(result => result.data.activityList)).subscribe((result) => {
      if (result.totalItems) {
        const ELEMENT_DATA:any = result.data.map((act: Activity) => ({
          id: act.id,
          activityName: act.activityName,
          skillName: (act.skills && act.skills.skill)?act.skills.skill:' -- ',
          areaName: (act.area && act.area.areaName)?act.area.areaName:' -- ',
          indSkill: act.indSkill,
          Status: act.status,
        }));
        this.dataSource = new MatTableDataSource<ActivityQuery>(ELEMENT_DATA);
        this.activities = result;
      } else {
        this._helper.showToast(4,'No Data Found');
      }
    });

  }
  editActivity(activityId){
    this._helper.goToPage('edit-activity',{state: {activityId}});
  }
  viewDetails(activityId){
    this._helper.goToPage('view-activity-details',{state: {activityId:activityId,page:this.pIndex}});
  }

  changeStatus(id,status){
    let newStatus = (status == 0) ? 0 : ((parseInt(status) === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation updateActivity($id: ID!,$status: Int){
          updateActivity(id:$id,status:$status)
        }`,
      variables: {id: parseInt(id),status:newStatus},
    }).subscribe(
      ({ data }: updateActivity) => {
        if(data.updateActivity[0] == true){
          this.getActivityQuery.refetch();
          this._helper.showToast(1,(status == 0)?'Activity Deleted Successfully':(status == 1)?'Activity Disabled Successfully':'Activity Activated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
