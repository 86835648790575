import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { map } from 'rxjs/operators';
import { familyGroup, familyQuery } from '../../../models/types';
@Component({
  selector: 'app-about-family',
  templateUrl: './about-family.component.html',
  styleUrls: ['./about-family.component.scss']
})
export class AboutFamilyComponent implements OnInit {
  familyId:any;
  familyDetails:any;
  page:any;
  constructor(private _helper: HelperService,private _apollo: Apollo) {
    
    if(!history || !history.state || !history.state.familyId || (!history.state.page && history.state.page <= -1)){
      this._helper.goToPage('view-family');
    }
    this.page = history.state.page;
    this.familyId = history.state.familyId;
    if(this.familyId ){
      this.getFamilyDetails();
    }
   }

  ngOnInit(): void {
  }

  getFamilyDetails(){
    this._apollo.watchQuery<familyQuery>({
      query: gql`
          query familyDetailedList($familyId: Int){
            familyDetailedList(familyId: $familyId){
              familyGroup{
                id
                familyName
                createdAt
                status
                careGiver{
                  id
                  firstname
                  lastname
                  emailId
                }
              }
              membersCount
              familyMembers{
                id
                careGiverId
                careGiver{
                  id
                  firstname
                  lastname
                  emailId
                  status
                }
              }
              child{
                id
                firstname
                lastname
                gender
                profilePic
                isDefault
                dob
                height
                weight
                isPremature
                status
              }
              childCount
            }
          }`,
          variables: {
            familyId: parseInt(this.familyId)
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.familyDetailedList)).subscribe((result) => {
      if (result) {
        this.familyDetails = result;
      } else {
        this._helper.showToast(4,'No Data Found');
      }
    });
  }

  onTabChanged(event: MatTabChangeEvent) {
    switch (event.index) {
      case 0:
        // this.getUserDetails();
        break;

      case 1:
        break;

      case 2:
        break;
    }
  }

  viewFamily(){    
    this._helper.goToPage('view-family',{state: {page:this.page}});
  }
}
