<div id="agents" class="page-layout carded fullwidth">
  <div class="top-bg accent"></div>

  <div class="center">
    <div
      class="header accent"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center"
    >
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16"> supervised_user_circle </mat-icon>
        <span class="logo-text h1"> Roles List</span>
      </div>

      <app-search-bar placeholder="Search for a Role" (searchEvent)="searchRoles($event)"> </app-search-bar>
      
      <button mat-raised-button (click)="openAddRole()"><mat-icon>add</mat-icon> Add Roles</button>
    </div>

    <div class="content-card" style="overflow-y: scroll">
      <h1 class="err-msg" *ngIf="!roles" (click)="getRoles()">No Roles Found</h1>

      <div >
        <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
          <!-- Name Column -->
          <ng-container matColumnDef="RoleName">
            <mat-header-cell *matHeaderCellDef>Role Name</mat-header-cell>
            <mat-cell [routerLink]="'/admin/roles'" *matCellDef="let u">
              <p class="text-truncate">{{u.RoleName}} </p>
            </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="Status">
            <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
            <mat-cell [routerLink]="'/admin/roles'" *matCellDef="let u">
              <p class="text-truncate">{{u.Status == '1' ? 'Active':'Inactive'}}</p>
            </mat-cell>
          </ng-container>

          <!-- Controls Column -->
          <ng-container matColumnDef="menu">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let u">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{ id: u.id, name: u.RoleName,status: u.Status}"
                aria-label="Menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-id="id" let-name="name" let-status="status">
              <button mat-menu-item (click)="editPopup(content,index,id)" *ngIf="this.userRoles && this.userRoles.includes(19)">
                <mat-icon>edit</mat-icon>
                <span>Edit Role</span>
              </button>
              <button mat-menu-item (click)="changeStatus(id,status)" *ngIf="this.userRoles && this.userRoles.includes(45) ">
                <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                <span>{{status == '1'? 'Inactive' : 'Active'}}</span>
              </button>
              <button mat-menu-item (click)="changeStatus(id,0)" *ngIf="this.userRoles && this.userRoles.includes(46) ">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
              <button mat-menu-item  (click)="permissions(id,name)" *ngIf="this.userRoles && this.userRoles.includes(29)">
                <mat-icon>lock</mat-icon>
                <span>Permissions</span>
              </button>
              
            </ng-template>
          </mat-menu>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

          <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
        </mat-table>

        <mat-paginator
          #paginator
          [length]="count"
          [pageSize]="pSize"
          [pageSizeOptions]="dataSize"
          [pageIndex]="pIndex"
          (page)="changePage($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-content my_modal_c">
      <div class="modal-body">
        <mat-card style= "height:400px;overflow: scroll;">
          <mat-card-header style="display: block; margin-bottom: 10px">
            <mat-card-title>Edit Role</mat-card-title>
            <br />
          </mat-card-header>
        
          <mat-card-content>
            <form name="roleForm" [formGroup]="roleForm" >
              <!-- Cause Name -->
              <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                <mat-label>Role Name</mat-label>
                <input matInput formControlName="rolename" type="text" placeholder="Role Name" />
                <mat-error *ngIf="!roleForm.hasError('removeSpaces') || roleForm.controls['rolename'].hasError('required')">Name is Required </mat-error>
              </mat-form-field>
      
              <mat-radio-group aria-label="Select an option" formControlName="status" *ngIf="this.userRoles && (this.userRoles.includes(45) || this.userRoles.includes(46))">
                <mat-radio-button *ngIf="this.userRoles.includes(45)" [value]="1">Active</mat-radio-button>
                <mat-radio-button *ngIf="this.userRoles.includes(45)" [value]="2">Inactive</mat-radio-button>
                <mat-radio-button *ngIf="this.userRoles.includes(46)" [value]="0">Delete</mat-radio-button>
            </mat-radio-group>
                <mat-error *ngIf="roleForm.controls['status'].hasError('required')">Status is Required </mat-error>
<br/>
<br/>
                <button mat-raised-button color="primary"  (click)="editRole()" [disabled]="roleForm.invalid" >
                  <span>Update</span>
                </button>
                <button mat-raised-button style="margin-left: 20px;" (click)="closeModel()">Cancel</button>
            </form>
          </mat-card-content>
        </mat-card>
        
      </div>
      <div class="modal-footer"></div>
  </div>
</ng-template>
