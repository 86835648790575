import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function removeSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      control &&
      control.value &&
      (/\s/.test(control.value.charAt(0)) || /\s/.test(control.value.charAt(control.value.length - 1)))
    ) {
      return { removeSpaces: { value: control.value } };
      // return { removeSpaces: true };
    }
    return null;
  };
}
