<div id="agents" class="page-layout carded fullwidth">
  <div class="top-bg accent"></div>
  
  <div class="center">
    <div
    class="header accent"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center"
    >
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="logo-icon s-32 mr-16"> supervised_user_circle </mat-icon>
      <span class="logo-text h1"> Users List</span>
    </div>
    
    <!-- SEARCH -->
    <app-search-bar placeholder="Search for a user" (searchEvent)="searchUsers($event)"> </app-search-bar>
    <button *ngIf="this.userRoles && this.userRoles.includes(13)" mat-raised-button [routerLink]="'/admin/add-user'"><mat-icon>add</mat-icon> Add User</button>
  </div>
  
  <div class="content-card" style="overflow-y: scroll">
    <h1 class="err-msg" *ngIf="!userData" (click)="getUsers()">No Users Found</h1>
    
    <div *ngIf="userData">
      <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
        <!-- Name Column -->
        <ng-container matColumnDef="FirstName">
          <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
          <mat-cell [routerLink]="'/admin/users'" *matCellDef="let u">
            <p class="text-truncate">{{u.FirstName}} </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="LastName">
          <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
          <mat-cell [routerLink]="'/admin/users'" *matCellDef="let u">
            <p class="text-truncate">{{u.LastName}} </p>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Role">
          <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
          <mat-cell [routerLink]="'/admin/users'" *matCellDef="let u">
            <p class="text-truncate">{{u.Role}} </p>
          </mat-cell>
        </ng-container>
        
        <!-- Phone Column -->
        <!-- <ng-container matColumnDef="Username">
          <mat-header-cell *matHeaderCellDef>Username</mat-header-cell>
          <mat-cell [routerLink]="'/admin/users'" *matCellDef="let u">
            <p class="text-truncate">{{u.Username}} </p>
          </mat-cell>
        </ng-container> -->
        
        <!-- Email ID Column -->
        <ng-container matColumnDef="Email">
          <mat-header-cell *matHeaderCellDef>Email ID</mat-header-cell>
          <mat-cell *matCellDef="let u">
            <p class="text-truncate">{{u.Email}}</p>
          </mat-cell>
        </ng-container>
        
        <!-- Status Column -->
        <ng-container matColumnDef="Status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell [routerLink]="'/admin/users'" *matCellDef="let u">
            <p class="text-truncate">
              {{u.Status == '1' ? 'Active':'Inactive'}}
            </p>
          </mat-cell>
        </ng-container>
        
        <!-- Controls Column -->
        <ng-container matColumnDef="menu">
          <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell *matCellDef="let u">
            <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{ id: u.id, index: u.index,status:u.Status}"
            aria-label="Menu"
            >
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      
      <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-id="id" let-index = index let-status = status>
          <button mat-menu-item (click)="editProfile(id)" *ngIf="this.userRoles && this.userRoles.includes(14) ">
            <mat-icon>edit</mat-icon>
            <span>Edit User</span>
          </button>
          <button mat-menu-item (click)="changeStatus(id,status)" *ngIf="this.userRoles && this.userRoles.includes(49) ">
            <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
            <span>{{status == '1'? 'Inactive' : 'Active'}}</span>
          </button>
          <button mat-menu-item (click)="changeStatus(id,0)" *ngIf="this.userRoles && this.userRoles.includes(50) ">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
          <button mat-menu-item  (click)="passwordReset(index)" *ngIf="this.userRoles && this.userRoles.includes(28) ">
            <mat-icon>lock</mat-icon>
            <span>Reset Password</span>
          </button>

        </ng-template>
      </mat-menu>
      
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      
      <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
    </mat-table>
    
    <mat-paginator
    #paginator
    [length]="userData.totalItems"
    [pageSize]="pSize"
    [pageSizeOptions]="dataSize"
    [pageIndex]="userData.currentPage"
    (page)="changePage($event)"
    >
  </mat-paginator>
</div>
</div>
</div>
</div>