import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { SpinnerService } from '../../../../shared/spinner.service';
import { Observable } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { Pipe, PipeTransform } from '@angular/core';
import { VideoProcessingService } from 'app/services/video-processing-service';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
@Pipe({
  name: 'removeWhiteSpace'
})
export class RemoveWhiteSpacePipe implements PipeTransform {

  transform(value: any): any {
    if (value === undefined)
      return 'undefined';
    return value.replace(/\s/g, "");
  }

}
// export function removeSpaces(control: AbstractControl) {
//   if (control && control.value && !control.value.replace(/\s/g, '').length) {
//     control.setValue('');
//   }
//   return null;
// }


@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss']
})
export class AddPostComponent implements OnInit {
  postForm:FormGroup; 
  postFormSubmit:boolean;
  filename: string = null;
  imageUrl: any;
  downloadURL: Observable<string>;
  imageData: File;
  videoUrl:any;
  imageType:any;
  videothumbnailData:any;
  imageIcontoConvert:any;
  imageIcontoConvertUrl:any;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      minHeight: '10rem',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: []
};
  constructor(private videoService: VideoProcessingService,private sanitizer: DomSanitizer,private _helper: HelperService,private storage: AngularFireStorage,private spinnerService: SpinnerService,private _apollo: Apollo){}

  ngOnInit(): void {
    this.postForm = new FormGroup({
      caption: new FormControl('', [Validators.required,removeSpacesValidator()]),
      postIcon: new FormControl('', [Validators.required]),
      // duration: new FormControl('', [Validators.required,removeSpacesValidator()]),
      description: new FormControl('', [Validators.required,removeSpacesValidator()]),
    });
  }
  getVideoImage(){
    this.videoService.promptForVideo().then(videoFile => {
      let type = videoFile.type.split("/",1);
      if(type[0] == 'image'){
        return this.videoService.onImageFileChanged(videoFile)
      }else{
        return this.videoService.generateThumbnail(videoFile);
      }
    }).then(result => {  
      if(result && result.status == true){
        this.postFormSubmit = false;
        this.videoUrl = (result.imageUrl)?false:this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result.imageData ))
        this.imageUrl = (result.imageUrl)?result.imageUrl:false;
        this.imageData = result.imageData;
        this.filename = result.filename;
        this.imageIcontoConvert = result.url;
        this.imageType = (this.imageIcontoConvert.split(";")[0].split(":"));
      }
    })
  }
  getDuration(e) {    
    if(e.target.duration > 60){
      this.postFormSubmit = true;
      this._helper.showToast(4,'Less Than 1 Min Video is Required');
    }
  }

  addPost(){
    if(this.postForm.invalid){
      return;
    }
    if(this.postFormSubmit){
      this._helper.showToast(4,'Less Than 1 Min Video is Required');
      return;
    }
    let imgnam = Date.now()+'-'+this.imageData.name;
    const fileRef = this.storage.ref(`/post/images/${imgnam}`);
    this.spinnerService.show();
    this.storage.upload(`/post/images/${imgnam}`, this.imageData).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){
            this._apollo.mutate({
              mutation: gql`
                mutation addadminPost($caption: String,$media: String,
                   $mediaType: String,$description: String,$type: Int){
                    addadminPost(caption: $caption,media: $media,mediaType:$mediaType,description: $description,type: $type){
                    id
                    status
                  }
                }`,
              variables: { 
                caption:this.postForm.value.caption,
                media:url,
                mediaType:(this.imageUrl)?'image':'video',
                description:this.postForm.value.description,
                type:1
              },
            }).subscribe(
              ({ data }: any) => {
                if(data.addadminPost.id){
                  this._helper.showToast(1,'Post Added Successfully');
                  this._helper.goToPage('/admin/view-posts')
                }else{
                  this._helper.showToast(4,'Something Went Wrong.. Try Again');
                }
              },(err) => {
                this._helper.showToast(4,err.message);
              }
            );
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })
    ).subscribe();
  }
  cancel(){
    this._helper.goToPage('/admin/view-posts')
  }
  

}




