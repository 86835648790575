<div id="profile" class="page-layout simple tabbed">
  <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between end">
    <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">
      <div class="name">Child Growth</div>
    </div>
  </div>



  <div class="about-content">
    <div id="about" style="margin-top: 25px;" fxLayout="row wrap" fxLayoutAlign="space-evenly none">


      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">Total no. of Feeding Tracker Data Entries</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ feedingTrackerCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">The Total no.of Health Data Entries</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ healthTrackerCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">Total no. of Development Tracker Data Entries</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ developmentTrackerCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">The Total no.of Sleep Data Entries</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ sleepTrackerCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">Total no. of Growth Tracker Data Entries</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ growthTrackerCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">Total no. of Diaper Tracker Data Entries</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ diaperTrackerCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">Total no. of activity tracker Data Entries</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ activityTrackerCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">Total no.of Immunization Data Entries</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ immunizationTrackerCount }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
      fxFlex.gt-md="20" style="margin: 0 10px;">
      <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
        <header class="accent">
          <div class="title">No Of Children Having Breast Milk Per Day</div>
        </header>
        <div class="content">
          <div class="info-line">
            <div class="info">{{   noOfChildrenHavingBreastMilkPerDay }}</div>
          </div>
        </div>
      </div>
    </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">Least Used Tracker</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ LeastUsedTracker }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">Most Used Tracker</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ MostUsedTracker }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title"> Average Feeding Time for Formula Milk</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ averageformulaMilk }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
        fxFlex.gt-md="20" style="margin: 0 10px;">
        <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
          <header class="accent">
            <div class="title">Average Feeding Time</div>
          </header>
          <div class="content">
            <div class="info-line">
              <div class="info">{{ averageFeedingTime }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
      fxFlex.gt-md="20" style="margin: 0 10px;">
      <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
        <header class="accent">
          <div class="title">Average Breast Feeding Time</div>
        </header>
        <div class="content">
          <div class="info-line">
            <div class="info">{{   averageBreastMilkTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-content" fxLayout="column" fxFlex="20" fxFlex.gt-sm="20"
      fxFlex.gt-md="20" style="margin: 0 10px;">
      <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
        <header class="accent">
          <div class="title">Total no. of CDC Milestones Achieved</div>
        </header>
        <div class="content">
          <div class="info-line">
            <div class="info">{{   noOfGetCDCMilestoneCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="20" fxFlex.gt-md="20" style="margin: 0 10px;">
      <div class="profile-box info-box general" fxLayout="column" style="margin-bottom: 20px;">
        <header class="accent">
          <div class="title">Total no. of CDC Milestones Achieved</div>
        </header>
        <mat-select placeholder="Select Month"  style="margin-left: 10px; margin-right: 30px;padding: 10px;" [(ngModel)]="selectedMonth" (ngModelChange)="onMonthSelected($event)">
          <mat-option *ngFor="let months of months" [value]="months.value">
            {{months.viewValue}}   
          </mat-option>
        </mat-select>
        <div class="content" style="border: 1px solid #ccc; padding: 10px; margin-top: 10px;">
          <div class="info-line" style="background-color: #f0f0f0; padding: 5px;">
            <div class="info" *ngIf="selectedMonth !== null"> For  {{selectedMonth}} : {{selectedMonthCDCCount}}</div>
          </div>
        </div>
      </div>
    </div>
    
    


    </div>
  </div>