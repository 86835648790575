import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Indicator ,IndicatorQuery,updateindicatorSkill} from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
@Component({
  selector: 'app-view-indicator',
  templateUrl: './view-indicator.component.html',
  styleUrls: ['./view-indicator.component.scss']
})
export class ViewIndicatorComponent implements OnInit {
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;
  skillId:any;
  areaId:any;
  skillName:any;
  areaName:any;
  getIndicatorQuery:QueryRef<any>;
  private querySubscription: Subscription;
  currentPage: number;
  dataSize: number[] = [10, 50, 100];
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  dataSource: any;
  displayedColumns: string[] = ['indicatorname','Status'];
  indicators:any;
  constructor(private _helper: HelperService,private _apollo: Apollo) { 
    if(!history || !history.state || !history.state.skillId || !history.state.name || !history.state.areaId || !history.state.areaName){
      this._helper.goToPage('view-area');
    }
    if(this.userRoles && (this.userRoles.includes(40) || this.userRoles.includes(55) || this.userRoles.includes(56))){
      this.displayedColumns.push('menu')
    }
    this.skillId = parseInt(history.state.skillId);
    this.areaId =history.state.areaId;
    this.skillName = history.state.name;
    this.areaName = history.state.areaName;
    this.getIndicators();
  }

  ngOnInit(): void {
  }
  
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;

    this.getIndicators();
  }
  searchIndicator(keyword: string){
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getIndicators();
  }
  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  getIndicators() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : 0}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getIndicatorQuery = this._apollo.watchQuery<IndicatorQuery>({
      query: gql`
          query indicatorSkillList($page: Int, $size: Int, $search: String,$skillId:Int){
            indicatorSkillList(page: $page, size: $size, search: $search,skillId:$skillId){
              totalItems
              data{
                id
                indicatorSkill
                status
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex) ,
            size: parseInt(pagesize),
            search: search,
            skillId:this.skillId
          },
      fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getIndicatorQuery.valueChanges.pipe(map(result => result.data.indicatorSkillList)).subscribe((result) => {
      if(result.totalItems){
        const ELEMENT_DATA:any = result.data.map((indicator: Indicator,index) => ({
          index:index,
          id: indicator.id,
          indicatorName:indicator.indicatorSkill,
          Status: indicator.status,
        }));
        this.dataSource = new MatTableDataSource<IndicatorQuery>(ELEMENT_DATA);
        this.indicators = result;
      }else{
        this._helper.showToast(4,'No Data Found');
      }
    });
  }
  refresh() {
    this.getIndicatorQuery.refetch();
  }

  editIndicator(indicatorId){
    this._helper.goToPage('edit-indicator',{state: {indicatorId:indicatorId,areaId:this.areaId,areaName:this.areaName}});
  }

  getBackToSkill(){
    this._helper.goToPage('view-skills',{state: {areaId:this.areaId,areaname:this.areaName}});
  }
  changeStatus(id,status){
    let newStatus = (status == 0) ? 0 : ((parseInt(status) === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation updateindicatorSkill($id: ID!,$status: Int){
          updateindicatorSkill(id:$id,status:$status)
        }`,
      variables: {id: parseInt(id),status:newStatus},
    }).subscribe(
      ({ data }: updateindicatorSkill) => {
        if(data.updateindicatorSkill[0] == true){
          this.getIndicatorQuery.refetch();
          this._helper.showToast(1,(status == 0)?'Activity Deleted Successfully':(status == 1)?'Activity Disabled Successfully':'Activity Activated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
