<div id="profile" class="page-layout simple tabbed" *ngIf="blogDetails">
    <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end" >
        <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"  fxLayoutAlign.gt-sm="start center">
            <div class="name">{{blogDetails.caption}}</div>
        </div>
        <button mat-raised-button (click)="viewBlogs()"><mat-icon>arrow_back</mat-icon>Back</button>
    </div>
    
    <div class="about-content">
        <mat-card >
            <mat-card-content>
                <div id="about" class="p-24" fxLayout="row wrap">
                    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">
                        <div class="profile-box info-box general" fxLayout="column">
                            <header class="accent">
                                <div class="title">Other Details</div>
                            </header>
                            
                            <div class="content">
                                <div class="info-line">
                                    <div class="title">Description</div>
                                    <div class="info"  [innerHTML]="blogDetails.description"></div>
                                </div>
                                
                                <div class="info-line">
                                    <div class="title">MileStone</div>
                                    <div class="info" style="overflow-wrap: break-word;">{{blogDetails.milestone}}</div>
                                </div>
                                
                                <!-- <div class="info-line">
                                    <div class="title">Duration</div>
                                    <div class="info">{{blogDetails.duration}}</div>
                                </div> -->
                                <div class="info-line">
                                    <div class="title">Tags</div>
                                    <div class="info">{{blogDetails.tag}}</div>
                                </div>  

                                <div class="info-line">
                                    <div class="title">Meta Titile</div>
                                    <div class="info">{{blogDetails.metaTag ? blogDetails.metaTag : ''}}</div>
                                </div> 
                                <div class="info-line">
                                    <div class="title">Meta Description</div>
                                    <div class="info">{{blogDetails.metaDescription ? blogDetails.metaDescription : ''}}</div>
                                </div> 
                                <div class="info-line">
                                    <div class="title">Slug</div>
                                    <div class="info">{{blogDetails.flag ? blogDetails.flag : ''}}</div>
                                </div> 
                               
                                <div class="info-line">
                                    <div class="title">Status</div>
                                    <div class="info">{{blogDetails.status == '1' ? 'Active': 'Inactive'}}</div>
                                </div>  

                                 
                            </div>
                        </div>
                    </div>
                    
                    <div class="about-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="35">
                        <div class="profile-box info-box general" fxLayout="column">
                            <div class="content">
                                <div class="info-line">
                                    <div class="title">Media</div>
                                    <div class="info"></div>
                                </div>
                                <div>
                                    <img *ngIf="blogDetails.mediaType =='image'" [src]="blogDetails.media" onerror="'assets/images/no_image.png';" />
                                    <video *ngIf="blogDetails.mediaType =='video'"   controls [attr.src]="blogDetails.media" style="max-width: -webkit-fill-available;"></video>                    
                                </div>  
                                <div class="info-line">
                                    <div class="title"> </div>
                                    <div class="info"></div>
                                </div>                          
                                <div class="info-line">
                                    <div class="title">Media Icon</div>
                                    <div class="info"></div>
                                </div>
                                <div>
                                    <img [src]="blogDetails.thumb" onerror="'assets/images/no_image.png';" />
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
