import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Blog, BlogQuery,updateLearn } from '../../../models/types';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.scss']
})
export class ViewBlogComponent implements OnInit {
  loading: boolean = false;
  blogs:any;
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;
  getLearnQuery:QueryRef<any>;
  private querySubscription: Subscription;
  currentPage: number;
  dataSize: number[] = [10, 50, 100];
  filter_type:any;
  daterange:any;
  maxDate:any = new Date();
  filterByInp:any;


  dataSource: any;
  displayedColumns: string[] = ['Caption','Milestone','Status','views'];
  constructor(private _helper: HelperService,private _apollo: Apollo, private _model: MatDialog,private datePipe: DatePipe) { 
    if(history && history.state && history.state.page){
      this.pIndex = history.state.page;
    }
    this.getBlogs();
    if(this.userRoles && (this.userRoles.includes(25) || this.userRoles.includes(47) || this.userRoles.includes(48) )){
      this.displayedColumns.push('menu')
    }
  }

  ngOnInit(): void {
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;

    this.getBlogs();
  }
  onClear(){
    this.filterByInp = null;
    this.filter_type=null;
    this.getBlogs();

  }
  searchBlog(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;

    this.getBlogs();
  }
  filterBy(event){
    this.filter_type = +event?.value;
    console.log(this.filter_type);
    
    if(this.filter_type !== 3){
      this.getBlogs();
    }
  }
  getSelectedDate(event){
    this.daterange = (event?.target?.value) ? event?.target?.value : ''
    console.log("daterange: ",this.daterange);
    
    if(this.filter_type == 3){
      this.getBlogs();
    }
  }
  getBlogs() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : ''}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getLearnQuery = this._apollo.watchQuery<BlogQuery>({
      query: gql`
          query learnList($page: Int, $size: Int, $search: String,$filtertype: Int, $startingDate: String, $endingDate:String){
            learnList(page: $page, size: $size, search: $search, filtertype: $filtertype,startingDate:$startingDate,endingDate:$endingDate){
              totalItems
              data{
                id
                caption
                thumb
                media
                mediaType
                description
                milestone
                status
                blogView
                viewCount
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex),
            size: parseInt(pagesize),
            search: search,
            filtertype:this.filter_type,
            startingDate: (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.begin, 'yyyy-MM-dd'):"",
            endingDate:  (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.end, 'yyyy-MM-dd'):"",
          },
          fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getLearnQuery.valueChanges.pipe(map(result => result.data.learnList)).subscribe((result) => {
      if (result.totalItems) {
        const ELEMENT_DATA:any = result.data.map((blog: Blog) => ({
          id: blog.id,
          caption: blog.caption,
          milestone: blog.milestone,
          Status: blog.status,
          blogView: this.filter_type ? blog.blogView : blog.viewCount
        }));
        this.dataSource = new MatTableDataSource<BlogQuery>(ELEMENT_DATA);
        this.blogs = result;
      } else {
        this._helper.showToast(4,'No Data Found');
      }
    });

  }
  editBlog(blogId){
    this._helper.goToPage('edit-blog',{state: {blogId}});
  }
  viewDetails(blogId){
    this._helper.goToPage('view-blog-details',{state: {blogId:blogId,page:this.pIndex}});
  }

  changeStatus(id,status){
    let newStatus = (status == 0) ? 0 : ((status === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation updateLearn($id: ID!,$status: String){
          updateLearn(id:$id,status:$status)
        }`,
      variables: {id: parseInt(id),status:newStatus.toString()},
    }).subscribe(
      ({ data }: updateLearn) => {
        if(data.updateLearn[0] == true){
          this.getLearnQuery.refetch();
          this._helper.showToast(1,(status == 0)?'Activity Deleted Successfully':(status == 1)?'Activity Disabled Successfully':'Activity Activated Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
}
