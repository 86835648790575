import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, map, startWith } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { SpinnerService } from '../../../../shared/spinner.service';
import { VideoProcessingService } from '../../../../services/video-processing-service'
import { Observable } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import { LowerCasePipe } from '@angular/common';
import {tagList} from '../tagListDummy';
import { removeSpacesValidator } from '../../../../shared/custom-validators'

import { Pipe, PipeTransform } from '@angular/core';
import { join } from 'path';


@Pipe({
  name: 'removeWhiteSpace'
})
export class RemoveWhiteSpacePipe implements PipeTransform {

  transform(value: any): any {
    if (value === undefined)
      return 'undefined';
    return value.replace(/\s/g, "");
  }

}

interface addLearn {
  data: {
    addLearn: {
      id: any,
      status: any
    };
  };
}
// export function removeSpaces(control: AbstractControl) {
//   if (control && control.value && !control.value.replace(/\s/g, '').length) {
//     control.setValue('');
//   }
//   return null;
// }
@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.scss']
})
export class AddBlogComponent implements OnInit {

  addOnBlur = false;
  readonly separatorKeysCodes = [ENTER, COMMA,SPACE] as const;
  tags: string[] = [];
  blogForm:FormGroup; 
  // blog:any;
  filename: string = null;
  imageUrl: any;
  milestones = ELEMENT_DATA;
  allMileStone: boolean = false;
  downloadURL: Observable<string>;
  imageData: File;
  blogFormSubmit:boolean;
  videoUrl:any;
  imageType:any;
  videothumbnailData:any;
  imageIcontoConvert:any;
  imageIcontoConvertUrl:any;
  tagFC = new FormControl([]);
  tagOptions: string[] = tagList;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  filteredTags: Observable<string[]>;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      minHeight: '10rem',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: []
};
  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,
    private spinnerService: SpinnerService,private sanitizer: DomSanitizer,private videoService: VideoProcessingService,private lowerCase:LowerCasePipe,private removeWhiteSpacePipe: RemoveWhiteSpacePipe) { }

  ngOnInit(): void {
    this.blogForm = new FormGroup({
      caption: new FormControl('', [Validators.required,removeSpacesValidator()]),
      blogIcon: new FormControl('', [Validators.required]),
      // duration: new FormControl('', [Validators.required,removeSpaces]),
      description: new FormControl('', [Validators.required,removeSpacesValidator()]),
      milestone: new FormControl('', [Validators.required]),
      tag: new FormControl([]),
      metaTitle:new FormControl('', [Validators.required,removeSpacesValidator()]),
      metaDescription:new FormControl('', [Validators.required,removeSpacesValidator()]),
      slug:new FormControl('', [Validators.required,removeSpacesValidator()]),
    });
    this.filteredTags = this.blogForm.controls.tag.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
    this.getTagList();
    
  }
  private getTagList(){
    this._apollo.query({
      query: gql`
        query tagsList($search:String){
          tagsList(search:$search){
            id
            name
            status
          }
        }`,
      variables: { search:"#"},
    }).subscribe(
      ({ data }: any) => {
        if(data.tagsList?.length > 0){
          this.tagOptions = data.tagsList.map((t:any)=>{
            return t.name;
          });
        }else{
          this.tagOptions = tagList;
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }
  private _filter(value: string): string[] {
    if(value){

      const filterValue = value.toLowerCase();
  
      return this.tagOptions.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

  selected(event: any): void {  
    this.add(event,2)
  }

  async addBlog(){
    if(this.blogForm.invalid){
      return;
    }
    if(this.blogFormSubmit){
      this._helper.showToast(4,'Less Than 1 Min Video is Required');
      return;
    }
    let milestoneValues = [...this.blogForm.value.milestone];
    if(this.blogForm.value.milestone.includes('All')){
      let index = null;
      const all = this.blogForm.value.milestone.find((el:any,i:any)=>{
        if(el === "All"){
          index = i;
        }
      });
      if(index !== null){
        milestoneValues.splice(index,1);
      }
    }
    let imgnam = Date.now()+'-'+this.imageData.name;
    const fileRef = this.storage.ref(`/blog/images/${imgnam}`);
    this.spinnerService.show();
    this.storage.upload(`/blog/images/${imgnam}`, this.imageData).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){
            let imgIconNam = Date.now()+'_'+this.imageData.name;
            const fileIconRef = this.storage.ref(`/blog/blogIcon/${imgIconNam}`);
            let uploadTask = this.storage.ref('/blog/blogIcon/').child(imgIconNam).putString( this.imageIcontoConvert , 'data_url', {contentType:this.imageType[1]});
            uploadTask.snapshotChanges().pipe(
              finalize(() => {
                fileIconRef.getDownloadURL().subscribe((iconUrl) => {
                  this._apollo.mutate({
                    mutation: gql`
                      mutation addLearn($caption: String,$thumb: String,$media: String,
                         $mediaType: String,$description: String,$milestone: String, $tag: String, $metaTag: String, $metaDescription: String,$flag:String){
                        addLearn(caption: $caption,thumb: $thumb,media: $media, 
                          mediaType:$mediaType,description: $description,milestone: $milestone,tag:$tag,metaTag:$metaTag,metaDescription:$metaDescription,flag:$flag){
                          id
                          status
                        }
                      }`,
                    variables: { caption:this.blogForm.value.caption,thumb:iconUrl,
                      media:url,mediaType:(this.imageUrl)?'image':'video',description:this.blogForm.value.description,
                      milestone:milestoneValues.toString(),
                      tag: this.tags.join(),
                      metaTag:this.blogForm.value.metaTitle,
                      metaDescription:this.blogForm.value.metaDescription,
                      flag:this.blogForm.value.slug
                    },
                  }).subscribe(
                    ({ data }: addLearn) => {
                      if(data.addLearn.id){
                        this._helper.showToast(1,'Blog Added Successfully');
                        this._helper.goToPage('/admin/view-blogs')
                      }else{
                        this._helper.showToast(4,'Something Went Wrong.. Try Again');
                      }
                    },(err) => {
                      this._helper.showToast(4,err.message);
                    }
                  );
                })
              })
            )
          .subscribe()
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })
    ).subscribe();
  }

  getDuration(e) {    
    if(e.target.duration > 60){
      this.blogFormSubmit = true;
      this._helper.showToast(4,'Less Than 1 Min Video is Required');
    }
  }
  setAllMileStones(value:any){
    if(value === 'All'){
      this.allMileStone = !this.allMileStone;
      if(this.allMileStone){
        const all = this.milestones.map(m=>{
          return m.value
        });
        this.blogForm.controls.milestone.setValue(all);
      }
      if(!this.allMileStone){
        this.blogForm.controls.milestone.setValue([]);
      }
    }else{
      let milestoneValues = [...this.blogForm.value.milestone];
      if(this.blogForm.value.milestone.includes('All')){
        let index = null;
        const all = this.blogForm.value.milestone.find((el:any,i:any)=>{
          if(el === "All"){
            index = i;
          }
        });
        if(index !== null){
          milestoneValues.splice(index,1);
        }
        this.blogForm.controls.milestone.setValue(milestoneValues);
      }

    }
    
  }

  cancel(){
    this._helper.goToPage('/admin/view-blogs')
  }

  getVideoImage(){
    this.spinnerService.show();
    this.videoService.promptForVideo().then(videoFile => {
      let type = videoFile.type.split("/",1);
      if(type[0] == 'image'){
        return this.videoService.onImageFileChanged2(videoFile)
      }else{
        return this.videoService.generateThumbnail(videoFile);
      }
    }).then(result => {  
      if(result && result.status == true){
        this.blogFormSubmit = false;
        this.videoUrl = (result.imageUrl)?false:this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result.imageData ))
        this.imageUrl = (result.imageUrl)?result.imageUrl:false;
        this.imageData = result.imageData;
        this.filename = result.filename;
        this.imageIcontoConvert = result.url;
        this.imageType = (this.imageIcontoConvert.split(";")[0].split(":"));
        this.spinnerService.hide();
      }
    }).then(()=>{
      this.spinnerService.hide();
    })
  }

 

  add(event: any,type=1): void {
    const value = (type === 1 ? event.value:event || '').trim();

    // Add our fruit
    if (value) {
      let valuetemp = value;
      if(value.charAt(0) !== "#"){
        valuetemp = `#${value}`;
      }
      if(!this.tags.includes(this.removeWhiteSpacePipe.transform(this.lowerCase.transform(valuetemp)))){
        this.tags.push(this.removeWhiteSpacePipe.transform(this.lowerCase.transform(valuetemp)));
      }
    }

    // Clear the input value
    this.tagInput.nativeElement.value = '';
    this.blogForm.controls.tag.setValue('');
  }

  remove(tag: any): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

}


export interface Element {
  id: number;
  name: string;
  value: string;
}

const ELEMENT_DATA: Element[] = [
  {id:73,name:"All Milestones", value:"All"},
  {id:0,name:"Month 00", value:"M00"},
  {id:1,name:"Month 01", value:"M01"},
  {id:2,name:"Month 02", value:"M02"},
  {id:3,name:"Month 03", value:"M03"},
  {id:4,name:"Month 04", value:"M04"},
  {id:5,name:"Month 05", value:"M05"},
  {id:6,name:"Month 06", value:"M06"},
  {id:7,name:"Month 07", value:"M07"},
  {id:8,name:"Month 08", value:"M08"},
  {id:9,name:"Month 09", value:"M09"},
  {id:10,name:"Month 10", value:"M10"},
  {id:11,name:"Month 11", value:"M11"},
  {id:12,name:"Month 12", value:"M12"},
  {id:13,name:"Month 13", value:"M13"},
  {id:14,name:"Month 14", value:"M14"},
  {id:15,name:"Month 15", value:"M15"},
  {id:16,name:"Month 16", value:"M16"},
  {id:17,name:"Month 17", value:"M17"},
  {id:18,name:"Month 18", value:"M18"},
  {id:19,name:"Month 19", value:"M19"},
  {id:20,name:"Month 20", value:"M20"},
  {id:21,name:"Month 21", value:"M21"},
  {id:22,name:"Month 22", value:"M22"},
  {id:23,name:"Month 23", value:"M23"},
  {id:24,name:"Month 24", value:"M24"},
  {id:25,name:"Month 25", value:"M25"},
  {id:26,name:"Month 26", value:"M26"},
  {id:27,name:"Month 27", value:"M27"},
  {id:28,name:"Month 28", value:"M28"},
  {id:29,name:"Month 29", value:"M29"},
  {id:30,name:"Month 30", value:"M30"},
  {id:31,name:"Month 31", value:"M31"},
  {id:32,name:"Month 32", value:"M32"},
  {id:33,name:"Month 33", value:"M33"},
  {id:34,name:"Month 34", value:"M34"},
  {id:35,name:"Month 35", value:"M35"},
  {id:36,name:"Month 36", value:"M36"},
  {id:37,name:"Month 37", value:"M37"},
  {id:38,name:"Month 38", value:"M38"},
  {id:39,name:"Month 39", value:"M39"},
  {id:40,name:"Month 40", value:"M40"},
  {id:41,name:"Month 41", value:"M41"},
  {id:42,name:"Month 42", value:"M42"},
  {id:43,name:"Month 43", value:"M43"},
  {id:44,name:"Month 44", value:"M44"},
  {id:45,name:"Month 45", value:"M45"},
  {id:46,name:"Month 46", value:"M46"},
  {id:47,name:"Month 47", value:"M47"},
  {id:48,name:"Month 48", value:"M48"},
  {id:49,name:"Month 49", value:"M49"},
  {id:50,name:"Month 50", value:"M50"},
  {id:51,name:"Month 51", value:"M51"},
  {id:52,name:"Month 52", value:"M52"},
  {id:53,name:"Month 53", value:"M53"},
  {id:54,name:"Month 54", value:"M54"},
  {id:55,name:"Month 55", value:"M55"},
  {id:56,name:"Month 56", value:"M56"},
  {id:57,name:"Month 57", value:"M57"},
  {id:58,name:"Month 58", value:"M58"},
  {id:59,name:"Month 59", value:"M59"},
  {id:60,name:"Month 60", value:"M60"},
  {id:61,name:"Month 61", value:"M61"},
  {id:62,name:"Month 62", value:"M62"},
  {id:63,name:"Month 63", value:"M63"},
  {id:64,name:"Month 64", value:"M64"},
  {id:65,name:"Month 65", value:"M65"},
  {id:66,name:"Month 66", value:"M66"},
  {id:67,name:"Month 67", value:"M67"},
  {id:68,name:"Month 68", value:"M68"},
  {id:69,name:"Month 69", value:"M69"},
  {id:70,name:"Month 70", value:"M70"},
  {id:71,name:"Month 71", value:"M71"},
  {id:72,name:"Month 72", value:"M72"}
];

