import { Route } from '@angular/router';
import { AccessAuthGuard } from 'app/services/auth.guard';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProfileComponent } from './pages/profile/profile.component';

import { AllUsersComponent } from './pages/users/all-users/all-users.component';
import { UserDetailsComponent } from './pages/users/user-details/user-details.component';
import { RoleComponent } from './pages/role/viewrole/role.component';
import { ViewActivitiesComponent } from './pages/activities/view-activities/view-activities.component';
import { ViewAreaComponent } from './pages/area/view-area/view-area.component';
import { AddAreaComponent } from './pages/area/add-area/add-area.component';
import { EditAreaComponent } from './pages/area/edit-area/edit-area.component';
import { ViewSkillsComponent } from './pages/skills/view-skills/view-skills.component';
import { AddSkillsComponent } from './pages/skills/add-skills/add-skills.component';
import { EditSkillsComponent } from './pages/skills/edit-skills/edit-skills.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { EditRoleComponent } from './pages/role/edit-role/edit-role.component';
import { AddUserComponent } from './pages/users/add-user/add-user.component';
import { AddRoleComponent } from './pages/role/add-role/add-role.component';
import { AddActivityComponent } from './pages/activities/add-activity/add-activity.component';
import { EditActivityComponent } from './pages/activities/edit-activity/edit-activity.component';
import { ActivityDetailsComponent } from './pages/activities/activity-details/activity-details.component';
import { AboutFamilyComponent } from './pages/family/about-family/about-family.component';
import { ViewFamilyComponent } from './pages/family/view-family/view-family.component';
import { ChildListDetailsComponent } from './pages/family/child-list-details/child-list-details.component';
import { ViewMilestonesComponent } from './pages/milestones/view-milestones/view-milestones.component';
import { ViewAssignedActivitiesComponent } from './pages/milestones/view-assigned-activities/view-assigned-activities.component';
import { AssignActivitiesComponent } from './pages/milestones/assign-activities/assign-activities.component';
import { ViewIndicatorComponent } from './pages/indicators/view-indicator/view-indicator.component';
import { EditIndicatorComponent } from './pages/indicators/edit-indicator/edit-indicator.component';
import { AddBlogComponent } from './pages/blog/add-blog/add-blog.component';
import { ViewBlogComponent } from './pages/blog/view-blog/view-blog.component';
import { EditBlogComponent } from './pages/blog/edit-blog/edit-blog.component';
import { ViewBlogDetailsComponent } from './pages/blog/view-blog-details/view-blog-details.component';
import { ViewUserPermissionsComponent } from './pages/userPermissions/view-user-permissions/view-user-permissions.component';
import { ErrorComponent } from './pages/error/error.component';
import { AddtipComponent } from './pages/tips/addtip/addtip.component';
import { ViewtipComponent } from './pages/tips/viewtip/viewtip.component';
import { EdittipComponent } from './pages/tips/edittip/edittip.component';
import { ShowindicatorsComponent } from './pages/milestones/showindicators/showindicators.component';
import { CmsManagementComponent } from './pages/cms/cms-management/cms-management.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { MembersListComponent } from './pages/members/members-list/members-list.component';
import { KpiComponent } from './pages/kpi/kpi.component';
import { ViewPostComponent } from './pages/post/view-post/view-post.component';
import { AddPostComponent } from './pages/post/add-post/add-post.component';
import { EditPostComponent } from './pages/post/edit-post/edit-post.component';
import { ViewPostDetailsComponent } from './pages/post/view-post-details/view-post-details.component';
import {ViewTrackerComponent,AddTrackPopupComponent} from './pages/trackers/view-tracker/view-track.component';
import { ViewTrackMilestonesComponent } from './pages/trackerMilestones/view-milestones/ViewTrackMilestones.component';
import { EditMilestoneComponent } from './pages/trackerMilestones/edit-milestone/edit-tracker-milestone';
import { ChildGrowth } from './pages/childGrowth/child-growth.component';


export const adminRoutes: Route[] = [
  {
    path: 'admin',
    pathMatch: 'full',
    component: DashboardComponent,
    canActivate: [AccessAuthGuard],
  },
  {
    path: 'error',
    pathMatch: 'full',
    component: ErrorComponent,
    canActivate: [AccessAuthGuard],
  },

  {
    path: 'users',
    component: AllUsersComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 11}
  },
  
  {
    path: 'edit-user',
    component: EditUserComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 14}
  },
  
  {
    path: 'add-user',
    component: AddUserComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 13}
  },
  
  // {
  //   path: 'users/:id',
  //   component: UserDetailsComponent,
  //   canActivate: [AccessAuthGuard],
  // },

  // {
  //   path: 'settings',
  //   component: SettingsComponent,
  //   canActivate: [AccessAuthGuard],
  // },
  
  {
    path: 'view-activities',
    component: ViewActivitiesComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 10}
  },
  {
    path: 'add-activity',
    component: AddActivityComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 1}
  },
  {
    path: 'edit-activity',
    component: EditActivityComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 3}
  },
  {
    path: 'view-activity-details',
    component: ActivityDetailsComponent,
    canActivate: [AccessAuthGuard],
  },
  {
    path: 'roles',
    component: RoleComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 18}
  },
  {
    path: 'edit-role',
    component: EditRoleComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 19}
  },
  {
    path: 'add-role',
    component: AddRoleComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 17}
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AccessAuthGuard],
  },
  {
    path: 'view-area',
    component: ViewAreaComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 8}
  },
  // {
  //   path: 'view-area/:id',
  //   component: ViewSkillsComponent,
  //   canActivate: [AccessAuthGuard],
  // },
  {
    path: 'edit-area',
    component: EditAreaComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 9}
  },
  {
    path: 'view-skills',
    component: ViewSkillsComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 35}
  },

  {
    path: 'edit-skill',
    component: EditSkillsComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 37}
  },
  {
    path: 'view-indicator',
    component: ViewIndicatorComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 38}
  },
  {
    path: 'edit-indicator',
    component: EditIndicatorComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 40}
  },
  {
    path: 'view-family',
    component: ViewFamilyComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 15}
  },
  {
    path: 'about-family',
    component: AboutFamilyComponent,
    canActivate: [AccessAuthGuard],
  },
  {
    path: 'child-details',
    component: ChildListDetailsComponent,
    canActivate: [AccessAuthGuard],
  },
  {
    path: 'milestones',
    component: ViewMilestonesComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 20}
  },
  {
    path: 'view-assigned-activity',
    component: ViewAssignedActivitiesComponent,
    canActivate: [AccessAuthGuard],
  },
  {
    path: 'assign-activity',
    component: AssignActivitiesComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 21}
  },
  {
    path: 'view-blogs',
    component: ViewBlogComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 24}
  },
  {
    path: 'add-blog',
    component: AddBlogComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 23}
  },
  {
    path: 'edit-blog',
    component: EditBlogComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 25}
  },
  {
    path: 'view-blog-details',
    component: ViewBlogDetailsComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 1}
  },
  {
    path: 'user-permissions',
    component: ViewUserPermissionsComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 29}
  },
  {
    path: 'view-tips',
    component: ViewtipComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 30}
  },
  {
    path: 'edit-tip',
    component: EdittipComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 32}
  },
  {
    path: 'milestone-indicators',
    component: ShowindicatorsComponent,
    canActivate: [AccessAuthGuard]
  },
  {
    path: 'cms',
    component: CmsManagementComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 58}
  },
  {
    path: 'contacts',
    component: ContactusComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 59}
  },
  {
    path: 'view-members',
    component: MembersListComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 60}
  },
  {
    path: 'kpi',
    component: KpiComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 61}
  },
  {
    path: 'view-posts',
    component: ViewPostComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 63}
  },
  {
    path: 'add-post',
    component: AddPostComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 62}
  },
  {
    path: 'edit-post',
    component: EditPostComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 64}
  },
  {
    path: 'view-post-details',
    component: ViewPostDetailsComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 63}
  },
  {
    path:'trackers',
    component:ViewTrackerComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 61}
  },
  // {
  //   path:'trackers/:id',
  //   component:AddTrackPopupComponent,
  //   canActivate: [AccessAuthGuard],
  //   data: {allowedRoles: 63}
  // },
  {
    path: 'track-milestone',
    component:ViewTrackMilestonesComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 69}
  },
  {
    path: 'edit-milestone',
    component:EditMilestoneComponent,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 70}
  },
  {
    path: 'child-growth',
    component:ChildGrowth,
    canActivate: [AccessAuthGuard],
    data: {allowedRoles: 67}
  }
  
  
];

