<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    
    <div class="center">
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16"> forum </mat-icon>
                <span class="logo-text h1"> Edit Post </span>
            </div>
        </div>
        
        <div class="content-card" >
            <mat-card>
                <mat-card-content>
                    <form [formGroup]="postForm">
                        <mat-form-field appearance="outline" class="w-75-p" floatLabel="always">
                            <mat-label>Post Caption</mat-label>
                            <input matInput formControlName="caption" type="text" placeholder="Caption" />
                            <mat-error *ngIf="postForm.controls['caption'].hasError('required')">Caption is Required </mat-error>
                            <mat-error *ngIf="postForm.controls['caption'].hasError('removeSpaces')">Invalid Caption</mat-error>

                        </mat-form-field> 
                        <mat-form-field  class="w-80-p" floatPlaceholder="never">
                            <mat-label>Media</mat-label>
                            <input [hidden]="true" type="file" accept="all/*" #fileSelect>
                            <input readonly matInput  style="cursor: pointer" (click)="getVideoImage()" formControlName="postIcon"  [(ngModel)]="filename" placeholder="Select file..."/>
                            <div style="float: right; cursor: pointer;margin-top: -25px;" (click)="getVideoImage()" matTooltip="Select a file" [matTooltipPosition]="'above'">
                                <mat-icon>attach_file</mat-icon>
                              </div>
                        </mat-form-field>
                        
                        <img *ngIf="imageUrl" class="uploadTempnail" [src]="imageUrl" onerror="'assets/images/no_image.png'" />
                        <video *ngIf="videoUrl" class="uploadTempnail"  controls [attr.src]="videoUrl" width="90" height="90"  (loadedmetadata)="getDuration($event)"></video>
                        <mat-error *ngIf="postFormSubmit">Media is Required </mat-error>
                        <br/><br/>
                      <angular-editor id="privacy" [config]="editorConfig" [placeholder]="'Description'" formControlName="description"></angular-editor>
                      <mat-error *ngIf="postForm.controls['description'].hasError('required')">Description is Required </mat-error>
                      <br/>
                        <br/>
                        <mat-radio-group aria-label="Select an option" formControlName="status" *ngIf="this.userRoles && (this.userRoles.includes(65) || this.userRoles.includes(66))">
                            <mat-radio-button *ngIf="this.userRoles.includes(65)" [value]="1">Publish</mat-radio-button>
                            <mat-radio-button *ngIf="this.userRoles.includes(65)" [value]="2">Unpublish</mat-radio-button>
                            <mat-radio-button *ngIf="this.userRoles.includes(66)" [value]="0">Delete</mat-radio-button>
                        </mat-radio-group>
                          <mat-error *ngIf="postForm.controls['status'].hasError('required')">Status is Required </mat-error>
          <br/>
          <br/>
                        <button mat-raised-button color="primary" style="margin-right: 5px" [disabled]="postForm.invalid"  (click)="editPost()"> Save </button>
                        &nbsp;&nbsp;
                        <button mat-stroked-button  (click)="cancel()" >
                            <span>Cancel</span>
                        </button>
                        
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

