
<h2 mat-dialog-title style="margin-left: 40px;">Add Tracker</h2>
<div mat-dialog-content style="overflow: hidden;">
  <form [formGroup]="trackerForm" (ngSubmit)="onSubmit()" #formDirective="ngForm" style="margin-left: 40px;">
    <mat-form-field appearance="outline" class="w-80-p" floatLabel="always">
      <mat-label>Select milestone</mat-label>
      <input matInput formControlName="milestoneValue"formControlName="trackername" placeholder="Enter milestone..." />
    </mat-form-field>
    
    <mat-form-field class="w-80-p" floatPlaceholder="never">
      <mat-label>Icon</mat-label>
      <input [hidden]="true" (change)="onFileChanged($event)" type="file" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" onclick="this.value=null;" accept="all/*" #fileSelect />
      <input readonly matInput style="cursor: pointer" formControlName="trackerIcon" (click)="fileSelect.click()" [(ngModel)]="filename" placeholder="Select file..." />
      <div style="float: right; cursor: pointer;margin-top: -25px; overflow: hidden;" (click)="fileSelect.click();">
        <mat-icon>attach_file</mat-icon>
      </div>
    </mat-form-field>
    
    <img *ngIf="imageUrl" style="width: 100%; max-height: 90px; object-fit: contain; padding-right: 90px;" class="uploadTempnail" id="image_id" [src]="imageUrl" onerror="'assets/images/no_image.png'" />

    <br/><br/>
      <button mat-raised-button color="primary" style="margin-right: 5px"  (ngSubmit)="onSubmit(); false">Save</button>
      <button mat-stroked-button style="margin-right: 5px" (click)="onNoClick()">Cancel</button>
  </form>
 
</div>

