import { FuseNavigation } from '@fuse/types';
import { getLocalStorageItem } from "app/helpers/utils";

const getNavItems = (): FuseNavigation[] => {
  const data = getLocalStorageItem('side-menu') || {};
  return ;

};

export const navigation = getNavItems();

// const navigation1: FuseNavigation[] = [
//   {
//     id: '',
//     title: '',
//     translate: '',
//     type: 'group',
//     children: [
//       {
//         id: 'admin-dashboard',
//         title: 'Dashboard',
//         type: 'item',
//         icon: 'dashboard',
//         url: '/admin',
//         exactMatch: true,
//       },

//       {
//         id: 'users',
//         title: 'Users',
//         type: 'item',
//         icon: 'groups',
//         url: '/admin/users',
//       },
//       {
//         id: 'family',
//         title: 'Family',
//         type: 'item',
//         icon: 'home',
//         url: '/admin/view-family',
//       },
//       {
//         id: 'roles',
//         title: 'Roles',
//         type: 'item',
//         icon: 'supervisor_account',
//         url: '/admin/roles',
//       },
//       {
//         id: 'area',
//         title: 'Area',
//         type: 'item',
//         icon: 'place',
//         url: '/admin/view-area',
//       },
//       {
//         id: 'activities',
//         title: 'Activities',
//         type: 'item',
//         icon: 'toys',
//         url: '/admin/view-activities',
//       },
//       {
//         id: 'milestone',
//         title: 'Milestone',
//         type: 'item',
//         icon: 'map',
//         url: '/admin/milestones',
//       },
//       {
//         id: 'blog',
//         title: 'Blog',
//         type: 'item',
//         icon: 'format_bold',
//         url: '/admin/view-blogs',
//       },
 
//     ],
//   },
// ];
