export const tagList = [
  '#parenting',
  '#toddler',
  '#games',
  '#outdoors',
  '#infant',
  '#child',
  '#newparents',
  '#earlyyearsdevelopment',
  '#homeschooling',
  '#kidsactivities',
  '#childcare',
  '#nutrition',
];
