import { Component, Inject, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { finalize, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ROLES, getLocalStorageRoleItem } from "app/helpers/utils";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { SpinnerService } from '../../../../shared/spinner.service';
import { TrackerQuery, Track, addTrack, deleteTrack, TrackerEditQuery, UpdateTrackerResponse, ChangeStatusResponse } from '../../../models/types';
import { OnDestroy } from '@angular/core';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-view-trackers',
  templateUrl: './view-track.component.html',
  styleUrls: ['./view-track.component.scss']
})
export class ViewTrackerComponent implements OnInit, OnDestroy {
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  pageEvent: PageEvent;
  datasource: null;
  trackOne: any;
  userRoles: any = (getLocalStorageRoleItem(ROLES)) ? getLocalStorageRoleItem(ROLES) : [];
  getTrackerQuery: QueryRef<any>;
  private querySubscription: Subscription;
  currentPage: number;
  dataSize: number[] = [10, 50, 100];
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['trackerName', 'icon', 'status', 'menu'];
  trackers: any;
  totallength: any;
  filteredFood: any[];
  Trackers: any[];
  dialogRef: any;
  constructor(private _helper: HelperService, private _apollo: Apollo, public dialog: MatDialog, private storage: AngularFireStorage, private spinnerService: SpinnerService,) {
    this.getTrackerData();
    this.dataSource = new MatTableDataSource<any>([]);
  }


  ngOnInit(): void {
  }

  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
    this.pSize = pageSize;
    this.pIndex = pageIndex;
    this.getTrackerData()
  }

  searchTrack(keyword: string) {
    this.pIndex = 0;
    this.searchKeyword = keyword;
    this.getTrackerData()
  }

  viewMileStone(trackerId: any) {
    const foundTracker: any = this.trackers.find((tracker) => tracker.id === trackerId);
    this._helper.goToPage('track-milestone', { queryParams: { trackerId: trackerId, trackerName: foundTracker.trackerName } });
  }

  getTrackerData() {
    const pageindex = `${this.pIndex ? `${this.pIndex}` : 0}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;
    this.getTrackerQuery = this._apollo.watchQuery<TrackerQuery>({
      query: gql`
        query devTrackerList($page: Int, $size: Int, $search: String) {
          devTrackerList(page: $page, size: $size, search: $search) {
            message
            status
            page {
              totalItems
              totalPages
              currentPage
            }
            data {
              id
              trackerName
              img
              createdAt
              status
             
            }
          }
        }
      `,
      variables: {
        page: parseInt(pageindex),
        size: parseInt(pagesize),
        search: search
      },
      fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getTrackerQuery.valueChanges.pipe(map(result => result.data.devTrackerList)).subscribe((result) => {
      if (result.data) {
        const ELEMENT_DATA: any = result.data.map((track: Track, index) => ({
          index: index,
          id: track.id,
          TrackerName: track.trackerName,
          img: track.img,
          Status: track.status,
        }));
        this.dataSource = new MatTableDataSource<TrackerQuery>(ELEMENT_DATA);

        this.trackers = result.data;

        this.totallength = result?.page?.totalItems;
        this.currentPage = result?.page?.currentPage;
      } else {
        this._helper.showToast(4, 'No Data Found');
      }
    })
  }

  getTrackerById(id: any): Observable<any> {
    const queryRef = this._apollo.watchQuery<TrackerEditQuery>({
      query: gql`
        query devTrackerById($id: ID!) {
          devTrackerById(id: $id) {
            message
            status
            data {
              id
              trackerName
              img
              createdAt
              status
            }
          }
        }
      `,
      variables: { id: id },
      fetchPolicy: 'cache-and-network',
    });

    return queryRef.valueChanges.pipe(
      map(result => result.data ? result.data : null),
      take(1)
    );
  }

  addTrack(): void {
    const dialogRef = this.dialog.open(AddTrackPopupComponent, {
      width: '500px',
      maxHeight: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._apollo.mutate({
          mutation: gql`
          mutation addDevTracker($trackerName: String!, $img: String!) {
            addDevTracker(trackerName: $trackerName, img: $img) {
              message
              status
              data {
                id
                trackerName
                img
                createdAt
                status
                __typename
              }
              __typename
            }
          }
          `,
          variables: { trackerName: result.trackerName, img: result.icon },
        }).subscribe(
          ({ data }: addTrack) => {
            this._helper.showToast(1, 'Tracker added Successfully');
            this.getTrackerQuery.refetch();
          }, (err) => {
            this._helper.showToast(4, err.message);
          }
        )
      }
    });
  }

  refresh() {
    this.getTrackerQuery.refetch();
  }


  editTrack(id: any): void {

    if (id) {
      this.getTrackerById(id).subscribe((trackerData) => {
        if (trackerData) {
          const dialogRef = this.dialog.open(AddTrackPopupComponent, {
            data: {
              id: trackerData.devTrackerById.data.id,
              trackerName: trackerData.devTrackerById.data.trackerName,
              icon: trackerData.devTrackerById.data.img,
              editMode: true,
            }
          });
          dialogRef.afterClosed().subscribe((updatedData) => {
            if (dialogRef.componentInstance && dialogRef.componentInstance.dialogClosedByUser) {
              return;
            }
            if (updatedData) {
              this._apollo.mutate({
                mutation: gql`
                  mutation updateDevTracker($id: ID!, $trackerName: String, $img: String, $status: Int) {
                    updateDevTracker(id: $id, trackerName: $trackerName, img: $img, status: $status) {
                      message
                      status
                      success
                    }
                  }
                `,
                variables: {
                  id: id,
                  trackerName: updatedData.trackerName,
                  img: updatedData.icon,
                  status: updatedData.status
                },
              }).subscribe(
                ({ data }: UpdateTrackerResponse) => {
                  this._helper.showToast(1, 'Tracker updated Successfully');
                  this.getTrackerQuery.refetch();
                },
                (err) => {
                  this._helper.showToast(4, err.message);
                }
              )
            }
          });
        }
      });
    }
  }


  ngOnDestroy(): void {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }


  changeStatus(id: any, status: any) {
    let newStatus = (status == 0) ? 0 : ((parseInt(status) === 1) ? 2 : 1);
    this._apollo.mutate({
      mutation: gql`
        mutation changeDevTrackerStatus($id: ID, $status: Int) {
          changeDevTrackerStatus(id: $id, status: $status) {
            message
            status
            success
          }
        }
      `,
      variables: { id: parseInt(id), status: newStatus },
    }).subscribe(
      ({ data }: ChangeStatusResponse) => {
        if (data.changeDevTrackerStatus.success === true) {
          this._helper.showToast(1, 'Tracker Status Updated Successfully');
          this.getTrackerQuery.refetch();
        }
      },
      (err) => {
        this._helper.showToast(4, err.message);
      }
    );

  }

  deleteItem(id: any) {
    if (id) {
      this._apollo.mutate({
        mutation: gql`
        mutation deleteDevTracker($id:ID ) {
          deleteDevTracker(id: $id) {
                   message
                   status
                   success
                 }
               }
        `,
        variables: { id: id },
      }).subscribe(
        ({ data }: deleteTrack) => {
          this._helper.showToast(1, 'Tracker deleted Successfully');
          this.getTrackerQuery.refetch();
        },
        (err) => {
          this._helper.showToast(4, err.message);
        }
      )
    }

  }
}

export interface DialogData {
  trackerName: string;
  icon: string;
  selectedData: any;
  editMode: boolean;
}
@Component({
  selector: 'app-add-track-popup',
  templateUrl: './addTrackPopup.html',

})


export class AddTrackPopupComponent {

  name: string;
  selectedTrack: string;
  selectedIcon: any;
  trackerForm: FormGroup;
  filename: string = null;
  imageUrl: any;
  imageData: File;
  selectedTracker: string;
  dialogClosedByUser = false;
  constructor(
    public dialogRef: MatDialogRef<AddTrackPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private storage: AngularFireStorage, private spinnerService: SpinnerService) {

  }

  ngOnInit(): void {

    this.trackerForm = new FormGroup({
      trackername: new FormControl(this.data && this.data.trackerName ? this.data.trackerName : '', [Validators.required]),
      trackerIcon: new FormControl(
        this.data && this.data.icon ? this.data.icon : '',
        this.data && this.data.editMode ? null : [Validators.required]
      ),

    });

    if (this.data && this.data.icon) {
      this.imageUrl = this.data.icon;
    }

  }
  onFileChanged(event) {
    if (!event.target.files || !event.target.files[0]) {
      this.imageUrl = false;
      return false;
    }

    this.imageData = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imageData);

    reader.onload = (event) => {
      this.imageUrl = reader.result;
      this.filename = this.imageData.name;
    }
  }
  onSubmit() {
    if (this.trackerForm.valid) {
      if (this.imageData) {
        this.spinnerService.show()
        const filePath = `/icons/${this.filename}`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, this.imageData);

        uploadTask.snapshotChanges().pipe(
          finalize(() => {
            storageRef.getDownloadURL().subscribe(downloadURL => {
              const formValue = {
                trackerName: this.trackerForm.get('trackername').value,
                icon: downloadURL || this.data.icon
              };
              this.dialogRef.close(formValue);
              this.spinnerService.hide();
            });
          })

        ).subscribe();
      } else {
        const formValue = {
          trackerName: this.trackerForm.get('trackername').value,
          icon: this.data.icon
        };
        this.dialogRef.close(formValue);
      }
    }
  }


  onNoClick(): void {
    this.dialogRef.componentInstance.dialogClosedByUser = true;
    this.dialogRef.close();
    this.spinnerService.hide();
  }

}
