import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { map } from 'rxjs/operators';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
import { AreaQuery,Activity, ActivityQuery} from '../../../models/types';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { SelectionModel } from '@angular/cdk/collections';
interface addMilestone {
  data: {
    addMilestone: {
      code: any,
      status: any
    };
  };
}
@Component({
  selector: 'app-assign-activities',
  templateUrl: './assign-activities.component.html',
  styleUrls: ['./assign-activities.component.scss']
})
export class AssignActivitiesComponent implements OnInit {
  
  pIndex: number = 0;
  pSize: number = 10;
  searchKeyword: string;
  pageEvent: PageEvent;
  datasource: null;
  pageSize: number;
  currentPage: number;
  dataSize: number[] = [10, 50, 100];
  
  dataSource: any;
  numRows: any;
  displayedColumns: string[] =  ['select','Name','Area', 'Skill','IndicatorSkill','Status'];
  areas:any;
  skills:any;
  milestones:any;
  milestoneId:any;
  selectedId:any[] = new Array();
  selectedAreaId:any;
  selectedSkillId:any;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
  matSelect: any;
  selectedAgeGrp: any;
  totalMilesData: any;
  matSelect2: any;
  matSelect3: any;
  setValue:any = '';
  selection = new SelectionModel<Activity>(true, []);
  totalMilesPage: number;
  masterTogglePage:any = {[this.pIndex]: {all: false, single: false, count: 0}};
  constructor(private _helper: HelperService,private _apollo: Apollo) { 
    if(!history || !history.state || !history.state.milestoneId || !history.state.selectedId){
      this._helper.goToPage('milestones'); 
    }
    this.milestoneId = history.state.milestoneId;
    this.selectedId = history.state.selectedId;
    this.getAreas();
    this.filterSearch();
  }
  ngOnInit(): void {
  }
  changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {    
    if(this.pSize != pageSize){
      if(this.selectedId.length >= pageSize){
        this.masterTogglePage[pageIndex].all = true;
        this.masterTogglePage[pageIndex].single = false;
        this.masterTogglePage[pageIndex].count = pageSize;
      }else{
        this.masterTogglePage[pageIndex].all = true;
        this.masterTogglePage[pageIndex].single = false;
        this.masterTogglePage[pageIndex].count = this.selectedId.length;
      }
      // this.masterTogglePage = {};
      // this.selectedId = new Array() ;
    }    
    this.pSize = pageSize;
    this.pIndex = pageIndex;
    this.filterSearch();
  }
  
  searchActivities(keyword: string) {    
    this.pIndex = 0;
    this.searchKeyword = keyword;
    this.filterSearch();
  }
  
  getAreas() {
    this._apollo.watchQuery<AreaQuery>({
      query: gql`
      query areaActiveList($search: String){
        areaActiveList(search: $search){
          id
          icon
          areaName
          status
        }
      }`,variables:{search:''},
      
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.areaActiveList)).subscribe((result) => {
      this.areas = result;
    })
  }
  
  getSelectedValue(event,id){    
    if(event == true){
      this.selection.select(id)
      this.selectedId.push(id);  
    } else{
      this.selection.deselect(id);
      this.selectedId.splice(this.selectedId.indexOf(id), 1);    
    }
    const count = this.masterTogglePage?.[this.pIndex]?.count >= 0 ? this.masterTogglePage[this.pIndex].count : 0
    this.masterTogglePage[this.pIndex] = { 
      all: false, 
      single: true, 
      count: ((event == true ? count + 1 : count - 1 ) )
    };
    if(this.masterTogglePage[this.pIndex].count >= this.pSize || this.totalMilesData == this.masterTogglePage[this.pIndex].count){
      this.masterTogglePage[this.pIndex].all = true;
      this.masterTogglePage[this.pIndex].single = false;
    }
    if(this.masterTogglePage[this.pIndex].count <= 0){
      this.masterTogglePage[this.pIndex].all = false;
      this.masterTogglePage[this.pIndex].single = false;
    }    
    this.isAllSelected()
  }
  
  checkedBoxorNot(id){
    if(this.selection.hasValue() || this.selectedId.includes(id)){
      if(this.selection.isSelected(id) || this.selectedId.includes(id)){
        return true;
      }            
    }
    return false;
  } 

  assignActivities(){
    if(this.selectedId.length == 0){
      this._helper.showToast(3,'No Activities are there to be Assign');
      return;
    }
    
    this._apollo.mutate({
      mutation: gql`
      mutation addMilestone($milestone: String,$activity:[String]){
        addMilestone(milestone: $milestone, activity:$activity){
          code
          status
        }
      }`,
      variables: { milestone:this.milestoneId,activity:this.selectedId},
    }).subscribe(
      ({ data }: addMilestone) => {
        if(data.addMilestone.status){;
          let milestoneId = this.milestoneId
          this._helper.goToPage('view-assigned-activity',{state: {milestoneId}});
          this._helper.showToast(1,'Activities Assigned Successfully');
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
      );
    }
    
    selectArea(event){
      this.matSelect = event.source
      this.selectedAreaId = (event)?event.value.id:'';
      this._apollo.watchQuery<AreaQuery>({
        query: gql`
        query areaIdBasedskillActive($areaId: Int){
          areaIdBasedskillActive(areaId: $areaId){
            id
            skill
            status
          }
        }`,
        variables: {areaId: parseInt(this.selectedAreaId)},
      }).valueChanges.pipe(map(result => result.data.areaIdBasedskillActive)).subscribe((result) => {
        if(result){
          this.skills = result;
          this.filterSearch();
        }
      });
    }
    selectSkill(event){
      this.pIndex = 0;
      this.matSelect2 = event.source
      this.selectedSkillId = (event)?event.value.id:'';
      this.filterSearch();
    }
    selectAgeGroup(event){
      this.pIndex = 0;
      this.matSelect3 = event.source
      this.selectedAgeGrp = (event)?event.value:'';
      this.filterSearch();
    }

    filterSearch(){
      const pageindex = `${(this.pIndex || this.pIndex == 0) ? `${this.pIndex}` : ''}`;
      const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
      const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;
      let varArr = {};
      varArr= {page: parseInt(pageindex),size: parseInt(pagesize)};
      if(search){
        varArr = {page: parseInt(pageindex),size: parseInt(pagesize), search: search}
      }
      if(this.selectedAgeGrp){
        varArr = {page: parseInt(pageindex),size: parseInt(pagesize), ageGroup: this.selectedAgeGrp}
      }
      if(this.selectedAreaId){
        varArr = {page: parseInt(pageindex),size: parseInt(pagesize), areaId: parseInt(this.selectedAreaId)}
      }
      if(this.selectedAreaId && this.selectedAgeGrp){
        varArr = {page: parseInt(pageindex),size: parseInt(pagesize), areaId: parseInt(this.selectedAreaId), ageGroup: this.selectedAgeGrp};
      }
      if(this.selectedAreaId && this.selectedSkillId){
        varArr = {page: parseInt(pageindex),size: parseInt(pagesize), areaId: parseInt(this.selectedAreaId), skillId: parseInt(this.selectedSkillId)};
      }
      if(this.selectedAreaId && this.selectedSkillId && this.selectedAgeGrp){
        varArr = {page: parseInt(pageindex),size: parseInt(pagesize), ageGroup: this.selectedAgeGrp, areaId: parseInt(this.selectedAreaId), skillId: parseInt(this.selectedSkillId)};
      }
      if(this.selectedSkillId && search && this.selectedAgeGrp){
        varArr = {page: parseInt(pageindex), search: search, ageGroup: this.selectedAgeGrp,size: parseInt(pagesize), areaId: parseInt(this.selectedAreaId), skillId: parseInt(this.selectedSkillId)};
      }
      if(this.selectedAreaId && search){
        varArr = {page: parseInt(pageindex), search: search, size: parseInt(pagesize), areaId: parseInt(this.selectedAreaId)};
      }
      this._apollo.watchQuery<ActivityQuery>({
        query: gql`
        query areaSkillList($page: Int, $size: Int, $areaId: Int, $skillId: Int,$search: String,$ageGroup: String){
          areaSkillList(page: $page, size: $size, areaId: $areaId, skillId: $skillId,search: $search,ageGroup: $ageGroup){
            totalItems
            data{
              id
              activityName
              area{
                areaName
              }
              skills{
                skill
              }
              indSkill
              status
            }
            totalPages
            currentPage
          }
        }`,
        variables: varArr, fetchPolicy: 'network-only',
      }).valueChanges.pipe(map(result => result.data.areaSkillList)).subscribe((result) => {
        if (result.totalItems) {
          const ELEMENT_DATA:any = result.data.map((act: Activity) => ({
            id: act.id,
            activityName: act.activityName,
            skillName: act.skills.skill,
            areaName: act.area.areaName,
            indicatorSkill:act.indSkill,
            Status: act.status,
          }));
          this.dataSource = new MatTableDataSource<ActivityQuery>(ELEMENT_DATA);
          this.milestones = result; 
          this.totalMilesData = result.totalItems;      
          this.totalMilesPage = result.totalPages; 
          this.formatPaginatedData();
          
        } else {
          this.milestones = '';
          this._helper.showToast(4,'No Data Found');
        }
      });
    }

    formatPaginatedData(){
      this.masterTogglePage[this.pIndex] = {all: false, single: false, count: 0};
      this.dataSource.data.forEach((element) => {
        if(this.checkedBoxorNot(element.id)){
          this.masterTogglePage[this.pIndex].count++;
        }
      });

      if (this.masterTogglePage[this.pIndex].count >= this.pSize){
        this.masterTogglePage[this.pIndex].all = true;
      } else if (this.masterTogglePage[this.pIndex].count > 0){
        this.masterTogglePage[this.pIndex].single = true;
      }
    }

    goBack(){
      this._helper.goToPage('milestones');
    }

    clearFilter(){
      this.searchKeyword = '';
      this.selectedSkillId = '';
      this.selectedAreaId = '';
      this.selectedAgeGrp = '';
      this._helper.setData('clear');
      if(this.matSelect){
        this.matSelect.writeValue(null);
      }
      if(this.matSelect2){
        this.matSelect2.writeValue(null);
      }
      if(this.matSelect3){
        this.matSelect3.writeValue(null);
      }
      this.filterSearch();
    }

    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return (numSelected === numRows)
    }
    
    masterToggle(event) {
      this.isAllSelected();
      if(event.checked == true){
        this.dataSource.data.forEach(row => {
          this.selection.select(row.id)
          if(!this.selectedId.includes(row.id)){   
            this.selectedId.push(row.id);
            }
        })
      }else{
        if(this.pIndex <= this.totalMilesPage){
          let isTrue:any = true;
          this.dataSource.data.forEach(row => {
            if(!this.selection.isSelected(row.id)){
              isTrue = false;
            }
          })
          
          if(isTrue == true){
            this.selection.clear();
            this.selectedId = new Array();
          }else{
            this.dataSource.data.forEach(row => {
              this.selection.select(row.id)
              if(!this.selectedId.includes(row.id)){   
                this.selectedId.push(row.id);
              }
            })
          }
        }
      }
      this.masterTogglePage[this.pIndex] = {all: true, single: false, count: this.pSize};
    }

  }
  