<mat-card>
  <mat-card-content>
    <form [formGroup]="skillForm" #formDirective="ngForm">
        <mat-form-field appearance="outline" class="w-70-p" floatLabel="always">
          <mat-label>Skill</mat-label>
          <input matInput formControlName="skillname" type="text" placeholder="Skill" />
          <mat-error *ngIf="skillForm.controls['skillname'].hasError('required')">Skill is Required </mat-error>
          <mat-error *ngIf="skillForm.controls['skillname'].hasError('removeSpaces')">Invalid Skill Name</mat-error>

        </mat-form-field>

        <mat-form-field class="w-80-p" floatPlaceholder="never">
          <mat-label>Icon</mat-label>
          <input [hidden]="true" (change)="onFileChanged($event)" type="file" accept="all/*" #fileSelect />
          <input readonly matInput style="cursor: pointer" (click)="fileSelect.click()" formControlName="skillIcon"  [(ngModel)]="filename" placeholder="Select file..." />
          <div style="float: right; cursor: pointer;margin-top: -25px;" (click)="fileSelect.click();">
            <mat-icon>attach_file</mat-icon>
          </div>
        </mat-form-field>
        <img *ngIf="imageUrl" class="uploadTempnail" id="image_id" [src]="imageUrl" onerror="'assets/images/no_image.png'" />
<br/><br/>
      <button mat-raised-button color="primary" style="margin-right: 5px"  [disabled]="skillForm.invalid" (click)="addSkill(formDirective)">Save</button>
      <button type="button" (click)="resetForm(formDirective)" mat-stroked-button style="margin-right: 5px">
        Cancel
      </button>
    </form>
  </mat-card-content>
</mat-card>
