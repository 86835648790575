<div class="profile-box info-box general" fxLayout="column">
    <mat-card class="case-document">
      <div *ngIf="familyNewsFeeds && familyNewsFeeds.data && familyNewsFeeds.data.length > 0; then NEWS; else NO_NEWS"></div>
  
      <ng-template #NO_NEWS>
        <h1 class="err-msg" >No Data Found</h1>
      </ng-template>
  
      <ng-template #NEWS>
        <div class="content-card" style="overflow-y: scroll">
            <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
              <ng-container matColumnDef="caption">
                <mat-header-cell *matHeaderCellDef>Caption</mat-header-cell>
                <mat-cell  (click)="viewDetails(u.index)" *matCellDef="let u">
                  <p class="text-truncate">{{u.caption}} </p>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="CareGiver">
                <mat-header-cell *matHeaderCellDef>Care Giver</mat-header-cell>
                <mat-cell  (click)="viewDetails(u.index)" *matCellDef="let u">
                  <p class="text-truncate">{{u.careGiver}} </p>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Activity">
                <mat-header-cell *matHeaderCellDef>Activity</mat-header-cell>
                <mat-cell  (click)="viewDetails(u.index)" *matCellDef="let u">
                  <p class="text-truncate">{{u.activity}} </p>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="postedBy">
                <mat-header-cell *matHeaderCellDef>Posted By</mat-header-cell>
                <mat-cell  (click)="viewDetails(u.index)" *matCellDef="let u">
                  <p class="text-truncate">{{u.postedBy}} </p>
                </mat-cell>
              </ng-container>
              
              <!-- Status Column -->
              <ng-container matColumnDef="Status">
                <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
                <mat-cell  (click)="viewDetails(u.index)" *matCellDef="let u">
                  <p class="text-truncate">{{u.Status == '1' ? 'Active':'Inactive'}}</p>
                </mat-cell>
              </ng-container>
              
              
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                
                <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
            </mat-table>
            
            <mat-paginator #paginator [length]="familyNewsFeeds.totalItems" [pageSize]="pSize" [pageSizeOptions]="dataSize" [pageIndex]="familyNewsFeeds.currentPage" (page)="changePage($event)"></mat-paginator>
        </div>
      </ng-template>
    </mat-card>
  </div>
  