import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql, QueryRef } from "apollo-angular";
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ROLES,getLocalStorageRoleItem } from "app/helpers/utils";
@Component({
  selector: 'app-view-post',
  templateUrl: './view-post.component.html',
  styleUrls: ['./view-post.component.scss']
})
export class ViewPostComponent implements OnInit {
    loading: boolean = false;
    posts:any;
    pIndex: number = 0;
    pSize: number = 10;
    searchKeyword: string;
    userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];
    pageEvent: PageEvent;
    datasource:null;
    pageSize: number;
    getPostQuery:QueryRef<any>;
    private querySubscription: Subscription;
    currentPage: number;
    dataSize: number[] = [10, 50, 100];

    dataSource: any;
    displayedColumns: string[] = ['Caption','Description','Status'];
    constructor(private _apollo: Apollo, private _helper: HelperService){
      if(history && history.state && history.state.page){
        this.pIndex = history.state.page;
      }
      this.getPosts();
      if(this.userRoles && (this.userRoles.includes(64) || this.userRoles.includes(65) || this.userRoles.includes(66) )){
        this.displayedColumns.push('menu')
      }
    }
    ngOnInit(): void {
    
    }
    changePage({ pageIndex, pageSize }: { pageIndex?: number; pageSize?: number }) {
      this.pSize = pageSize;
      this.pIndex = pageIndex;
  
      this.getPosts();
    }
    searchPost(keyword: string) {
        this.pIndex = 0;
        this.searchKeyword = keyword;
    
        this.getPosts();
    }
    viewDetails(postId){
      this._helper.goToPage('/admin/view-post-details',{state: {postId:postId,page:this.pIndex}});
    }
    getPosts(){
      this.posts=null;
    const pageindex = `${this.pIndex ? `${this.pIndex}` : ''}`;
    const pagesize = `${this.pSize ? `${this.pSize}` : ''}`;
    const search = `${this.searchKeyword ? `${this.searchKeyword}` : ''}`;

    this.getPostQuery = this._apollo.watchQuery<any>({
      query: gql`
          query adminpostList($page: Int, $size: Int, $search: String){
            adminpostList(page: $page, size: $size, search: $search){
              totalItems
              data{
                id
                caption
                type
                media
                mediaType
                description
                adminId
                createdAt
                status            
              }
              totalPages
              currentPage
            }
          }`,
          variables: {
            page: parseInt(pageindex),
            size: parseInt(pagesize),
            search: search
          },
          fetchPolicy: 'network-only',
    });
    this.querySubscription = this.getPostQuery.valueChanges.pipe(map(result => result.data.adminpostList)).subscribe((result) => {
      if (result.totalItems) {
        
        const ELEMENT_DATA:any = result.data.map((post: any) => ({
          id: post.id,
          caption: post.caption,
          description:post.description,
          status: post.status,
        }));
        this.dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
        this.posts = result;
      } else {
        // this._helper.showToast(4,'No Data Found');
      }
    });
    }
    editPost(postId){
      this._helper.goToPage('/admin/edit-post',{state: {postId}});
    }
    changeStatus(id,status){
      let newStatus = (status == 0) ? 0 : ((status === 1) ? 2 : 1);
      if(status !== 0){
        this._apollo.mutate({
          mutation: gql`
            mutation publishUnpublishadminPost($id: ID!,$status: Int){
              publishUnpublishadminPost(id:$id,status:$status){
                status
                code 
                message
              }
            }`,
          variables: {id: parseInt(id),status:newStatus},
        }).subscribe(
          ({ data }: any) => {
            if(data.publishUnpublishadminPost?.code == "200"){
              this.getPostQuery.refetch();
              this._helper.showToast(1,data.publishUnpublishadminPost.message);
            }else{
              this._helper.showToast(4,'Something Went Wrong.. Try Again');
            }
          },(err) => {
            this._helper.showToast(4,err.message);
          }
        );
      }else{
        this.editPostStatsus(id,status)
      }
    }
    editPostStatsus(id,status){
      this._apollo.mutate({
        mutation: gql`
          mutation updateadminPost($id:ID!,$status:Int){
            updateadminPost(id:$id,status:$status)
          }`,
        variables: {id: id,status:status},
      }).subscribe(
        ({ data }: any) => {
          if(data.updateadminPost[0] == true){
            this._helper.showToast(1,'Blog Deleted Successfully');
            this.getPosts();
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err) => {
          this._helper.showToast(4,err.message);
        }
      );
    }
}

