<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    <div class="center">
      <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16"> map </mat-icon>
          <span class="logo-text h1">Milestone - {{milestoneId}}</span>
        </div>
        <app-search-bar *ngIf="tabIndex !== 1"placeholder="Search for an Indicator" (searchEvent)="searchIndicator($event)"> </app-search-bar>

        <!-- <button mat-raised-button (click)="viewAssignedActivities()" ><mat-icon>assignment  </mat-icon> View Assigned Activities</button> -->
        
      </div>

      
      <div class="content-card" style="overflow-y: scroll">
        <h1 class="err-msg" *ngIf="!milestones">No Activities Found</h1>
        <mat-tab-group *ngIf="milestones" (selectedTabChange)="onTabChange($event)">
          <mat-tab label="Indicator Skill"> 
            <div *ngIf="milestones">
              <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
                <!-- Name Column -->
                <ng-container matColumnDef="IndicatorSkill">
                  <!-- <mat-header-cell *matHeaderCellDef>Indicator Skill</mat-header-cell> -->
                  <mat-cell  *matCellDef="let u">
                    <p class="text-truncate">{{u.indicatorSkill}} </p>
                  </mat-cell>
                </ng-container>
                
                
                <!-- <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row> -->
                
                <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
              </mat-table>
              
              
              <mat-paginator #paginator [length]="milestones.totalItems" [pageSize]="pSize" [pageSizeOptions]="dataSize" [pageIndex]="milestones.currentPage" (page)="changePage($event)" > </mat-paginator>
            </div>
          </mat-tab>
          <mat-tab label="View Assigned Activities">  
            <app-view-assigned-activities [milestoneIDInp]="this.milestoneId"></app-view-assigned-activities>
          </mat-tab>
        </mat-tab-group>
        
        
      </div>
    </div>
  </div>