import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize,map } from 'rxjs/operators';
import { Area ,AreaQuery,updateArea} from '../../../models/types';
import { SpinnerService } from '../../../../shared/spinner.service';
import { getLocalStorageRoleItem, ROLES } from 'app/helpers/utils';

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
@Component({
  selector: 'app-edit-area',
  templateUrl: './edit-area.component.html',
  styleUrls: ['./edit-area.component.scss']
})
export class EditAreaComponent implements OnInit {
  areaForm:FormGroup; 
  area:any;
  areaId:any;
  filename: any;
  imageUrl: any;
  imageData: File;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];

  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,private spinnerService: SpinnerService) { 
    if(!history || !history.state || !history.state.areaId){
      this._helper.goToPage('areas');
    }
    this.areaId = history.state.areaId;
    if(this.areaId){
      this.getAreaById();
      this.setForm();
    }
  }

  ngOnInit(): void {
  }

  setForm(){
    this.areaForm = new FormGroup({
      areaname: new FormControl((this.area && this.area.areaName)?this.area.areaName:'', [Validators.required,removeSpaces]),
      areaIcon: new FormControl(''),
      status: new FormControl((this.area)?((this.area.status === 0) ? 0 :this.area.status): '', [Validators.required])
    })
  }
  getAreaById() {
    this._apollo.watchQuery<AreaQuery>({
      query: gql`
          query areaOne($id: ID!){
            areaOne(id: $id){
              id
              areaName
              icon
              status
            }
          }`,
          variables: { id: this.areaId  },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.areaOne)).subscribe((result) => {
      this.area = result;
      this.imageUrl = this.area.icon;
      // this.filename = this.area.icon;
      this.setForm();
    })
  }

  onFileChanged(event) {
    if(!event.target.files || !event.target.files[0]) {
      this.imageUrl = false;
      return false;
    }

    this.imageData = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imageData);

    reader.onload = (event) => {
      this.imageUrl = reader.result;
      this.filename = this.imageData.name;
    }
  }

  async editArea(){
    if(this.areaForm.invalid){
      return;
    }
    if(this.imageData && this.imageData.name){
      this.getImageUrl();
    }else{
      this.updateAreaData();
    }
          
  }

  updateAreaData(){
    this._apollo.mutate({
      mutation: gql`
        mutation updateArea($areaName: String,$icon:String!,$id:ID!,$status:Int){
          updateArea(areaName: $areaName,icon:$icon,id:$id,status:$status)
        }`,
      variables: {id: this.areaId ,areaName:this.areaForm.value.areaname,
        icon:(this.filename)?this.filename:this.area.icon,
        status:this.areaForm.value.status},
    }).subscribe(
      ({ data }: updateArea) => {
        if(data.updateArea[0] == true){
          this._helper.showToast(1,'Area Updated Successfully');
          this._helper.goToPage('/admin/view-area')
        }else{
          this._helper.showToast(4,'Something Went Wrong..Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
  }

  getImageUrl(){
    let imgnam = Date.now()+'-'+this.imageData.name;
    const fileRef = this.storage.ref(`/categories/${imgnam}`);
    this.spinnerService.show();
    this.storage.upload(`/categories/${imgnam}`, this.imageData).snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((url) => {
          if(url){
            this.filename = url;
            this.updateAreaData();
          }else{
            this._helper.showToast(4,'Something Went Wrong.. Try Again');
          }
        },(err)=>{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        })
      })
    ).subscribe();
  }

  cancel(){
    this._helper.goToPage('/admin/view-area')
  }
}
