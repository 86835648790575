import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '../../../../shared/spinner.service';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
interface addindicatorSkill {
  data: {
    addindicatorSkill: {
      id: any,
      status: any
    };
  };
}
// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && !control.value.replace(/\s/g, '').length) {
//       return  {removeSpaces: {value: null}};
//     }
//     return;
//   };
// }
@Component({
  selector: 'app-add-indicator',
  templateUrl: './add-indicator.component.html',
  styleUrls: ['./add-indicator.component.scss']
})
export class AddIndicatorComponent implements OnInit {

  indicatorForm:FormGroup; 
  // Indicator:any;
  filename: string = null;
  imageUrl: any;
  imageData: File;
  skillId:any;
  @Output() onIndicatorAdded = new EventEmitter();

  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,private spinnerService: SpinnerService) {
    if(!history || !history.state || !history.state.skillId){
      this._helper.goToPage('view-area');
    }
    this.skillId = parseInt(history.state.skillId);
   }

  ngOnInit(): void {
    this.indicatorForm = new FormGroup({
      name: new FormControl('', [Validators.required,removeSpacesValidator()])
    })
  }

  addIndicator(formDirective: FormGroupDirective){
    if(this.indicatorForm.invalid){
      return;
    }

    this._apollo.mutate({
      mutation: gql`
        mutation addindicatorSkill($indicatorSkill: String, $skillId: Int){
          addindicatorSkill(indicatorSkill: $indicatorSkill, skillId: $skillId){
            id
            status
          }
        }`,
      variables: { skillId:this.skillId,indicatorSkill:this.indicatorForm.value.name},
    }).subscribe(
      ({ data }: addindicatorSkill) => {
        if(data.addindicatorSkill.id){
          this._helper.showToast(1,'Indicator Added Successfully');
          this.resetForm(formDirective);
          this.onIndicatorAdded.emit();
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );

  }

  resetForm(formDirective: FormGroupDirective) {
    this.indicatorForm.reset();
    formDirective.resetForm(); // to remove mat-errors

    this.filename = null;
    this.imageUrl = null;
    this.imageData = null;
  }
  
  onFileChanged(event) {
    if(!event.target.files || !event.target.files[0]) {
      this.imageUrl = false;
      return false;
    }

    this.imageData = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.imageData);

    reader.onload = (event) => {
      this.imageUrl = reader.result;
      this.filename = this.imageData.name;
    }
  }

}
