<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    
    <div class="center">
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16"> place </mat-icon>
                <span class="logo-text h1"> Edit Indicator</span>
            </div>
        </div>
        
        <div class="content-card" >
            <div >
                <mat-card>
                    <mat-card-content>
                        <form [formGroup]="indicatorForm">
                            <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                                <mat-label>Indicator Name</mat-label>
                                <input matInput formControlName="name" type="text" placeholder="Indicator Name" />
                                <mat-error *ngIf="indicatorForm.controls['name'].hasError('required')">Indicator Name is Required </mat-error>
                                <mat-error *ngIf="indicatorForm.controls['name'].hasError('removeSpaces')">Invalid Indicator Name</mat-error>

                            </mat-form-field>

                              <mat-radio-group aria-label="Select an option" formControlName="status" *ngIf="this.userRoles && (this.userRoles.includes(56) || this.userRoles.includes(55))">
                                <mat-radio-button *ngIf="this.userRoles.includes(56)" [value]="1">Active</mat-radio-button>
                                <mat-radio-button *ngIf="this.userRoles.includes(56)" [value]="2">Inactive</mat-radio-button>
                                <mat-radio-button *ngIf="this.userRoles.includes(55)" [value]="0">Delete</mat-radio-button>
                            </mat-radio-group>
                              <mat-error *ngIf="indicatorForm.controls['status'].hasError('required')">Status is Required </mat-error>
              <br/>
              <br/>
                            <button mat-raised-button color="primary" style="margin-right: 5px"  [disabled]="indicatorForm.invalid" (click)="editIndicator()"> Update </button>
                            &nbsp;&nbsp;
                            <button mat-stroked-button  (click)="cancel()" >
                                <span>Cancel</span>
                            </button>

                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>

