<div id="agents" class="page-layout carded fullwidth">
  <div class="top-bg accent"></div>
  
  <div class="center">
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" >
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16"> toys </mat-icon>
        <span class="logo-text h1"> Add Activity</span>
      </div>
    </div>
    
    <div class="content-card" style="overflow-y: scroll">
      <mat-card >
        <mat-card-content>
          <form [formGroup]="activityForm">
            
            <mat-form-field  appearance="outline" class="w-40-p"  floatLabel="always">
              <mat-label>Activity Code</mat-label>
              <input matInput formControlName="activitycode" type="text" placeholder="Activity Code" />
              <mat-error *ngIf="activityForm.controls['activitycode'].hasError('required')">Activity Code is Required </mat-error>
              <mat-error *ngIf="activityForm.controls['activitycode'].hasError('removeSpaces')">Invalid Activity Code</mat-error>

            </mat-form-field>
            <mat-form-field  appearance="outline" class="w-60-p"  floatLabel="always">
              <mat-label>Activity Name</mat-label>
              <input matInput formControlName="activityname" type="text" placeholder="Activity Name" />
              <mat-error *ngIf="activityForm.controls['activityname'].hasError('required')">Activity Name is Required </mat-error>
              <mat-error *ngIf="activityForm.controls['activityname'].hasError('removeSpaces')">Invalid Activity Name</mat-error>

            </mat-form-field>

            <mat-form-field appearance="outline" class="w-50-p" floatLabel="always">
              <mat-label>Area</mat-label>
              <mat-select formControlName="areaId"  placeholder="Choose Area"  (selectionChange)="selectSkill($event)">
                <mat-option selected [value]="-1" disabled>Choose Area</mat-option>
                <mat-option *ngFor="let ara of area" [value]="ara">
                  {{ara.areaName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="activityForm.controls['skillid'].hasError('required')">Skill is Required </mat-error>
              
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-50-p" floatLabel="always">
              <mat-label>Skill</mat-label>
              <mat-select formControlName="skillid"  placeholder="Choose Skill"  (selectionChange)="selectIndicator($event)">
                <mat-option selected [value]="-1" disabled>Choose Skill</mat-option>
                <mat-option *ngFor="let skill of skills" [value]="skill">
                  {{skill.skill}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="activityForm.controls['skillid'].hasError('required')">Skill is Required </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
              <mat-label>Indicators</mat-label>
              <mat-select formControlName="indicators"  multiple placeholder="Choose Indicators">
                <mat-option selected [value]="-1" disabled>Choose Indicators</mat-option>
                <mat-option *ngFor="let ind of skillIndicators" [value]="ind.id">
                  {{ind.indicatorSkill}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="activityForm.controls['indicators'].hasError('required')">Indicators is Required </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
              <mat-label>Age group</mat-label>
              <mat-chip-list #chipList_1 aria-label="AgeGroup selection">
                <mat-chip *ngFor="let group of groups" [selectable]="selectable" [removable]="removable" 
                (removed)="remove(group,2)">
                  {{group}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Choose Age Group" #groupInput [formControl]="agegroupFC" 
                [matAutocomplete]="auto_2" [matChipInputFor]="chipList_1" [matChipInputSeparatorKeyCodes]="separatorKeysCodesGroup" 
                (matChipInputTokenEnd)="add($event,2)" matChipInputAddOnBlur="false" (blur)="addOnBlur($event,2)">
              </mat-chip-list>
              <mat-autocomplete #auto_2="matAutocomplete" (optionSelected)="selected($event,2)">
                <mat-option *ngFor="let groups of filteredGroups | async" [value]="groups.ageGroup">
                  {{groups.ageGroup}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="activityForm.controls['agegroup'].hasError('required')">Age Group is Required </mat-error>
            </mat-form-field>
            <div class="error" *ngIf="(activityForm.controls['agegroup'].touched || activityForm.controls['agegroup'].dirty )&& activityForm.controls['agegroup'].hasError('pattern')">Invalid Age Group</div>

            <mat-form-field  class="w-80-p" floatPlaceholder="never">
              <mat-label>Media</mat-label>
              <input [hidden]="true" (change)="onFileChanged($event)" type="file" accept="all/*" #fileSelect>
              <input readonly matInput style="cursor: pointer; padding-right: 45px;" (click)="fileSelect.click();" formControlName="media"  [(ngModel)]="filename" placeholder="Select file..."/>
              <div style="float: right; cursor: pointer;margin-top: -25px;" (click)="fileSelect.click();">
                <mat-icon>attach_file</mat-icon>
              </div>
            </mat-form-field>
            <img *ngIf="imageUrl" class="uploadTempnail" [src]="imageUrl" onerror="'assets/images/no_image.png'" />
            <video *ngIf="videoUrl" class="uploadTempnail"  controls [attr.src]="videoUrl" width="90" height="90"  (loadedmetadata)="getDuration($event)"></video>
            <mat-error *ngIf="activityFormSubmit">Media is Required </mat-error>
            <br/><br/>

            <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
              <mat-label>Interactions</mat-label>
              <textarea matInput formControlName="interactions" placeholder="Interactions"></textarea>
              <mat-error *ngIf="activityForm.controls['interactions'].hasError('required')">Interactions is Required </mat-error>
              <mat-error *ngIf="activityForm.controls['interactions'].hasError('removeSpaces')">Invalid Interactions</mat-error>

            </mat-form-field>
            
            <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
              <mat-label>Duration</mat-label>
              <input matInput formControlName="duration" type="text" placeholder="Duration" />
              <mat-error *ngIf="activityForm.controls['duration'].hasError('required')">Duration is Required </mat-error>
              <mat-error *ngIf="activityForm.controls['duration'].hasError('removeSpaces')">Invalid Duration</mat-error>

            </mat-form-field>

            <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
              <mat-label>Materials</mat-label>
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable" [removable]="removable" (removed)="remove(fruit,1)">
                  {{fruit}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Materials" #fruitInput [formControl]="materialsFC" [matAutocomplete]="auto_1"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event,1)" 
                matChipInputAddOnBlur="false" (blur)="addOnBlur($event,1)">
              </mat-chip-list>
              <mat-autocomplete #auto_1="matAutocomplete"  (optionSelected)="selected($event,1)">
                <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit.name">
                  {{fruit.name}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="activityForm.controls['materials'].hasError('required')">Materials is Required </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
              <mat-label>Objective</mat-label>
              <textarea matInput formControlName="objective" placeholder="Objective"></textarea>
              <mat-error *ngIf="activityForm.controls['objective'].hasError('required')">Objective is Required </mat-error>
              <mat-error *ngIf="activityForm.controls['objective'].hasError('removeSpaces')">Invalid Objective</mat-error>

            </mat-form-field>

              <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description" placeholder="Description"></textarea>
                <mat-error *ngIf="activityForm.controls['description'].hasError('required')">Description is Required </mat-error>
                <mat-error *ngIf="activityForm.controls['description'].hasError('removeSpaces')">Invalid Description</mat-error>

              </mat-form-field>

              <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                <mat-label>Evaluation</mat-label>
                <textarea matInput formControlName="evaluation" placeholder="Evaluation"></textarea>
                <mat-error *ngIf="activityForm.controls['evaluation'].hasError('required')">Evaluation is Required </mat-error>
                <mat-error *ngIf="activityForm.controls['evaluation'].hasError('removeSpaces')">Invalid Evaluation</mat-error>

              </mat-form-field>

              <mat-form-field appearance="outline" class="w-30-p" floatLabel="always">
                <mat-label>Place</mat-label>
                <mat-select formControlName="place" placeholder="Choose Place">
                  <mat-option selected="true" [value]="-1" disabled>Choose Place</mat-option>
                  <mat-option value="At Home">At Home</mat-option>
                  <mat-option value="At Table">At Table</mat-option>
                  <mat-option value="Out Side">Out Side</mat-option>
                  <mat-option value="At Mealtime">At Mealtime</mat-option>
                  <mat-option value="Car">Car</mat-option>
                  <mat-option value="Social">Social</mat-option>
                </mat-select>
                <mat-error *ngIf="activityForm.controls['place'].hasError('required')">Place is Required </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="w-70-p" floatLabel="always">
                <mat-label>MileStone</mat-label>
                <mat-select formControlName="milestone"  multiple placeholder="Choose Milestone">
                  <mat-option selected [value]="-1" disabled>Choose Milestone</mat-option>
                  <mat-option *ngFor="let mile of milestones" [value]="mile.value">
                    {{mile.value}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="activityForm.controls['milestone'].hasError('required')">Milestone is Required </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                <mat-label>Others</mat-label>
                <input matInput formControlName="other" type="text" placeholder="Other" />
                <mat-error *ngIf="activityForm.controls['other'].hasError('required')">Others is Required </mat-error>
                <mat-error *ngIf="activityForm.controls['other'].hasError('removeSpaces')">Invalid Others</mat-error>

              </mat-form-field>
              <br/>
              <br/>
              <button mat-raised-button color="primary"  [disabled]="activityForm.invalid"  (click)="addActivity()" >
                <span>Save</span>
              </button>   
              &nbsp;&nbsp;
              <button mat-stroked-button  (click)="cancel()" >
                <span>Cancel</span>
              </button>         
              <br/>
              <br/>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  
  