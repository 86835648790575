import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private auth = new BehaviorSubject(false);
  private role = new BehaviorSubject<any>(null);
  private userdetails = new BehaviorSubject<any>(null);

  private _data: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor( public toastr: ToastrManager, private router : Router) {}

  setAuth(status: boolean = false) {
    this.auth.next(status);
  }

  getAuth() {
    return this.auth.asObservable();
  }

  setMenu(data: any) {
    this.role.next(data);
  }
  getMenu(): Observable<any>  {
    return this.role.asObservable();
  }
  public setData(data: any){
      this._data.next(data);
  }

  public getData(): Observable<any> {
      return this._data.asObservable();
  }
  setUserDetails(data: any) {
    this.userdetails.next(data);
  }
  getUserDetails(): Observable<any>  {
    return this.userdetails.asObservable();
  }
  showToast(type: number = 4, message: string = 'Bad Request') {
    switch (type) {
      case 1: this.toastr.successToastr(message); break;
      case 2: this.toastr.infoToastr(message); break;
      case 3: this.toastr.warningToastr(message); break;
      case 4: this.toastr.errorToastr(message); break;
    }
  }

  goToPage(path: any, data = {}) {
    this.router.navigate(['/'+path], data);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
}
