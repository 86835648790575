import { Injectable } from '@angular/core';
import { CanActivate,ActivatedRouteSnapshot, RouterStateSnapshot,ActivatedRoute,Router,CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { getToken } from '../helpers/utils';
import { AuthorizationService } from './authorization.service';
@Injectable({
  providedIn: 'root',
})
export class AccessAuthGuard implements CanActivate {
  constructor(private authorizationService: AuthorizationService,private myRoute: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles = next.data.allowedRoles;
    const isAuthorized = this.authorizationService.isAuthorized(allowedRoles);
    if (!isAuthorized) {
      this.myRoute.navigate(['error']);
    }

    return isAuthorized;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DenyAuthGuard implements CanActivate {
  constructor(private myRoute: Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (getToken()) {
      this.myRoute.navigateByUrl('/');
      return false;
    } else return true;
  }
}
