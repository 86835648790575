<div id="agents" class="page-layout carded fullwidth">
  <div class="top-bg accent"></div>

  <div class="center">
    <div
      class="header accent"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center"
    >
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16"> place </mat-icon>
        <span class="logo-text h1"> Edit Area</span>
      </div>
    </div>

    <div class="content-card">
      <div>
        <mat-card>
          <mat-card-content>
            <form [formGroup]="areaForm">
              <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                <mat-label>Area</mat-label>
                <input matInput formControlName="areaname" type="text" placeholder="Area" />
                <mat-error *ngIf="areaForm.controls['areaname'].hasError('required')">Area is Required </mat-error>
                <mat-error *ngIf="areaForm.controls['areaname'].hasError('removeSpaces')">Invalid Area</mat-error>
              </mat-form-field>
              <mat-form-field class="w-80-p" floatPlaceholder="never">
                <mat-label>Icon</mat-label>
                <input [hidden]="true" (change)="onFileChanged($event)" type="file" accept="all/*" #fileSelect />
                <input
                  readonly
                  matInput
                  style="cursor: pointer"
                  (click)="fileSelect.click()"
                  formControlName="areaIcon"
                  [(ngModel)]="filename"
                  placeholder="Select file..."
                />
                <!-- <button matSuffix mat-icon-button (click)="fileSelect.click();"  matTooltip="Select a file" [matTooltipPosition]="'above'">
                                  <mat-icon>attach_file</mat-icon>
                                </button> -->
                <div style="float: right; cursor: pointer; margin-top: -25px" (click)="fileSelect.click()">
                  <mat-icon>attach_file</mat-icon>
                </div>
              </mat-form-field>
              <img
                *ngIf="imageUrl"
                class="uploadTempnail"
                id="image_id"
                [src]="imageUrl"
                onerror="'assets/images/no_image.png'"
              /><br /><br />
              <mat-radio-group
                aria-label="Select an option"
                formControlName="status"
                *ngIf="this.userRoles && (this.userRoles.includes(44) || this.userRoles.includes(43))"
              >
                <mat-radio-button *ngIf="this.userRoles.includes(44)" [value]="1">Active</mat-radio-button>
                <mat-radio-button *ngIf="this.userRoles.includes(44)" [value]="2">Inactive</mat-radio-button>
                <mat-radio-button *ngIf="this.userRoles.includes(43)" [value]="0">Delete</mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="areaForm.controls['status'].hasError('required')">Status is Required </mat-error>
              <br />
              <br />
              <button
                mat-raised-button
                color="primary"
                style="margin-right: 5px"
                [disabled]="areaForm.invalid"
                (click)="editArea()"
              >
                Update
              </button>
              &nbsp;&nbsp;
              <button mat-stroked-button (click)="cancel()">
                <span>Cancel</span>
              </button>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
