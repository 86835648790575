<div id="agents" class="page-layout carded fullwidth">
  <div class="top-bg accent"></div>
  <div class="center">
    <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="space-between center">
      <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon s-32 mr-16"> trending_up  </mat-icon>
        <span class="logo-text h1"> Trackers List </span>
      </div>

      <!-- SEARCH -->
      <app-search-bar placeholder="Search for a Tracker" (searchEvent)="searchTrack($event)"> </app-search-bar>

      <button mat-raised-button (click)="addTrack()"> Add Track</button>
     
    </div>

    <div class="content-card" style="overflow-y: scroll">
      <h1 class="err-msg" *ngIf="totallength === 0">No Tracks Found</h1>
      <h1 class="err-msg" *ngIf="totallength === undefined">No Tracks Found</h1>
      <div  *ngIf="totallength > 0">
        <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
          <!-- Name Column -->
          <ng-container matColumnDef="trackerName">
            <mat-header-cell *matHeaderCellDef>TrackerName </mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row?.TrackerName }}</mat-cell>
          </ng-container>


          <!-- Icon -->
          <ng-container matColumnDef="icon">
            <mat-header-cell *matHeaderCellDef>TrackerIcon</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <p class="text-truncate">
                <img [src]="row.img" alt="Tracker Icon" style="max-width: 50px; max-height: 50px;" />
              </p>
            </mat-cell>
          </ng-container>
          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ +row.Status == 1 ? 'Active' : 'Inactive' }}</mat-cell>
          </ng-container>

          <!-- menu -->
          <ng-container matColumnDef="menu">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu"
                [matMenuTriggerData]="{ id: row.id, index: row.index, status: row.Status, trackerName: row.TrackerName}" aria-label="Menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </mat-cell>
          </ng-container>


          <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-id="id" let-index="index" let-status="status",let-trackerName="trackerName" >
              <button mat-menu-item (click)="editTrack(id)">
                <mat-icon>edit</mat-icon>
                <span>Edit Tracker</span>
              </button>
              <button mat-menu-item (click)="changeStatus(id,status)">
                <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                <span>{{status == '1'? 'Inactive' : 'Active'}}</span>
              </button>
              <button mat-menu-item (click)="deleteItem(id)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
              <button mat-menu-item (click)="viewMileStone(id)">
                <mat-icon>track_changes</mat-icon>
                <span>Milestone</span>
              </button>
            </ng-template>
          </mat-menu>



          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

          <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
        </mat-table>


        <mat-paginator #paginator [length]="totallength" [pageSize]="pSize" [pageSizeOptions]="dataSize"
          [pageIndex]="currentPage" (page)="changePage($event)">
        </mat-paginator>
      </div>
    </div>



  </div>
</div>