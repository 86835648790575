import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize,map } from 'rxjs/operators';
import { IndicatorQuery,Indicator,updateindicatorSkill} from '../../../models/types';
import { SpinnerService } from '../../../../shared/spinner.service';
import { getLocalStorageRoleItem, ROLES } from 'app/helpers/utils';
import { removeSpacesValidator } from '../../../../shared/custom-validators'
// export function removeSpacesValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     if (control && control.value && !control.value.replace(/\s/g, '').length) {
//       return  {removeSpaces: {value: null}};
//     }
//     return;
//   };
// }
@Component({
  selector: 'app-edit-indicator',
  templateUrl: './edit-indicator.component.html',
  styleUrls: ['./edit-indicator.component.scss']
})
export class EditIndicatorComponent implements OnInit {

  indicatorForm:FormGroup; 
  indicator:any;
  indicatorId:any;
  areaId: any;
  areaName: any;
  userRoles:any = (getLocalStorageRoleItem(ROLES))?getLocalStorageRoleItem(ROLES):[];

  @Output() onIndicatorAdded = new EventEmitter();
  constructor(private _helper: HelperService,private _apollo: Apollo,private storage: AngularFireStorage,private spinnerService: SpinnerService) {     
    if(!history || !history.state || !history.state.indicatorId || !history.state.areaId || !history.state.areaName){
      this._helper.goToPage('view-indicator');
    }
    this.indicatorId = history.state.indicatorId;
    this.areaId =history.state.areaId;
    this.areaName = history.state.areaName;
    if(this.indicatorId){
      this.getIndicatorById();
      this.setForm();
    }
  }

  ngOnInit(): void {
  }

  setForm(){
    this.indicatorForm = new FormGroup({
      name: new FormControl((this.indicator && this.indicator.indicatorSkill)?this.indicator.indicatorSkill:'', [Validators.required,removeSpacesValidator()]),
      status: new FormControl((this.indicator)?((this.indicator.status === 0) ? 0 :this.indicator.status): '', [Validators.required])
    })
  }
  getIndicatorById() {
    this._apollo.watchQuery<IndicatorQuery>({
      query: gql`
          query indicatorSkillOne($id: ID!){
            indicatorSkillOne(id: $id){
              id
              indicatorSkill
              skills{
                id
                skill
              }
              status
            }
          }`,
          variables: { id: parseInt(this.indicatorId)  },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
    }).valueChanges.pipe(map(result => result.data.indicatorSkillOne)).subscribe((result) => {
      this.indicator = result;
      this.setForm();
    })
  }

  async editIndicator(){
    if(this.indicatorForm.invalid){
      return;
    }
    this._apollo.mutate({
      mutation: gql`
        mutation updateindicatorSkill($skillId: Int,$indicatorSkill:String!,$id:ID!,$status:Int){
          updateindicatorSkill(skillId: $skillId,indicatorSkill:$indicatorSkill,id:$id,status:$status)
        }`,
      variables: {id: parseInt(this.indicatorId ),indicatorSkill:this.indicatorForm.value.name,
        status:this.indicatorForm.value.status,skillId:parseInt(this.indicator.skills.id)},
    }).subscribe(
      ({ data }: updateindicatorSkill) => {
        if(data.updateindicatorSkill[0] == true){
          this._helper.showToast(1,'Indicator Updated Successfully');
          this._helper.goToPage('view-indicator',{state: {skillId:this.indicator.skills.id,name:this.indicator.skills.skill,areaId:this.areaId,areaName:this.areaName}});
        }else{
          this._helper.showToast(4,'Something Went Wrong.. Try Again');
        }
      },(err) => {
        this._helper.showToast(4,err.message);
      }
    );
          
  }

  cancel(){
    let skillId = this.indicator.skills.id;
    this._helper.goToPage('view-indicator',{state: {skillId}});
  }
}
