import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HelperService } from 'app/services/helper.service';

interface SettingsData {
  smtpHost?: string;
  smtpUser?: string;
  smtpPort?: number;
  smtpPassword?: string;

  adminEmail?: string;

  term?: string;
  privacy?: string;
  about?: string;
  // logo: string;
  // favIcon: string
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  loading: boolean = false;
  errMsg: string = '';
  succMsg: string = '';

  settingsForm: FormGroup;
  settingsData: SettingsData;

  constructor(private formBuilder: FormBuilder, private helperService: HelperService) {}

  ngOnInit(): void {
    this.settingsForm = this.formBuilder.group({
      smtpHost: [''],
      smtpUser: [''],
      smtpPort: [''],
      smtpPassword: [''],

      adminEmail: [''],
      apiKey: [''],
      commission: [''],

      term: [''],
      privacy: [''],
      about: [''],
    });

    this.getSettings();
  }

  getSettings() {
    this.loading = true;
    this.errMsg = '';
  }

  updateSettings() {
    if (this.settingsForm.valid) {
      this.loading = true;

    }
  }
}
