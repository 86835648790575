<div id="agents" class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
  
    <div class="center">
      <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center">
        <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="logo-icon s-32 mr-16"> flare </mat-icon>
          <span class="logo-text h1"  > Tips List </span>
        </div>
        
        <!-- SEARCH -->
        <app-search-bar placeholder="Search for a Tips" (searchEvent)="searchTips($event)"> </app-search-bar>
      </div>
  
      <div style="margin-bottom: 20px;" *ngIf="this.userRoles && this.userRoles.includes(31)">
        <app-addtip (onTipAdded)="refresh()" ></app-addtip>
      </div>

      <div class="content-card" style="overflow-y: scroll">
        <h1 class="err-msg" *ngIf="!tips">No Tips Found</h1>
  
        <div *ngIf="tips">
          <mat-table class="agents-table" #table [dataSource]="dataSource" fusePerfectScrollbar>
            <!-- Name Column -->
            <ng-container matColumnDef="Caption">
              <mat-header-cell *matHeaderCellDef>Caption</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <p class="text-truncate" matTooltip="{{u.caption}}"  [matTooltipPosition]="'above'">{{u.caption}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Message">
              <mat-header-cell *matHeaderCellDef>Message</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <p class="text-truncate" matTooltip="{{u.message}}"  [matTooltipPosition]="'above'">{{u.message}} </p>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Learn">
              <mat-header-cell *matHeaderCellDef>Learn</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <p class="text-truncate" matTooltip="{{u.learn}}"  [matTooltipPosition]="'above'">{{(u.learn)?u.learn:' -- '}} <br/><br/> </p>
              </mat-cell>
            </ng-container>
  
            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
              <mat-cell  *matCellDef="let u">
                <p class="text-truncate">{{u.Status == '1' ? 'Active':'Inactive'}}</p>
              </mat-cell>
            </ng-container>
  
            <!-- Controls Column -->
            <ng-container matColumnDef="menu">
              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
              <mat-cell *matCellDef="let u">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  [matMenuTriggerData]="{ id: u.id,status: u.Status}"
                  aria-label="Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
  
            <mat-menu #menu="matMenu">
              <ng-template matMenuContent let-id="id"  let-status="status">
                <button mat-menu-item (click)="editTip(id)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit Tip</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,status)" *ngIf="this.userRoles && this.userRoles.includes(52) ">
                  <mat-icon>{{status == '1'? 'block' : 'done'}}</mat-icon>
                  <span>{{status == '1'? 'Inactive' : 'Active'}}</span>
                </button>
                <button mat-menu-item (click)="changeStatus(id,0)" *ngIf="this.userRoles && this.userRoles.includes(51) ">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </ng-template>
            </mat-menu>
  
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  
            <mat-row *matRowDef="let agent; columns: displayedColumns" class="agent" matRipple> </mat-row>
          </mat-table>
  
          <mat-paginator 
            #paginator
            [length]="tips.totalItems"
            [pageSize]="pSize"
            [pageSizeOptions]="dataSize"
            [pageIndex]="tips.currentPage"
            (page)="changePage($event)"
            >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>