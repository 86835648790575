<mat-card>
    <mat-card-content>
        <form [formGroup]="tipForm"  #formDirective="ngForm">
            <!-- <div class="form-fields"> -->
                <mat-form-field appearance="outline" class="w-70-p" floatLabel="always">
                    <mat-label>Caption</mat-label>
                    <input matInput formControlName="caption" type="text" placeholder="Caption" />
                    <mat-error *ngIf="tipForm.controls['caption'].hasError('required')">Caption is Required </mat-error>
                    <mat-error *ngIf="tipForm.controls['caption'].hasError('removeSpaces')">Invalid Caption</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-70-p" floatLabel="always">
                    <mat-label>Message</mat-label>
                    <input matInput formControlName="message" type="text" placeholder="Message" />
                    <mat-error *ngIf="tipForm.controls['message'].hasError('required')">Message is Required </mat-error>
                    <mat-error *ngIf="tipForm.controls['message'].hasError('removeSpaces')">Invalid Message</mat-error>

                </mat-form-field>
                
                <mat-form-field appearance="outline" class="w-100-p" floatLabel="always">
                    <mat-label>Learn</mat-label>
                    <mat-select formControlName="learn" placeholder="Choose Learn">
                        <mat-option selected [value]="-1" disabled>Choose Learn</mat-option>
                        <mat-option *ngFor="let blog of blogs" [value]="blog.id">
                            {{blog.caption}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br/><br/>
                <button mat-raised-button color="primary" (click)="addtips(formDirective)" style="margin-right: 5px" [disabled]="tipForm.invalid">Save</button>
                <button type="button" (click)="resetForm(formDirective)" mat-stroked-button style="margin-right: 5px">Cancel</button>
            </form>
        </mat-card-content>
    </mat-card>
    