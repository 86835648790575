export const locale = {
    lang: 'en',
    data: {
        'SAMPLE': {
            'HELLO': 'Hello, World!'
        },
        'LOGIN' :{
            'LOGIN TO YOUR ACCOUNT': 'LOGIN TO YOUR ACCOUNT',
            'Email': 'Email',
            'Email is required': 'Email is required',
            'Please enter a valid email address' : 'Please enter a valid email address',
            'Password': 'Password',
            'Password is required': 'Password is required',
            'Remember Me': 'Remember Me',
            'Forgot Password?': 'Forgot Password?',
            'LOGIN' : 'LOGIN',
            'OR' : 'OR',
            'Create an account' : 'Create an account'
            
        }
    }
};
