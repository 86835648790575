import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'app/services/helper.service';
import { Apollo, gql } from "apollo-angular";
import { map } from 'rxjs/operators';
import { CareGiversCountQuery, ChildCountQuery, FamilyChildCount, FamilyChildCountQuery, FamilyCountQuery, IncreaseUserCOuntQuery, SessionCountQuery, TicketCountQuery, UserEngageCountQuery } from '../../models/types';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit {

  caregiverCountData:any;
  familyCountData:any;
  childCountData:any;
  sessionCountData:any;
  userEngageinTotalCount:any;
  familyChildTotalCount:any;
  ticketCount:any;
  increaseUserCOunDetailst:any;
  daterange:any;
  maxDate:any = new Date();
  filter_type:any
  famDatasource: any;
  famDisplayedColumns: string[] = ['care1','care2'];
  ticDatasource: any;
  ticDisplayedColumns: string[] = ['care1','care2','care3'];
  incDatasource: any;
  incDisplayedColumns: string[] = ['care1','care2','care3','care4','care5'];
  constructor(private _helper: HelperService,private _apollo: Apollo,private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.callFunctions();
  }

  
  callFunctions(){
    this.getCareGiverCount();
    this.getFamilyCount();
    this.getChildCount();
    this.getSessionCount();
    this.getUserEngageCount();
    this.getFamilyChildCount();
    this.ticketDetailsCount();
    this.getIncreaseUserCOunt();
  }

  filterBy(event){
    this.filter_type = +event?.value;
    console.log(this.filter_type);
    
    if(this.filter_type !== 3){
      this.callFunctions();
    }
  }

  getSelectedDate(event){
    this.daterange = (event?.target?.value) ? event?.target?.value : ''
    if(this.filter_type == 3){
      this.callFunctions();
    }
  }
  getCareGiverCount(){
    this._apollo.watchQuery<CareGiversCountQuery>({
      query: gql`
      query caregiverCount($filtertype: Int,$startingDate: String,$endingDate: String){
        caregiverCount(filtertype:$filtertype,startingDate:$startingDate,endingDate: $endingDate){
          careGiversCount
        }
      }`,
      variables: {
        filtertype: this.filter_type ,
        startingDate: (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.begin, 'yyyy-MM-dd'):"",
        endingDate:  (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.end, 'yyyy-MM-dd'):"",
      },
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.caregiverCount)).subscribe((result) => {
      if(result){
        this.caregiverCountData = result.careGiversCount || 0;
      }
    });
  }
  getFamilyCount(){
    this._apollo.watchQuery<FamilyCountQuery>({
      query: gql`
      query familyCountDetails($filtertype: Int,$startingDate: String,$endingDate: String){
        familyCountDetails(filtertype:$filtertype,startingDate:$startingDate,endingDate: $endingDate){
          familyCount
        }
      }`,
      variables: {
        filtertype: this.filter_type ,
        startingDate: (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.begin, 'yyyy-MM-dd'):"",
        endingDate:  (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.end, 'yyyy-MM-dd'):"",
      },
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.familyCountDetails)).subscribe((result) => {
      if(result){
        this.familyCountData = result.familyCount || 0;
      }
    });
  }
  getChildCount(){
    this._apollo.watchQuery<ChildCountQuery>({
      query: gql`
      query childCountDetails($filtertype: Int,$startingDate: String,$endingDate: String){
        childCountDetails(filtertype:$filtertype,startingDate:$startingDate,endingDate: $endingDate){
          childCount
        }
      }`,
      variables: {
        filtertype: this.filter_type ,
        startingDate: (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.begin, 'yyyy-MM-dd'):"",
        endingDate:  (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.end, 'yyyy-MM-dd'):"",
      },
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.childCountDetails)).subscribe((result) => {
      if(result){
        this.childCountData = result.childCount || 0;
      }
    });
  }
  getSessionCount(){
    this._apollo.watchQuery<SessionCountQuery>({
      query: gql`
      query sessionActiveCountDetails($filtertype: Int,$startingDate: String,$endingDate: String){
        sessionActiveCountDetails(filtertype:$filtertype,startingDate:$startingDate,endingDate: $endingDate){
          sessionActiveCount
        }
      }`,
      variables: {
        filtertype: this.filter_type ,
        startingDate: (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.begin, 'yyyy-MM-dd'):"",
        endingDate:  (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.end, 'yyyy-MM-dd'):"",
      },
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.sessionActiveCountDetails)).subscribe((result) => {
      if(result){
        this.sessionCountData = result.sessionActiveCount || 0;
      }
    });
  }
  getUserEngageCount(){
    this._apollo.watchQuery<UserEngageCountQuery>({
      query: gql`
      query userEngageinTotal($filtertype: Int,$startingDate: String,$endingDate: String){
        userEngageinTotal(filtertype:$filtertype,startingDate:$startingDate,endingDate: $endingDate){
          userEngageinTotalCount
        }
      }`,
      variables: {
        filtertype: this.filter_type ,
        startingDate: (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.begin, 'yyyy-MM-dd'):"",
        endingDate:  (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.end, 'yyyy-MM-dd'):"",
      },
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.userEngageinTotal)).subscribe((result) => {
      if(result){
        this.userEngageinTotalCount = result.userEngageinTotalCount || 0;
      }
    });
  }
  getFamilyChildCount(){
    this._apollo.watchQuery<FamilyChildCountQuery>({
      query: gql`
      query familyChildCountDetails($filtertype: Int,$startingDate: String,$endingDate: String){
        familyChildCountDetails(filtertype:$filtertype,startingDate:$startingDate,endingDate: $endingDate){
          familyName
          childCount
        }
      }`,
      variables: {
        filtertype: this.filter_type ,
        startingDate: (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.begin, 'yyyy-MM-dd'):"",
        endingDate:  (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.end, 'yyyy-MM-dd'):"",
      },
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.familyChildCountDetails )).subscribe((result) => {
      if(result){
        this.familyChildTotalCount = result;
        const ELEMENT_DATA_AGEGROUP:any = this.familyChildTotalCount.map((acti: FamilyChildCount) => ({
          familyName: acti?.familyName || 0,
          childCount: acti?.childCount || 0,
        }));
        this.famDatasource = new MatTableDataSource<FamilyChildCountQuery>(ELEMENT_DATA_AGEGROUP);
      }
    });
  }
  ticketDetailsCount(){
    this._apollo.watchQuery<TicketCountQuery>({
      query: gql`
      query ticketsCount($filtertype: Int,$startingDate: String,$endingDate: String){
        ticketsCount(filtertype:$filtertype,startingDate:$startingDate,endingDate: $endingDate){
          resolvedCount
          newTicketsCount
          processingCount
        }
      }`,
      variables: {
        filtertype: this.filter_type ,
        startingDate: (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.begin, 'yyyy-MM-dd'):"",
        endingDate:  (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.end, 'yyyy-MM-dd'):"",
      },
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.ticketsCount )).subscribe((result) => {
      if(result){
        this.ticketCount = result;
        const ELEMENT_DATA_TICGROUP:any = [{
          resolvedCount: this.ticketCount?.resolvedCount || 0,
          newTicketsCount: this.ticketCount?.newTicketsCount || 0,
          processingCount: this.ticketCount?.processingCount || 0,
        }];
        this.ticDatasource = new MatTableDataSource<TicketCountQuery>(ELEMENT_DATA_TICGROUP);
      }
    });
  }
  getIncreaseUserCOunt(){
    this._apollo.watchQuery<IncreaseUserCOuntQuery>({
      query: gql`
      query increaseUserCOunt($filtertype: Int,$startingDate: String,$endingDate: String){
        increaseUserCOunt(filtertype:$filtertype,startingDate:$startingDate,endingDate: $endingDate){
          familyCount
          childCount
          careGiversCount
          sessionActiveCount
          userEngageinTotalCount
        }
      }`,
      variables: {
        filtertype: this.filter_type ,
        startingDate: (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.begin, 'yyyy-MM-dd'):"",
        endingDate:  (this.daterange && this.filter_type == 3) ? this.datePipe.transform(this.daterange.end, 'yyyy-MM-dd'):"",
      },
      fetchPolicy:"network-only"
    }).valueChanges.pipe(map(result => result.data.increaseUserCOunt )).subscribe((result) => {
      if(result){
        this.increaseUserCOunDetailst = result;
        console.log(this.increaseUserCOunDetailst);
        
        const ELEMENT_DATA_INCGROUP:any = [{
          familyCount: this.increaseUserCOunDetailst?.familyCount || 0,
          childCount: this.increaseUserCOunDetailst?.childCount || 0,
          careGiversCount: this.increaseUserCOunDetailst?.careGiversCount || 0,
          sessionActiveCount: this.increaseUserCOunDetailst?.sessionActiveCount || 0,
          userEngageinTotalCount: this.increaseUserCOunDetailst?.userEngageinTotalCount || 0,
        }];
        this.incDatasource = new MatTableDataSource<IncreaseUserCOuntQuery>(ELEMENT_DATA_INCGROUP);
      }
    });
  }

}
