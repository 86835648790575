import { NgModule } from '@angular/core';
import { CommonModule,DatePipe, LowerCasePipe, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule} from '@angular/common/http';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule, MatDatepickerToggle, MatDatepickerToggleIcon } from 
    '@angular/material/datepicker';
    import { FuseProgressBarModule } from '@fuse/components';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { LayoutModule } from 'app/layout/layout.module';
import { UIModule } from 'app/ui-components/ui.module';
import { adminRoutes } from './admin.routes';


import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProfileComponent } from './pages/profile/profile.component';

import { AllUsersComponent } from './pages/users/all-users/all-users.component';

import { UserDetailsComponent } from './pages/users/user-details/user-details.component';
import { RoleComponent } from './pages/role/viewrole/role.component';
import { AddUserComponent } from './pages/users/add-user/add-user.component';
import { AddRoleComponent } from './pages/role/add-role/add-role.component';
import { ViewActivitiesComponent } from './pages/activities/view-activities/view-activities.component';
import { AddAreaComponent } from './pages/area/add-area/add-area.component';
import { EditAreaComponent } from './pages/area/edit-area/edit-area.component';
import { ViewAreaComponent } from './pages/area/view-area/view-area.component';
import { ViewSkillsComponent } from './pages/skills/view-skills/view-skills.component';
import { AddSkillsComponent } from './pages/skills/add-skills/add-skills.component';
import { EditSkillsComponent } from './pages/skills/edit-skills/edit-skills.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { EditRoleComponent } from './pages/role/edit-role/edit-role.component';
import { AddActivityComponent } from './pages/activities/add-activity/add-activity.component';
import { EditActivityComponent } from './pages/activities/edit-activity/edit-activity.component';
import { ActivityDetailsComponent } from './pages/activities/activity-details/activity-details.component';
import { ViewFamilyComponent } from './pages/family/view-family/view-family.component';
import { AboutFamilyComponent } from './pages/family/about-family/about-family.component';
import { MemberDetailsComponent } from './pages/family/member-details/member-details.component';
import { ChildDetailsComponent } from './pages/family/child-details/child-details.component';
import { ChildListDetailsComponent } from './pages/family/child-list-details/child-list-details.component';
import { ViewMilestonesComponent } from './pages/milestones/view-milestones/view-milestones.component';
import { ViewAssignedActivitiesComponent } from './pages/milestones/view-assigned-activities/view-assigned-activities.component';
import { AssignActivitiesComponent } from './pages/milestones/assign-activities/assign-activities.component';
import { AddIndicatorComponent } from './pages/indicators/add-indicator/add-indicator.component';
import { ViewIndicatorComponent } from './pages/indicators/view-indicator/view-indicator.component';
import { EditIndicatorComponent } from './pages/indicators/edit-indicator/edit-indicator.component';
import { AddBlogComponent, RemoveWhiteSpacePipe } from './pages/blog/add-blog/add-blog.component';
import { ViewBlogComponent } from './pages/blog/view-blog/view-blog.component';
import { EditBlogComponent } from './pages/blog/edit-blog/edit-blog.component';
import { ViewBlogDetailsComponent } from './pages/blog/view-blog-details/view-blog-details.component';
import { ViewUserPermissionsComponent } from './pages/userPermissions/view-user-permissions/view-user-permissions.component';
import { ErrorComponent } from './pages/error/error.component';
import { NewsfeedsComponent } from './pages/family/newsfeeds/newsfeeds.component';
import { AddtipComponent } from './pages/tips/addtip/addtip.component';
import { ViewtipComponent } from './pages/tips/viewtip/viewtip.component';
import { EdittipComponent } from './pages/tips/edittip/edittip.component';
import { NewsfeeddetailsComponent } from './pages/family/newsfeeddetails/newsfeeddetails.component';
import { ShowindicatorsComponent } from './pages/milestones/showindicators/showindicators.component';
import { CmsManagementComponent } from './pages/cms/cms-management/cms-management.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { ContactDialog } from './pages/contactus/contactus.component';
import { MembersListComponent } from './pages/members/members-list/members-list.component';
import { KpiComponent } from './pages/kpi/kpi.component';
import {ViewPostComponent} from './pages/post/view-post/view-post.component';
import { AddPostComponent } from './pages/post/add-post/add-post.component';
import { EditPostComponent } from './pages/post/edit-post/edit-post.component';
import { ViewPostDetailsComponent } from './pages/post/view-post-details/view-post-details.component'
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddTrackPopupComponent } from './pages/trackers/view-tracker/view-track.component';
import { ViewTrackerComponent } from './pages/trackers/view-tracker/view-track.component';
import { ViewTrackMilestonesComponent } from './pages/trackerMilestones/view-milestones/ViewTrackMilestones.component';
// import { AddMilestonePopupComponent } from './pages/trackeMilestones/view-milestones/ViewTrackMilestones.component';
import {AddAMilestoneComponent} from './pages/trackerMilestones/add-milestones/add-tracker-milestone.component';
import {EditMilestoneComponent} from './pages/trackerMilestones/edit-milestone/edit-tracker-milestone'
import {ChildGrowth} from './pages/childGrowth/child-growth.component'
@NgModule({
  declarations: [
    DashboardComponent,
    SettingsComponent,
    ProfileComponent,
    AllUsersComponent,
    UserDetailsComponent,
    RoleComponent,
    AddUserComponent,
    AddRoleComponent,
    ViewActivitiesComponent,
    AddAreaComponent,
    EditAreaComponent,
    ViewAreaComponent,
    ViewSkillsComponent,
    AddSkillsComponent,
    EditSkillsComponent,
    EditUserComponent,
    EditRoleComponent,
    AddActivityComponent,
    EditActivityComponent,
    ActivityDetailsComponent,
    ViewFamilyComponent,
    AboutFamilyComponent,
    MemberDetailsComponent,
    ChildDetailsComponent,
    ChildListDetailsComponent,
    ViewMilestonesComponent,
    ViewAssignedActivitiesComponent,
    AssignActivitiesComponent,
    AddIndicatorComponent,
    ViewIndicatorComponent,
    EditIndicatorComponent,
    AddBlogComponent,
    ViewBlogComponent,
    EditBlogComponent,
    ViewBlogDetailsComponent,
    ViewUserPermissionsComponent,
    ErrorComponent,
    NewsfeedsComponent,
    AddtipComponent,
    ViewtipComponent,
    EdittipComponent,
    NewsfeeddetailsComponent,
    ShowindicatorsComponent,
    CmsManagementComponent,
    ContactusComponent,
    ContactDialog,
    MembersListComponent,
    KpiComponent,
    ViewPostComponent,
    AddPostComponent,
    EditPostComponent,
    ViewPostDetailsComponent,
    AddTrackPopupComponent,
    ViewTrackerComponent,
    ViewTrackMilestonesComponent,
    // AddMilestonePopupComponent,
    AddAMilestoneComponent,
    EditMilestoneComponent,
    ChildGrowth

  ],
  imports: [
    CommonModule,
    RouterModule.forChild(adminRoutes),
    LayoutModule,
    FuseSharedModule,
    ChartsModule,
    NgxChartsModule,

    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatRippleModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatIconModule,
    // BrowserAnimationsModule,
    // MatDatepickerToggleIcon,
    MatTabsModule,
    MatGridListModule,
    MatMenuModule,
    MatDialogModule,
    MatCardModule,
  MatTooltipModule,
  AngularEditorModule,
  HttpClientModule,
  SatDatepickerModule,
   SatNativeDateModule,
    UIModule,
 FuseProgressBarModule 

  ],
  exports: [
    // AngularEditorModule,
  ],
  providers:[DatePipe,LowerCasePipe,RemoveWhiteSpacePipe]
})
export class AdminModule {}
