<div id="profile" class="page-layout simple tabbed" *ngIf="familyDetails">
  <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between end"  >
    <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
      <div class="name">{{familyDetails.familyGroup.familyName}} </div>
    </div>
    <button mat-raised-button (click)="viewFamily()"><mat-icon>arrow_back</mat-icon>Back</button>
  </div>

  <div class="about-content">
    <mat-tab-group dynamicHeight="true" mat-stretch-tabs (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="About">
        <div id="about" class="p-24" fxLayout="row wrap">
          <div class="about-content" fxLayout="column" fxFlex="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
            <div class="profile-box info-box general" fxLayout="column">
              <header class="accent">
                <div class="title">About</div>
              </header>

              <div class="content">
                <div class="info-line">
                  <div class="title">CareGiver</div>
                  <div class="info">{{familyDetails.familyGroup.careGiver.firstname}} {{familyDetails.familyGroup.careGiver.lastname}}</div>
                </div>

                <div class="info-line">
                  <div class="title">Email Id</div>
                  <div class="info">{{familyDetails.familyGroup.careGiver.emailId}}</div>
                </div>


                <div class="info-line">
                  <div class="title">Created At</div>
                  <div class="info">{{familyDetails.familyGroup.createdAt | date : 'MMMM dd, yyyy'}} </div>
                </div>
              </div>
            </div>
          </div>

          <div class="about-sidebar" fxLayout="column" fxFlex="50" fxFlex.gt-sm="50" fxFlex.gt-md="50">
            <div class="profile-box info-box general" fxLayout="column">
              <div >
                <header class="accent">
                  <div class="title">Member Details</div>
                </header>

                <div class="content">
                    <div class="info-line">
                        <div class="title">Total Members</div>
                        <div class="info">{{familyDetails.membersCount}}</div>
                    </div>
    
                    <div class="info-line">
                        <div class="title">Total Children</div>
                        <div class="info">{{familyDetails.childCount}}</div>
                    </div>
                    <div class="info-line">
                        <div class="title">Status</div>
                        <div class="info">{{familyDetails.familyGroup.status == '1' ? 'Active' : 'Inactive'}}</div>
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Members">
        <div *ngIf="familyDetails.familyMembers.length == 0; then NO_MEMBERS; else MEMBERS"></div>
        <ng-template #NO_MEMBERS>
            <h1 class="err-msg" >No Data Found</h1>
        </ng-template>
        <ng-template #MEMBERS>
            <app-member-details [members]="familyDetails.familyMembers"></app-member-details>
        </ng-template>
      </mat-tab>

      <mat-tab label="Children">
        <div *ngIf="familyDetails.child.length  == 0; then NO_CHILD; else CHILD"></div>
        <ng-template #NO_CHILD>
            <h1 class="err-msg" >No Data Found</h1>
        </ng-template>
        <ng-template #CHILD>
            <app-child-details [child]="familyDetails.child"></app-child-details>
        </ng-template>
      </mat-tab>

      <mat-tab label="News Feed List">
            <app-newsfeeds [familyId]="familyId"></app-newsfeeds>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
